const taxpayable = { data: [], loading: true };
export const GetAllTaxPayableReducer = (state = taxpayable, action) => {
    switch (action.type) {

        case "get_allpayable":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}
const taxpayablevoucher = { data: [], loading: true };
export const GetAllTaxPayableVoucherReducer = (state = taxpayablevoucher, action) => {
    switch (action.type) {

        case "get_allpayableVoucher":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}