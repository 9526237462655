import { useState } from "react";
import { GeneralAdjustmentForm } from "./GeneralAdjustmentForm";
import { GeneralAdjTable } from "./GeneralAdjustmentTable";

const GeneralAdjustmentMain = () => {
    const showadjustmenttable = () => {
      stateshowadjustment(
        <GeneralAdjustmentForm canceladjustmenttable={canceladjustmenttable} />
      );
    };
    const canceladjustmenttable = () => {
      stateshowadjustment(
        <GeneralAdjTable
          showadjustmenttable={showadjustmenttable}
          canceladjustmenttable={canceladjustmenttable}
        />
      );
    };
    const [showadjustment, stateshowadjustment] = useState(
      <GeneralAdjTable
        showadjustmenttable={showadjustmenttable}
        canceladjustmenttable={canceladjustmenttable}
      />
    );
  
    return (
      <>
        <div className="row">
          <div className="dash_background col-12 p-0">
            <div className="row ">
              <div className="col-md-12">{showadjustment}</div>
            </div>
          </div>
        </div>
      </>
    );
  }
  export default GeneralAdjustmentMain;