const alltax = { data: [], loading: true };
const taxdata = {
    TaxId:0,
    TaxName:null,
    TaxPer:null
    
};
export const GetAllTaxReducer = (state = alltax, action) => {
    switch (action.type) {

        case "get_alltax":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}
export const GetTaxDataReducer = (state = taxdata, action) => {
    switch (action.type) {

        case "get_taxdata":
            return {
                taxdata: action.payload,

            }
        default:
            return {
                taxdata: taxdata,

            };
    }
}
