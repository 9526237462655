import { useState } from "react";
import AddNominee from "./AddNominee";
import { TransferContractPage } from "./TransferContractForm";
import { TransferContractTable } from "./TransferContractTable";

const MainTransferContract = () => {
    const [statecontractdata, setcontractdata] = useState({});
    const [stateCustomersNomineeRecord, setCustomersNomineeRecord] = useState([]);
    const changecomponent = () => {
        setComponentState("TransferPage");
    };

    const formCancel = () => {
        setComponentState("TransferTable");
    };
    const showaddnominee = () => {
        setComponentState("addnominee");
    }
    const [componentState, setComponentState] = useState(
        "TransferTable"
    );

    return <>

        <>
            <div className="row ">
                <div className="dash_background col-12 p-0">
                    <div className="row ">

                        <div className="col-md-12">
                            {/* {componentState} */}
                            {componentState == "TransferTable" ? <TransferContractTable changecomponent={changecomponent} formCancel={formCancel} /> : componentState == "addnominee" ? <AddNominee changecomponent={changecomponent} statecontractdata={statecontractdata} stateCustomersNomineeRecord={stateCustomersNomineeRecord} /> : componentState == "TransferPage" ? <TransferContractPage statecontractdata={statecontractdata} stateCustomersNomineeRecord={stateCustomersNomineeRecord}
                            //  stateCustomersNomineeRecord={stateCustomersNomineeRecord} 
                             showaddnominee={showaddnominee} formCancel={formCancel} setcontractdata={setcontractdata} setCustomersNomineeRecord={setCustomersNomineeRecord} /> : null}

                        </div>


                    </div>


                </div>
            </div>
        </>
    </>;
};
export default MainTransferContract;