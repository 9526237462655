import React, { Component } from "react";
import gravity from "../../../assets/GravityLogo.png";
export class ClientPrint extends Component {
    render() {

        return (
            <div style={{ backgroundColor: "white", height: "100%" }}>
                <div className="row m-0 p-0">
                    <div className="watermarked">
                        <img
                            src={gravity}
                            style={{ height: "600px", width: "600px" }}
                            alt="Company logo"
                        />
                    </div>
                    <div className="col-8 text-left mt-4">
                        <h2 style={{ color: "#1b7a7f" }}>Gravity Associates Pvt Limited</h2>
                        <hr />
                        <h5 style={{ color: "grey", marginTop: "5px" }}>
                            <i>
                                204 R/B Road, East Canal Road, Faisalabad.
                            </i>
                        </h5>
                        <div
                            style={{
                                borderTop: "2px dotted black",
                                borderBottom: "2px dotted black",
                                height: "7px",
                            }}
                        ></div>
                    </div>
                    <div className="col-4 text-center">
                        {/* IMAGE COMES HERE */}

                        <img
                            src={gravity}
                            style={{ height: "175px", width: "175px", borderRadius: "100px" }}
                            className="pt-2"
                            alt="Company logo"

                        />
                    </div>
                </div>
                <div
                    className="row m-3 p-0"
                // style={{ border: "5px solid darkgrey", height: "auto" }}
                >
                    <div className="col-12 text-center mt-2">
                        <h4 style={{ color: "red" }}>
                            <u>Clients</u>
                        </h4>





                        <div className="mt-5">
                            <div class="table-responsive col-12">
                                <table class="table">
                                    <thead>
                                        <tr>

                                            <th>Sr</th>
                                            <th>Name</th>

                                            <th>CNIC</th>
                                            <th>Occupation</th>
                                            <th>Contact No.</th>
                                            <th>City</th>

                                        </tr>
                                    </thead>

                                    <tbody>

                                        {this.props.printData.data !== undefined ?
                                            this.props.printData.data.map((val, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{val.ManagerClientName}</td>

                                                    <td>{val.ManagerClientCnic}</td>
                                                    <td>{val.Occupation}</td>
                                                    <td>{val.ContactNumber1}</td>
                                                    <td>{val.City}</td>

                                                </tr>))
                                            : null}

                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}