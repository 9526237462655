const Depreciation = { data: [], loading: true };

export const GetAssetsDepReducer = (state = Depreciation, action) => {
    switch (action.type) {

        case "get_allDepreciate":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}