import React from "react";
import "../App.css";
import { Link } from 'react-router-dom';

import background from "../assets/background.jpg";
const divStyle = {
    width: '100vw',
    height: '100vh',
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover'
};

const Forget = (props) => {

    return (
        <>
            <div style={divStyle}  >
                <div className="loginbox ">
                    <div className="row">
                        <div className="col-12 forgottext">Forgot Password?</div>
                        <div className="col-12 verfiytext"> Enter the email associated with your account</div>
                        <div className="col-12 mt-5">
                            <input type="text" Placeholder="Email" className="logininput" id="username" />
                        </div>




                        <div className="col-12 mt-3">
                            <Link to="/verify"> <button

                                className=" text-center mt-4 p-2 logIn "
                            >
                                {" "}
                      SEND
                    </button>
                            </Link>
                        </div>


                    </div>
                </div>
            </div>
        </>
    );
};

export default Forget;