import React from "react";
import { BiInfoCircle } from "react-icons/bi";
import SaveImage from "../../ModalImages/SaveImage";
import ListImage from "../../ModalImages/ListImage";
import DownIcon from "../../ModalImages/DownIcon";

function UnSubmittedVouchersModal() {
  return (
    <div>
      <div class="d-flex justify-content-center align-items-center">
        <span class="modal-btn" data-toggle="modal" data-target="#myModal2">
          <BiInfoCircle />
        </span>
      </div>

      <div
        class="modal right fade my-modal"
        id="myModal2"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myModalLabel2"
      >
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="myModalLabel2">
                UnSubmitted Vouchers Info!
              </h4>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div class="modal-body">
              <ul className="modal-ul">
                <li>
                  <ListImage />
                  All unsubmitted vouchers will appear here for getting approval
                  from the admin.
                </li>
                <li>
                  <ListImage />
                  All vouchers can be submitted and deleted at once.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UnSubmittedVouchersModal;
