const allSalaryPaymentPopulate = { data: [], loading: true };
const allSalaryTableData = { data: [], loading: true };

export const GetAllSalaryPaymentPopulateRed = (state = allSalaryPaymentPopulate, action) => {
    switch (action.type) {

        case "get_allSalaryPayment":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}
export const GetAllSalaryTableRed = (state = allSalaryTableData, action) => {
    switch (action.type) {

        case "get_allSalaryTableData":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}
