const allinstallrescedulePopulate = { data: [], loading: true };
const resceduledata = {};

export const GetAllInstallRescedulePopulateRed = (
  state = allinstallrescedulePopulate,
  action
) => {
  switch (action.type) {
    case "get_installrescedule":
      return {
        data: action.payload,
        loading: action.loading,
      };
    default:
      return state;
  }
};

export const GetresceduledataReducer = (state = resceduledata, action) => {
  console.log(state);
  switch (action.type) {
    case "get_resceduledata":
      return {
        resceduledata: action.payload,
      };
    default:
      return state;
  }
};
