const allpopulatelead = { data: [], loading: true };
const alltablelead = { data: [], loading: true };
const allleadtaskpopulate = { data: [], loading: true };
const allleadtasktable = { data: [], loading: true };

const allleadmeetingpopulate = { data: [], loading: true };
const allleadmeetingtable = { data: [], loading: true };

const allleadcallpopulate = { data: [], loading: true };
const allleadcalltable = { data: [], loading: true };





export const GetAllPopulateLeadRed = (state = allpopulatelead, action) => {
    switch (action.type) {

        case "get_allpopulateleads":
            return {

                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}


export const GetAllTableLeadRed = (state = alltablelead, action) => {
    switch (action.type) {

        case "get_alltableleads":
            return {

                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}
export const GetAllLeadTaskPopulateRed = (state = allleadtaskpopulate, action) => {
    switch (action.type) {

        case "get_allleadtaskpopulate":
            return {

                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}
export const GetAllLeadTasktableRed = (state = allleadtasktable, action) => {
    switch (action.type) {

        case "get_allleadtasktable":
            return {

                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}
export const GetAllLeadMeetingPopulateRed = (state = allleadmeetingpopulate, action) => {
    switch (action.type) {

        case "get_allleadmeetingpopulate":
            return {

                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}
export const GetAllLeadMeetingTabelRed = (state = allleadmeetingtable, action) => {
    switch (action.type) {

        case "get_allleadmeetingtable":
            return {

                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}
export const GetAllLeadCallPopulateRed = (state = allleadcallpopulate, action) => {
    switch (action.type) {

        case "get_allleadcallpopulate":
            return {

                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}
export const GetAllLeadCallTableRed = (state = allleadcalltable, action) => {
    switch (action.type) {

        case "get_allleadcalltable":
            return {

                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}