const allbanks = { data: [], loading: true };
const allcash = { data: [], loading: true };
export const GetAllBanksReducer = (state = allbanks, action) => {
    switch (action.type) {

        case "get_allbanks":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}
export const GetAllCashReducer = (state = allcash, action) => {
    switch (action.type) {

        case "get_allcash":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}

