import React, { Component } from "react";
import gravity from "../../../../assets/GravityLogo.png";
import PrintHeader from "../../../Prints/PrintHeader/PrintHeader";
export class AllProjectsPrint extends Component {
    render() {

        return (
          <div style={{ backgroundColor: "white", height: "100%" }}>
            <div className="row m-0 p-0">
              <PrintHeader />
            </div>
            <div className="row m-3 p-0">
              <div className="col-12 text-center mt-2">
                <h4 style={{ color: "red" }}>
                  <u>Projects </u>
                </h4>
                <h5>
                  {/* <b>Voucher No.:{" "}</b> */}
                  {/* {this.props.printData !== undefined ?

                                this.props.printData.GeneralVoucherId

                                : null} */}
                </h5>

                <div className="mt-3">
                  <div class=" col-12">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>Sr</th>
                          <th>Project Name</th>
                          <th> Category</th>
                          <th> Owner</th>
                          <th>Location</th>
                          <th>City</th>
                        </tr>
                      </thead>

                      <tbody>
                        {this.props.printData.data !== undefined
                          ? this.props.printData.data.map((val, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{val.ProjectName}</td>
                                <td>
                                  {val.ProjectCategory !== undefined &&
                                  val.ProjectCategory !== null
                                    ? val.ProjectCategory.ProjectCategoryName
                                    : null}
                                </td>
                                <td>{val.ProjectOwner}</td>
                                <td>{val.ProjectLocation}</td>
                                <td>{val.ProjectCity}</td>
                              </tr>
                            ))
                          : null}
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* <h5 className="text-right pt-3">
                            <b>Narration:{" "}</b>
                            {this.props.printData !== undefined ?

                                this.props.printData.Narration

                                : null}
                        </h5> */}
              </div>
            </div>
          </div>
        );
    }
}