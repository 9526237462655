import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import Loader from "react-loader-spinner";

const CommossionPaymentDeleteVoucherModal = ({
  DeleteVoucherData,
  handelDeleteVoucherdataChange,
  closeDeleteVoucherModal,
  showPassword,
  setshowPassword,
  loading,
  deleteVoucher,
}) => {
  return (
    <div id="DeleteVoucherModal" class="modal">
      <div class="modal-content-cat_voucher2">
        <div className="row ">
          <div className="col-12  page_heading">
            <h4 className="  text-center pt-2">Delete Voucher</h4>
            <span class="close pr-2  " onClick={closeDeleteVoucherModal}>
              &times;
            </span>
          </div>
        </div>
        <div className="row" style={{ width: "100%" }}>
          <div className="col-12 " style={{ paddingTop: "10px" }}>
            <label className="input_label m-0">Email:</label>
          </div>
          <div className="col-12 ">
            <input
              value={DeleteVoucherData?.Email}
              onChange={handelDeleteVoucherdataChange}
              name="Email"
              autoComplete="off"
              style={{ width: "100%" }}
              className="input_total_styling "
              placeholder="Email"
              type="email"
            />
          </div>
          <div className="col-12 " style={{ paddingTop: "10px" }}>
            <label className="input_label m-0">Password:</label>
          </div>
          <div className="col-12 ">
            <input
              value={DeleteVoucherData?.Password}
              onChange={handelDeleteVoucherdataChange}
              name="Password"
              autoComplete="off"
              type={showPassword ? "text" : "password"}
              style={{ width: "100%" }}
              className="input_total_styling "
              placeholder="Password"
            />
            {showPassword ? (
              <AiOutlineEye
                onClick={() => {
                  setshowPassword(!showPassword);
                }}
                size={"1.3em"}
                id="togglePassword"
                style={{ marginLeft: "-30px" }}
              />
            ) : (
              <AiOutlineEyeInvisible
                onClick={() => {
                  setshowPassword(!showPassword);
                }}
                size={"1.3em"}
                id="togglePassword"
                style={{ marginLeft: "-30px" }}
              />
            )}
          </div>
          <div className="col-12  " style={{ paddingTop: "10px" }}>
            <label className="input_label m-0">Reason:</label>
          </div>
          <div className="col-12  " style={{ width: "100%" }}>
            <textarea
              value={DeleteVoucherData?.Reason}
              onChange={handelDeleteVoucherdataChange}
              name="Reason"
              autoComplete="off"
              style={{ width: "100%", height: "100px" }}
              className="input_total_styling "
              placeholder="Reason"
              rows="4"
            />
          </div>
          <div
            style={{
              display: "flex",
              float: "right",
              width: "100%",
              justifyContent: "end",
              marginRight: "3%",
              marginTop: "10px",
              alignItems: "center",
            }}
          >
            {loading && (
              <Loader type="ThreeDots" color="green" height={40} width={40} />
            )}
            <div>
              <span>
                <button
                  className="cancel_btn ml-2  "
                  onClick={() => {
                    closeDeleteVoucherModal();
                  }}
                >
                  Cancel
                </button>
              </span>
            </div>
            <div>
              <span>
                <button
                  className="save_btn ml-2  "
                  disabled={loading}
                  onClick={deleteVoucher}
                >
                  Save
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommossionPaymentDeleteVoucherModal;
