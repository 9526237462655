import React, { Component } from "react";
import gravity from "../../../../assets/GravityLogo.png";
import moment from "moment";
import IndividualPrintHeader from "../../../Prints/PrintHeader/IndividualPrintHeader";
import PrintHeader from "../../../Prints/PrintHeader/PrintHeader";
var converter = require('number-to-words')
class InstallmentPayPrint extends Component {
    render() {
  
        return (
          <div className="row m-0 p-0">
            <div className="row m-0 p-0">
              <PrintHeader />
            </div>

            <h4 className="col-12 text-center">
              <u>Installment Payment</u>
            </h4>
            <h4 className="col-12 text-center">
              {localStorage.getItem("ProjectName") === "THE GRAND CITY"
                ? "THE GRAND ICONIC CITY"
                : localStorage.getItem("ProjectName")}
            </h4>
            <div className="col-6 mt-2">
              Voucher No:{" "}
              {this.props.printData !== undefined
                ? this.props.printData.InstalmentPaymentVoucherId
                : null}
            </div>
            <div className="col-6 mt-2 text-right">
              Voucher Date:{" "}
              {this.props.printData !== undefined
                ? moment(this.props.printData?.EntryDate?.split("T")[0]).format(
                    "DD-MMM-YYYY"
                  )
                : null}
            </div>

            <div className="col-6 mt-2">
              Payment Type:{" "}
              {this.props.printData !== undefined
                ? this.props.printData.PaymentType
                : null}
            </div>
            <div className="col-6 mt-2 text-right"></div>

            {/* <div className="col-12 mt-2">
                    Received with thanks from:
                </div>
            
                <div className="col-6 mt-2">
                    Name: {this.props.printData !== undefined ?

this.props.printData.AffiliateCnicNavigation.AffiliateName

                                : null}
                </div>
                <div className="col-6 mt-2 text-right">
                    CNIC:
                    {this.props.printData !== undefined ?

                        this.props.printData.Contract.AffiliateCnic

                                : null}
                </div>
                
                <div className="col-6 mt-2">
                    Cell: {this.props.printData !== undefined ?

                        this.props.printData.Contract.AffiliateCnicNavigation.PhoneNumber

                                : null}
                </div>
                 */}
            <div className="col-6 mt-2 text-right"></div>

            <div className="col-12 mt-3">
              <table className="table">
                <thead style={{ backgroundColor: "lightgray" }}>
                  <tr>
                    <th
                      style={{
                        borderLeft: "1px solid grey",
                        borderRight: "1px solid grey",
                      }}
                    >
                      Sr.
                    </th>
                    <th
                      style={{
                        borderLeft: "1px solid grey",
                        borderRight: "1px solid grey",
                      }}
                    >
                      Customer{" "}
                    </th>
                    <th
                      style={{
                        borderLeft: "1px solid grey",
                        borderRight: "1px solid grey",
                      }}
                    >
                      Reference
                    </th>

                    <th
                      style={{
                        borderLeft: "1px solid grey",
                        borderRight: "1px solid grey",
                      }}
                    >
                      Project{" "}
                    </th>
                    <th
                      style={{
                        borderLeft: "1px solid grey",
                        borderRight: "1px solid grey",
                      }}
                    >
                      Unit
                    </th>

                    <th
                      style={{
                        borderLeft: "1px solid grey",
                        borderRight: "1px solid grey",
                      }}
                    >
                      Narration
                    </th>
                    <th
                      style={{
                        borderLeft: "1px solid grey",
                        borderRight: "1px solid grey",
                      }}
                    >
                      Amount
                    </th>
                  </tr>
                </thead>

                {this.props.printData !== undefined ? (
                  <tbody>
                    <tr>
                      <td
                        style={{
                          borderLeft: "1px solid grey",
                          borderRight: "1px solid grey",
                        }}
                      >
                        1
                      </td>
                      {this.props.printData.CustomerName !== undefined &&
                      this.props.printData.CustomerName !== null ? (
                        <td
                          style={{
                            borderLeft: "1px solid grey",
                            borderRight: "1px solid grey",
                          }}
                        >
                          {this.props.printData.CustomerName}
                        </td>
                      ) : (
                        <td
                          style={{
                            borderLeft: "1px solid grey",
                            borderRight: "1px solid grey",
                          }}
                        ></td>
                      )}
                      <td
                        style={{
                          borderLeft: "1px solid grey",
                          borderRight: "1px solid grey",
                        }}
                      >
                        {this.props.printData.Reference}
                      </td>

                      {this.props.printData.Project !== undefined &&
                      this.props.printData.Project !== null ? (
                        <td
                          style={{
                            borderLeft: "1px solid grey",
                            borderRight: "1px solid grey",
                          }}
                        >
                          {this.props.printData.Project.ProjectName ===
                          "THE GRAND CITY"
                            ? "THE GRAND ICONIC CITY"
                            : this.props.printData.Project.ProjectName}
                        </td>
                      ) : (
                        <td
                          style={{
                            borderLeft: "1px solid grey",
                            borderRight: "1px solid grey",
                          }}
                        ></td>
                      )}
                      {this.props.printData.Unit !== undefined &&
                      this.props.printData.Unit !== null ? (
                        <td
                          style={{
                            borderLeft: "1px solid grey",
                            borderRight: "1px solid grey",
                          }}
                        >
                          {this.props.printData.Unit}
                        </td>
                      ) : (
                        <td
                          style={{
                            borderLeft: "1px solid grey",
                            borderRight: "1px solid grey",
                          }}
                        ></td>
                      )}

                      <td
                        style={{
                          borderLeft: "1px solid grey",
                          borderRight: "1px solid grey",
                        }}
                      >
                        {" "}
                        {this.props.printData.Detail}
                      </td>
                      <td
                        style={{
                          borderLeft: "1px solid grey",
                          borderRight: "1px solid grey",
                        }}
                      >
                        {parseFloat(
                          this.props.printData.Amount
                        ).toLocaleString()}
                      </td>
                    </tr>
                  </tbody>
                ) : null}
                <thead style={{ backgroundColor: "lightgray" }}>
                  <tr>
                    <th style={{ borderLeft: "1px solid grey" }}>Total</th>
                    <th></th>

                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>

                    <th
                      style={{
                        borderLeft: "1px solid grey",
                        borderRight: "1px solid grey",
                      }}
                    >
                      {this.props.printData !== undefined
                        ? parseFloat(
                            this.props.printData?.Amount
                          ).toLocaleString()
                        : null}
                    </th>
                  </tr>
                </thead>
              </table>
            </div>

            <div className="col-12 mt-2">
              Total Amount:{" "}
              {this.props.printData !== undefined
                ? parseFloat(this.props.printData?.Amount).toLocaleString()
                : null}
            </div>

            <div className="col-12 mt-2">
              Received Amount in words:{" "}
              {this.props.printData !== undefined ||
              this.props.printData?.Amount !== null
                ? converter.toWords(Number(this.props.printData?.Amount || 0))
                : // this.props.printData?.Amount
                  null}
            </div>

            {/* <div className="col-12 mt-2">
                    On account payment of shop:
                </div>                          
             */}

            <div className="col-6 mt-5 text-center">
              <b>___________________</b>
            </div>
            <div className="col-6 mt-5 text-center">
              <b>___________________</b>
            </div>
            <div className="col-6 mt-4 text-center">
              <b>Received By:</b>
            </div>
            <div className="col-6 mt-4 text-center">
              <b>Authorized By:</b>
            </div>
            <div className="col-12 mt-4">
              <hr />
            </div>
          </div>
        );
    }
}

export default InstallmentPayPrint;