import React from "react";
import { BiInfoCircle } from "react-icons/bi";
import SaveImage from "../../ModalImages/SaveImage";
import ListImage from "../../ModalImages/ListImage";
import DownIcon from "../../ModalImages/DownIcon";

function TransferContractModal() {
  return (
    <div>
      <div class="d-flex justify-content-center align-items-center">
        <span class="modal-btn" data-toggle="modal" data-target="#myModal2">
          <BiInfoCircle />
        </span>
      </div>

      <div
        class="modal right fade my-modal"
        id="myModal2"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myModalLabel2"
      >
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="myModalLabel2">
                Transfer Contract Info!
              </h4>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div class="modal-body">
              <ul className="modal-ul">
                <li>
                  <ListImage />
                  Old contract and its installment status changed to
                  ‘Transferred’.
                </li>
                <li>
                  <ListImage />
                  New contract and its installments are created with a new rate
                  and schedule.
                </li>
                <li>
                  <DownIcon />
                  Transactions are:
                  <ul>
                    <li>
                      <ListImage />
                      Old customer Credit with ‘CustomerReceivable’.
                    </li>
                    <li>
                      <ListImage />
                      New customer Debit with ‘CustomerReceivable’. If transfer
                      fee {">"} 0
                    </li>
                    <li>
                      <ListImage />
                      Old customer Debit with transfer fee.
                    </li>
                    <li>
                      <ListImage />
                      "TRANSFER FEE" account Credit with transfer fee.
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TransferContractModal;
