const allallowances = { data: [], loading: true };

export const GetAllAllowancesReducer = (state = allallowances, action) => {
    switch (action.type) {

        case "get_allallowances":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}
const alldesignation = { data: [], loading: true };

export const GetAllDesignationReducer = (state = alldesignation, action) => {
    switch (action.type) {

        case "get_alldesignation":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}
const allDeduction = { data: [], loading: true };

export const GetAllDeductionReducer = (state = allDeduction, action) => {
    switch (action.type) {

        case "get_allDeduction":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}