const alllenders = { data: [], loading: true };
const lenderdata = {
    // LenderId: 0,
    // LenderName: null,
    // LenderAddress: null,
    // ContactNumber1: null,
    // ContactNumber2: null,
    // PhoneNumber: null,
    // Country: null,
    // City: null,
    // Email: null,
    // LenderCnic: null,
    // Occupation: null,

    // AccountId: 0
    BuyerId: 0,
    BuyerName: null,
    BuyerAddress: null,
    BuyerPhoneNumber: null,
    BuyerCellNoOne: null,
    BuyerCellNoTwo: null,
    BuyerEmail: null,
    BuyerCnic: null,
    BuyerCity: null,
    BuyerCountry: null

};
export const GetAllAssetBuyerReducer = (state = alllenders, action) => {
    switch (action.type) {

        case "get_alllenders":
            return {

                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}
export const GetAssetBuyerdataReducer = (state = lenderdata, action) => {
    switch (action.type) {

        case "get_lendersdata":
            return {
                lenderdata: action.payload,

            }
        default:
            return {
                lenderdata: lenderdata,

            };
    }


}
