import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { TiPrinter } from "react-icons/ti";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { getallProject } from "../../../actions/getprojects";
import { AllProjectsPrint } from "../ProjectsPrint/AllProjectsPrint";
import ProjectsPrint from "../ProjectsPrint/ProjectsPrint";
import { CSVLink } from "react-csv";


export const ProjectTable = (props) => {
    const resultsallproject = useSelector((state) => state.getProject);
    const dispatch = useDispatch();
    const [filerunits, setfilterunits] = useState();
  
    const [printData, SetPrintData] = useState();
    // print function
    const componentRef = useRef();
  
    const printRecoveryBooking = useReactToPrint({
      content: () => componentRef.current,
    });
    useEffect(() => {
      if (printData !== undefined) {
        printRecoveryBooking();
      }
    }, [printData]);
    // all projects print
  
    const AllcomponentRef = useRef();
  
    const AllprintPaymentRec = useReactToPrint({
      content: () => AllcomponentRef.current,
    });
  
    //Table Data fiter
  
  
    const onTextChange = (e) => {
  
      if (document.getElementById("projectfilter").value === "" && document.getElementById("Category").value === "") {
        setfilterunits(resultsallproject.data);
  
      } else {
        let suggestions = [];
        {
          const regex = new RegExp(document.getElementById("projectfilter").value, "i");
          const regex1 = new RegExp(document.getElementById("Category").value, "i");
  
  
  
  
  
          suggestions = resultsallproject.data
            .sort()
            .filter((val) =>
              (document.getElementById("projectfilter").value === "" || regex.test(val.ProjectName)) &&
              (document.getElementById("Category").value === "" || regex1.test(val.ProjectCategory.ProjectCategoryName))
  
  
  
            );
  
        }
        setfilterunits(suggestions);
      }
    };
    useEffect(() => {
  
  
      dispatch(getallProject());
    }, [dispatch]
    )
    useEffect(() => {
      setfilterunits(resultsallproject.data);

    }, [resultsallproject]
    );
    const [CsvData, setCsvData] = useState([]);
    useEffect(() => {
      let arr = [];
      filerunits?.forEach((val, index) => {
        arr.push({
          Sr: index + 1,
          "Project Name": val.ProjectName,
          Category:
            val.ProjectCategory !== undefined && val.ProjectCategory !== null
              ? val.ProjectCategory.ProjectCategoryName
              : null,
          Owner: val.ProjectOwner,
          Location: val.ProjectLocation,
          City: val.ProjectCity,
          // "Pay Time": val.Installments + " Months",
          // "Marketing Exp%": val.MarketingExpensePercentage,
        });
      });
      setCsvData(arr);
    }, [filerunits]);
    return (
      <>
        <div hidden>
          <ProjectsPrint ref={componentRef} printData={printData} />
        </div>
        <div hidden>
          <AllProjectsPrint
            ref={AllcomponentRef}
            printData={resultsallproject}
          />
        </div>
        <div className="row pt-3 ">
          <div className="col-md-1 pr-0 pl-0">
            <label className="input_label m-0">Search:</label>
          </div>
          <div className="col-md-2 pr-0 pl-0">
            <input
              type="text "
              className="input_styling "
              id="projectfilter"
              onChange={onTextChange}
              placeholder="Project "
            ></input>
          </div>
          <div className="col-md-2 pr-0 pl-0">
            <input
              type="text "
              className="input_styling "
              id="Category"
              onChange={onTextChange}
              placeholder="Category"
            ></input>
          </div>

          <div className="col-md-1 pr-0 pl-0">
            {resultsallproject.loading ? (
              <Loader type="ThreeDots" color="green" height={40} width={40} />
            ) : null}
          </div>
        </div>
        <div className="row ">
          <div className=" col-12  table_height pr-0 pl-0">
            <div className="table-responsive mt-4">
              <table className="table table-borderless m-0">
                <thead>
                  <tr>
                    <th>Sr</th>
                    <th>Project Name</th>
                    <th> Category</th>
                    <th> Owner</th>
                    <th>Location</th>
                    <th>City</th>
                    {/* <th>Pay Time</th>
                    <th>Marketing Exp%</th> */}

                    <th className="text-center">
                      <button
                        className="save_btn"
                        onClick={() => {
                          props.showprojecttable();
                        }}
                      >
                        New+
                      </button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filerunits !== undefined
                    ? filerunits.map((val, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{val.ProjectName}</td>
                          <td>
                            {val.ProjectCategory !== undefined &&
                            val.ProjectCategory !== null
                              ? val.ProjectCategory.ProjectCategoryName
                              : null}
                          </td>
                          <td>{val.ProjectOwner}</td>
                          <td>{val.ProjectLocation}</td>
                          <td>{val.ProjectCity}</td>
                          {/* <td>{val.Installments + " Months"}</td>
                      <td>{val.MarketingExpensePercentage}</td> */}

                          <td></td>
                          {/* <td className="text-center cur_sor" onClick={() => SetPrintData(val)}>
                        <GrPrint size="1.3em" />
                      </td> */}
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-6  text-right pr-0 pl-0">
            <button
              className="print_btn float-left"
              onClick={() => {
                AllprintPaymentRec();
              }}
            >
              <TiPrinter size="1.3em" />
              Print
            </button>
            <button
              style={{ color: "black !important" }}
              className="print_btn ml-2 float-left"
            >
              <CSVLink
                style={{ color: "black" }}
                filename="Project_Csv"
                data={CsvData}
              >
                Download CSV
              </CSVLink>
            </button>
          </div>
        </div>
      </>
    );
  };