import { useState } from "react";
import AddCustomerForm from "./CustomerForm";
import { CustomerTable } from "./CustomerTable";

const MainCustomer = () => {
    const [stateedit, setedit] = useState("No");
  
    const showclienttable = () => {
      stateshowclient("addcustomer");
    };
    const cancelclienttable = () => {
      stateshowclient("customertable");
    };
    const [showclient, stateshowclient] = useState("customertable");
  
    return (
      <>
        <div className="row ">
          <div className="dash_background col-12 p-0">
            <div className="row ">
              <div className="col-md-12">
                {showclient === "customertable" ? (
                  <CustomerTable
                    showclienttable={showclienttable}
                    cancelclienttable={cancelclienttable}
                    setedit={setedit}
                  />
                ) : (
                  <AddCustomerForm
                    cancelclienttable={cancelclienttable}
                    stateedit={stateedit}
                    setedit={setedit}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  export default MainCustomer;