import { useState } from "react";
import { AffiliateForm } from "./AffiliateForm";
import { AffiliateTable } from "./AffiliateTable";

const MainAffiliates = () => {
    const[isedit ,setedit]=useState("noedit");

    const showaffiliatestable = () => {
        stateshowaffiliates(<AffiliateForm cancelaffiliatestable={cancelaffiliatestable}  isedit={isedit} setedit={setedit}  />);
    }
    const cancelaffiliatestable = () => {
        stateshowaffiliates(<AffiliateTable showaffiliatestable={showaffiliatestable} cancelaffiliatestable={cancelaffiliatestable} setedit={setedit} />);
    }
    const [showclient, stateshowaffiliates] = useState(<AffiliateTable showaffiliatestable={showaffiliatestable} cancelaffiliatestable={cancelaffiliatestable} setedit={setedit}  />);
    return (

        <>
            <div className="row ">
                <div className="dash_background col-12 p-0">
                    <div className="row ">

                        <div className="col-md-12">
                            {showclient}
                        </div>


                    </div>


                </div>
            </div>
        </>

    )
}
export default MainAffiliates;