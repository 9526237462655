const allpaymentinstallTableData = { data: [], loading: true };
export const GetAllPaymentInstallTableRed = (state = allpaymentinstallTableData, action) => {
    switch (action.type) {

        case "get_allpaymentinstalltabledata":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}