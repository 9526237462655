import React from "react";
import { BiInfoCircle } from "react-icons/bi";
import SaveImage from "../../ModalImages/SaveImage";
import ListImage from "../../ModalImages/ListImage";
import DownIcon from "../../ModalImages/DownIcon";

function ContractModal() {
  return (
    <div>
      <div class="d-flex justify-content-center align-items-center">
        <span class="modal-btn" data-toggle="modal" data-target="#myModal2">
          <BiInfoCircle />
        </span>
      </div>

      <div
        class="modal right fade my-modal"
        id="myModal2"
        role="dialog"
        aria-labelledby="myModalLabel2"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="myModalLabel2">
                Contract Info!
              </h4>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                style={{ cursor: "pointer" }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div class="modal-body">
              <div className="modal-scroll">
                <ul className="modal-ul">
                  <h4>Create Contract</h4>
                  <li>
                    <h5>
                      <SaveImage />
                      Save and submit
                    </h5>
                    <ul>
                      <li>
                        <ListImage />
                        Change unit status as SOLD.
                      </li>
                      <li>
                        <ListImage />
                        Can be deleted and edited at this stage.
                      </li>
                    </ul>
                  </li>
                  <h4>Generate Installments</h4>

                  <li>
                    <ListImage />
                    If project owner == personal
                    <ul>
                      <li>
                        <DownIcon />
                        If first time contract then transactions are :
                        <ul>
                          <li>
                            <ListImage />
                            “SALES” account credit with contract total amount
                          </li>
                          <li>
                            <ListImage />
                            Customer account debit with contract total amount.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <DownIcon />
                        If resale plot contract then transactions are :
                        <ul>
                          <li>
                            <ListImage />
                            “RESALE INCOME” account credit with contract total
                            amount
                          </li>
                          <li>
                            <ListImage />
                            Customer account debit with contract total amount.
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <ListImage />
                    If project owner is other
                    <ul>
                      <li>
                        <DownIcon />
                        If first time contract then transactions are :
                        <ul>
                          <li>
                            <ListImage />
                            “INVESTMENT ACCOUNT” account credit with contract
                            advance amount
                          </li>
                          <li>
                            <ListImage />
                            Customer account debit with contract advance amount.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <DownIcon />
                        If resale plot contract then transactions are :
                        <ul>
                          <li>
                            <DownIcon />
                            “RESALE INCOME” account credit with amount X
                            <ul>
                              <li>
                                <ListImage />
                                Premium amount = Total price of new contract -
                                Total amount of Unit purchase.
                              </li>
                              <li>
                                <ListImage />X = Premium amount + purchase cost
                                of plot
                              </li>
                            </ul>
                          </li>
                          <li>
                            <ListImage />
                            Customer account debit with amount X.
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>

                  <h4>Generate Commission</h4>
                  <li>
                    <DownIcon />
                    If first time contract
                    <ul>
                      <li>
                        <DownIcon />
                        If CompanyCommissionAmount {">"} 0
                        <ul>
                          <li>
                            <ListImage />
                            Transactions are: <br />
                            DealName + "'S DEAL COMM. R/A accounts Debit with
                            ‘CompanyCommissionAmount’ <br /> DealName + "'S DEAL
                            COMM. INC" accounts Credit with
                            ‘CompanyCommissionAmount’.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <DownIcon />
                        If ComissionAmount {">"} 0 || AdminShareAmount {">"} 0
                        <ul>
                          <li>
                            <ListImage />
                            Transactions are: <br /> ProjectName + "'S
                            COMMISSION EXPENSE" accounts debit with ‘Total
                            commission’ set as above.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <DownIcon />
                        If ComissionAmount {">"} 0
                        <ul>
                          <li>
                            <ListImage />
                            Check affiliate accounts available or not.
                          </li>
                          <li>
                            <ListImage />
                            Transactions are: <br /> Affiliate accounts credit
                            with ‘ComissionAmount’.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <DownIcon />
                        If AdminShareAmount {">"} 0
                        <ul>
                          <li>
                            <ListImage />
                            Transactions are: <br /> {"{project.ProjectName}"}'S
                            ADMIN SHARE PAYABLE" accounts Credit with
                            ‘AdminShareAmount’.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <DownIcon />
                        If ReservedCommissionAmount {">"} 0
                        <ul>
                          <li>
                            <ListImage />
                            Transactions are: <br />{" "}
                            AffiliateAccountReserveCommission’ accounts Credit
                            with ‘ReservedCommissionAmount’.
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <DownIcon />
                    If investment involve
                    <ul>
                      <li>
                        <ListImage />
                        Project lenders account Credit with TotalPrice.
                      </li>
                      <li>
                        <ListImage />
                        ProjectName + "'S RETURN ON INVESTMENT" Debit with
                        Profit amount. <br />
                        ProfitAmount = Loan.NetProfit * (contract’s TotalPrice -
                        unit’s TotalCost) / 100
                      </li>
                      <li>
                        <ListImage />
                        Users can update commission, advance amount and nominee
                        from the Edit icon. Transactions will also be updated.
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContractModal;
