import React, { Component } from "react";
import gravity from "../../../../assets/GravityLogo.png";
import moment from "moment";

class ExternalCommissionPrint extends Component {
  render() {
    return (
      <div style={{ backgroundColor: "white", height: "100%" }}>
        <div className="row m-0 p-0">
          <div className="watermarked">
            <img
              src={gravity}
              style={{ height: "600px", width: "600px" }}
              alt="Company logo"
            />
          </div>
          <div className="col-8 text-left mt-4">
            <h2 style={{ color: "#1b7a7f" }}>Gravity Associates Pvt Limited</h2>
            <hr />
            <h5 style={{ color: "grey", marginTop: "5px" }}>
              <i>204 R/B Road, East Canal Road, Faisalabad.</i>
            </h5>
            <div
              style={{
                borderTop: "2px dotted black",
                borderBottom: "2px dotted black",
                height: "7px",
              }}
            ></div>
          </div>
          <div className="col-4 text-center">
            {/* IMAGE COMES HERE */}

            <img
              src={gravity}
              style={{ height: "175px", width: "175px", borderRadius: "100px" }}
              className="pt-2"
              alt="Company logo"
            />
          </div>
        </div>

        <div
          className="row m-3 p-0"
          // style={{ border: "5px solid darkgrey", height: "auto" }}
        >
          <div className="col-12 text-center mt-2">
            <h4 style={{ color: "red" }}>
              <u>
                External{" "}
                {this.props?.CommType[0] +
                  this.props?.CommType.slice(1).toLowerCase()}{" "}
                Commission
              </u>
            </h4>
            {this.props?.Data !== null ? (
              <div className="mt-5">
                <div class=" col-12">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>Sr</th>
                        <th>Date</th>
                        <th>Unit No.</th>
                        <th>Address</th>
                        <th>Customer Name</th>
                        <th>Seller Name</th>
                        <th>Contarct Price</th>
                        <th>Commission Amount</th>
                        <th>Narration</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>{1}</td>
                        <td>
                          {moment(this.props?.Data?.Date).format("YYYY-MM-DD")}
                        </td>
                        <td>{this.props?.Data?.UnitNumber}</td>
                        <td>{this.props?.Data?.Address}</td>
                        <td>{this.props?.Data?.CustomerName}</td>
                        <td>{this.props?.Data?.SellerName}</td>
                        <td>
                          {this.props?.Data?.ContractPrice?.toLocaleString()}
                        </td>
                        <td>
                          {this.props?.Data?.CommissionAmount?.toLocaleString()}
                        </td>
                        <td>{this.props?.Data?.Narration}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default ExternalCommissionPrint;
