const allLandPaymentPopulate = { data: [], loading: true };
const allLandTableData = { data: [], loading: true };

export const GetAllLandPaymentPopulateRed = (state = allLandPaymentPopulate, action) => {
    switch (action.type) {

        case "get_allLandPayment":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}
export const GetAllLandTableRed = (state = allLandTableData, action) => {
    switch (action.type) {

        case "get_allLandTableData":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}
