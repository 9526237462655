const CommissionPayment = { data: [], loading: true };
export const GetComPaymentReportRed = (state = CommissionPayment, action) => {
    switch (action.type) {

        case "get_allcompaymentreport":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}