const allgetallcomissionpayment = { data: [], loading: true };

const allcomissionpaymentTableData = { data: [], loading: true };

export const GetCommissionPaymentRed = (state = allgetallcomissionpayment, action) => {
    switch (action.type) {

        case "get_allcomissionpayment":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}
export const GetAllCommissionPaymentTableRed = (state = allcomissionpaymentTableData, action) => {
    switch (action.type) {

        case "get_allcomissionpaymenttabledata":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}