import { useState } from "react";
import { RePurchase } from "./RePurchase";
import { RePurchaseTable } from "./RePurchaseTable";

const MainRePurchase = () => {
    const showprojecttable = () => {
      stateshowproject(<RePurchase cancelprojecttable={cancelprojecttable} />);
    };
    const cancelprojecttable = () => {
      stateshowproject(
        <RePurchaseTable
          showprojecttable={showprojecttable}
          cancelprojecttable={cancelprojecttable}
        />
      );
    };
    const [showproject, stateshowproject] = useState(
      <RePurchaseTable
        showprojecttable={showprojecttable}
        cancelprojecttable={cancelprojecttable}
      />
    );
  
    return (
      <>
        <div className="row  ">
          <div className="dash_background col-12 p-0">
            <div className="row ">
              <div className="col-md-12">{showproject}</div>
            </div>
          </div>
        </div>
      </>
    );
  };
  
  export default MainRePurchase;