import React from "react";
import { BsFillInfoSquareFill } from "react-icons/bs";
import { Tooltip as BsTooltip } from "bootstrap";
import { useEffect, useRef } from "react";

export const Tooltip = (p) => {
  const childRef = useRef(undefined);

  useEffect(() => {
    const t = new BsTooltip(childRef.current, {
      title: p.text,
      placement: "right",
      trigger: "hover",
    });
    return () => t.dispose();
  }, [p.text]);

  return React.cloneElement(p.children, { ref: childRef });
};
const FormHeaderWithInfo = ({ HeaderTitle, Info }) => {
  return (
    <div className="row ">
      <div className="col-md-12  text-left page_heading">
        <h4 className="m-0">{HeaderTitle}</h4>
        <Tooltip text={Info}>
          <div style={{ cursor: "pointer" }} data-toggle="tooltip" title={""}>
            <BsFillInfoSquareFill size={"1.4rem"} />
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default FormHeaderWithInfo;
