
const allVouch = { data: [], loading: true };


export const GetAllUnSubmittedVouchers = (state = allVouch, action) => {
    switch (action.type) {

        case "get_unsubmittedVouch":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}