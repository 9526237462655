import { useState } from "react";
import { TaxForm } from "./TaxForm";
import { TaxTable } from "./TaxTable";


const MainTaxDetail = () => {

    const showtaxtable = () => {
        stateshowtax(<TaxForm canceltaxtable={canceltaxtable} />);
    }
    const canceltaxtable = () => {
        stateshowtax(<TaxTable showtaxtable={showtaxtable} canceltaxtable={canceltaxtable} />);
    }
    const [showtax, stateshowtax] = useState(<TaxTable showtaxtable={showtaxtable} canceltaxtable={canceltaxtable} />);

    return (

        <>
            <div className="row  ">
                <div className="dash_background col-12 p-0">
                    <div className="row ">

                        <div className="col-md-12">
                            {showtax}

                        </div>


                    </div>


                </div>
            </div>
        </>

    )
}

export default MainTaxDetail;