const allgetcomreceipt = { data: [], loading: true };
const allcomreceiptTableData = { data: [], loading: true };

export const GetComReceiptRed = (state = allgetcomreceipt, action) => {
    switch (action.type) {

        case "get_allcommissionreceipt":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}
export const GetAllComReceiptTableRed = (state = allcomreceiptTableData, action) => {
    switch (action.type) {

        case "get_allComRectabledata":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}