import { useState } from "react";

import {NewPurchaseForm } from "./NewPurchase"
import { NewPurchaseTable } from "./NewPurchaseTable";


const MainNewPurchase = () => {

    const showunittable = () => {
        stateshowunit(<NewPurchaseForm cancelunittable={cancelunittable} />);
    }
    const cancelunittable = () => {
        stateshowunit(<NewPurchaseTable showunittable={showunittable} cancelunittable={cancelunittable} />);
    }
    const [showunit, stateshowunit] = useState(<NewPurchaseTable showunittable={showunittable} cancelunittable={cancelunittable} />);

    return (

        <>
            <div className="row ">
                <div className="dash_background col-12 p-0">
                    <div className="row ">

                        <div className="col-md-12">
                            {showunit}
                        </div>


                    </div>


                </div>
            </div>
        </>

    )
}
export default MainNewPurchase;