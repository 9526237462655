const AccBalanceSummary = { data: [], loading: true };
export const GetAccBalanceSummary = (state = AccBalanceSummary, action) => {
    switch (action.type) {

        case "get_allaccbalance":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}
