const allgetloanreceipt = { data: [], loading: true };
const allloanrecTableData = { data: [], loading: true };

export const GetLoanReceiptRed = (state = allgetloanreceipt, action) => {
    switch (action.type) {

        case "get_allgetloanreceipt":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}
export const GetAllLoanReceiptTableRed = (state = allloanrecTableData, action) => {
    switch (action.type) {

        case "get_allloanrectabledata":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}
