import React from "react";
import submitImage from "../../../assets/icons8-submit-document-50.png";

const SubmitImage = () => {
  return (
    <img
      src={submitImage}
      style={{ width: "25px" }}
      alt="img"
      className="mr-2"
    />
  );
};

export default SubmitImage;
