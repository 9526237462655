import React, { useState } from "react";
import { PayAdvance, PayAdvanceTable } from "./addadvance";

import { PayLoan, PayLoanTable } from "./addloan";


const MainAdvanceLoanComp = () => {
    const [tab, settab] = useState("payadvance");
    const [stateadvanceedit, setadvanceedit] = useState([]);
    const showpayadvancetable = () => {
        stateshowpayadvance("advanceadd");
    }
    const cancelpayadvancetable = () => {
        stateshowpayadvance("advancetable");
        setadvanceedit([]);
    }
    const [showpayadvance, stateshowpayadvance] = useState("advancetable");
    // for payloan
    const showpayloantable = () => {
        statshowpayloan("payadd");
    }
    const cancelpayloantable = () => {
        statshowpayloan("paytable");
    }
    const [showpayloan, statshowpayloan] = useState("paytable");
    return (

        <>
            <div className="row  ">
                <div className="dash_background col-12 p-0">
                    <div className="row ">
                        <div className="col-md-12 text-center  p-0">

                            <button
                                className={tab === "payadvance" ? "adjust_btns_selected  " : "adjust_btns  "}
                                onClick={() => {
                                    settab("payadvance");

                                }}
                            >
                                <span className="comp_btns_text">Advance</span>

                            </button>


                            <button
                                className={tab === "payloan" ? "adjust_btns_selected ml-1 " : "adjust_btns ml-1 "}
                                onClick={() => {
                                    settab("payloan");

                                }}
                            >
                                <span className="comp_btns_text">Loan </span>

                            </button>


                        </div>
                        <div className="col-md-12">
                            {tab === "payadvance" ? showpayadvance === "advanceadd" ? <PayAdvance cancelpayadvancetable={cancelpayadvancetable} setadvanceedit={setadvanceedit} stateadvanceedit={stateadvanceedit} /> : <PayAdvanceTable showpayadvancetable={showpayadvancetable} setadvanceedit={setadvanceedit} stateadvanceedit={stateadvanceedit} cancelpayadvancetable={cancelpayadvancetable} />
                                : tab === "payloan" ? showpayloan === "payadd" ? <PayLoan cancelpayloantable={cancelpayloantable} /> : <PayLoanTable showpayloantable={showpayloantable} cancelpayloantable={cancelpayloantable} />
                                    : null
                            }
                        </div>


                    </div>


                </div>
            </div>
        </>

    )
}

export default MainAdvanceLoanComp;