const Maintenance = { data: [], loading: true };

export const GetAssetsMaintenenceReducer = (state = Maintenance, action) => {
    switch (action.type) {

        case "get_allMaintenence":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}