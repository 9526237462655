import React from "react";
import { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import Loader from "react-loader-spinner";

const UnitReceiptDeleteVoucherModal = ({
  closemodal2,
  DeleteVoucherData,
  handelDeleteVouvherdataChange,
  loading2,
  setunitRecieptRowData,
  deleteUnitRecipteVoucher,
}) => {
  const [showPassword, setshowPassword] = useState(false);
  return (
    <div
      id="mymodal2"
      class="modal"
    >
      <div class="modal-content-cat_voucher2">
        <div className="row ">
          <div className="col-12  page_heading">
            <h4 className="  text-center pt-2">Delete Voucher</h4>
            <span
              class="close pr-2  "
              onClick={closemodal2}
            >
              &times;
            </span>
          </div>
        </div>
        <div
          className="row"
          style={{ width: "100%" }}
        >
          <div
            className="col-12 "
            style={{ paddingTop: "10px" }}
          >
            <label className="input_label m-0">Email:</label>
          </div>
          <div className="col-12 ">
            <input
              value={DeleteVoucherData?.Email}
              onChange={handelDeleteVouvherdataChange}
              name="Email"
              autoComplete="off"
              readOnly
              onMouseDown={(e) => {
                e.target.removeAttribute("readOnly");
              }}
              style={{ width: "100%" }}
              className="input_total_styling "
              placeholder="Email"
              id="unitRecieptVoucherEmail"
              type="email"
            />
          </div>
          <div
            className="col-12 "
            style={{ paddingTop: "10px" }}
          >
            <label className="input_label m-0">Password:</label>
          </div>
          <div className="col-12 ">
            <input
              value={DeleteVoucherData?.Password}
              onChange={handelDeleteVouvherdataChange}
              name="Password"
              autoComplete="off"
              readOnly
              onMouseDown={(e) => {
                e.target.removeAttribute("readOnly");
              }}
              type={showPassword ? "text" : "password"}
              style={{ width: "100%" }}
              className="input_total_styling "
              placeholder="Password"
              id="unitRecieptVoucherPassword"
            />
            {showPassword ? (
              <AiOutlineEye
                onClick={() => {
                  setshowPassword(!showPassword);
                }}
                size={"1.3em"}
                id="togglePassword"
                style={{ marginLeft: "-30px" }}
              />
            ) : (
              <AiOutlineEyeInvisible
                onClick={() => {
                  setshowPassword(!showPassword);
                }}
                size={"1.3em"}
                id="togglePassword"
                style={{ marginLeft: "-30px" }}
              />
            )}
          </div>
          <div
            className="col-12  "
            style={{ paddingTop: "10px" }}
          >
            <label className="input_label m-0">Reason:</label>
          </div>
          <div
            className="col-12  "
            style={{ width: "100%" }}
          >
            <textarea
              value={DeleteVoucherData?.Reason}
              onChange={handelDeleteVouvherdataChange}
              name="Reason"
              autoComplete="off"
              style={{ width: "100%", height: "100px" }}
              className="input_total_styling "
              placeholder="Reason"
              id="unitRecieptVoucherReason"
              rows="4"
            />
          </div>
          <div
            style={{
              display: "flex",
              float: "right",
              width: "100%",
              justifyContent: "end",
              marginRight: "3%",
              marginTop: "10px",
              alignItems: "center",
            }}
          >
            {loading2 && (
              <Loader
                type="ThreeDots"
                color="green"
                height={40}
                width={40}
              />
            )}
            <div>
              <span>
                <button
                  className="cancel_btn ml-2  "
                  onClick={() => {
                    closemodal2();
                    setunitRecieptRowData(null);
                  }}
                >
                  Cancel
                </button>
              </span>
            </div>
            <div>
              <span>
                <button
                  className="save_btn ml-2  "
                  disabled={loading2}
                  onClick={deleteUnitRecipteVoucher}
                >
                  Save
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnitReceiptDeleteVoucherModal;
