const allgetloanPayment = { data: [], loading: true };
const allloanpayTableData = { data: [], loading: true };

export const GetLoanPaymentRed = (state = allgetloanPayment, action) => {
    switch (action.type) {

        case "get_allgetloanpayment":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}
export const GetAllLoanPaymentTableRed = (state = allloanpayTableData, action) => {
    switch (action.type) {

        case "get_allloanpaytabledata":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}
