import React, { Component } from "react";
import gravity from "../../../../assets/GravityLogo.png";

export class ComPaySoldIndivdualPrint extends Component {
    render() {

        return (
            <div style={{ backgroundColor: "white", height: "100%" }}>
                <div className="row m-0 p-0">
                    <div className="watermarked">
                        <img
                            src={gravity}
                            style={{ height: "600px", width: "600px" }}
                            alt="Company logo"
                        />
                    </div>
                    <div className="col-8 text-left mt-4">
                        <h2 style={{ color: "#1b7a7f" }}>Gravity Associates Pvt Limited</h2>
                        <hr />
                        <h5 style={{ color: "grey", marginTop: "5px" }}>
                            <i>
                                204 R/B Road, East Canal Road, Faisalabad.
                            </i>
                        </h5>
                        <div
                            style={{
                                borderTop: "2px dotted black",
                                borderBottom: "2px dotted black",
                                height: "7px",
                            }}
                        ></div>
                    </div>
                    <div className="col-4 text-center">
                        {/* IMAGE COMES HERE */}
                        <img
                            src={gravity}
                            style={{ height: "175px", width: "175px", borderRadius: "100px" }}
                            className="pt-2"
                            alt="Company logo"
                        />
                    </div>
                </div>
                <div
                    className="row m-3 p-0"
                // style={{ border: "5px solid darkgrey", height: "85%" }}
                >
                    <div className="col-12 text-center mt-2">
                        <h4 style={{ color: "red" }}>
                            <u>Deal Sold Commission Payment Units</u>
                        </h4>
                        {/* <h5>
                            <b>Entry Date:{" "}</b>
                            {this.props.printData !== undefined ?

                                this.props.printData.EntryDate.split("T")[0]

                                : null}
                        </h5>
                        <h5>
                            <b>Exp Date:{" "}</b>
                            {this.props.printData !== undefined ?

                                this.props.printData.ExpDate.split("T")[0]

                                : null}
                        </h5> */}

                        <div className="row">
                            <div className="col-6 mt-4">

                                <ul>

                                    <li>
                                        <b>Deal Name:</b>{" "}
                                        {this.props.printData !== undefined ? this.props.printData.DealName : null}
                                    </li>


                                    <li>
                                        <b>Deal Amount:</b>{" "}
                                        {this.props.printData !== undefined ? this.props.printData.DealAmount.toLocaleString() : null}
                                    </li>


                                </ul>

                            </div>
                            <div className="col-6 mt-4">
                                <ul>

                                    <li>
                                        <b>Amount Received: </b>{" "}
                                        {this.props.printData !== undefined ? this.props.printData.AmountReceived.toLocaleString() : null}
                                    </li>
                                    <li>
                                        <b>Amount Used:</b>{" "}
                                        {this.props.printData !== undefined ? this.props.printData.AmountUsed.toLocaleString() : null}
                                    </li>






                                </ul>
                            </div>
                        </div>


                        <div className="mt-5">
                            <div class=" col-12">
                                <table class="table ">
                                    <thead class="purple whiteText">
                                        <tr>
                                            <th scope="col">Sr</th>


                                            <th scope="col">Unit</th>
                                            <th scope="col">Size</th>

                                            <th scope="col">Rate</th>
                                            <th scope="col">Total Value</th>


                                            <th scope="col">Comission Exp</th>


                                            <th scope="col">Paid</th>
                                            <th scope="col">Payable</th>
                                            <th scope="col">Status</th>

                                            <th scope="col">Reference
                                                </th>




                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.printData === undefined || this.props.printData.Units === undefined

                                            // contractinstallment.length == 0
                                            ? null
                                            : this.props.printData.Units.map((x, id) => (
                                                <>  <tr key={id} >
                                                    <td>{id + 1}</td>
                                                    <td>{x.UnitName}</td>
                                                    <td>{x.RatePerMarlaOrsqf == "MARLA" ? x.NetMarla + "M" : x.NetSqft + "Sqft"}</td>
                                                    <td>{x.RatePerMarla.toLocaleString()}</td>
                                                    <td>{x.Price !== undefined && x.Price !== null ? x.Price.toLocaleString() : null}</td>

                                                    <td>
                                                        {x.Contract[0].CommissionAmountPayable !== null && x.Contract[0].CommissionAmountPayable !== undefined ? x.Contract[0].CommissionAmountPayable.toLocaleString() : null}
                                                    </td>
                                                    <td> {x.Payments !== null && x.Payments !== undefined ? x.Payments.toLocaleString() : null}
                                                    </td>
                                                    <td> {x.Contract[0].CommissionAmountPayable !== null && x.Contract[0].CommissionAmountPayable !== undefined ? x.Contract[0].CommissionAmountPayable.toLocaleString() : null}
                                                    </td>
                                                    <td>  {x.Contract[0].ContractStatus !== null && x.Contract[0].ContractStatus !== undefined ? x.Contract[0].ContractStatus.toLocaleString() : null}
                                                    </td>
                                                    <td>   {x.Contract[0].Reference !== null && x.Contract[0].Reference !== undefined ? x.Contract[0].Reference.toLocaleString() : null}

                                                    </td>
                                                </tr>

                                                    <> {x.Contract.length > 1 ?
                                                        x.Contract.map((y, idx) => (
                                                            <>
                                                                {/* <tr>
                                                                <th scope="col">Comission Exp</th>


                                                                <th scope="col">Paid</th>
                                                                <th scope="col">Payable</th>
                                                                <th scope="col">Status</th>

                                                                <th scope="col">Reference
                                                                </th>
                                                            </tr> */}

                                                                {idx > 0 ? <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>
                                                                        {y.CommissionAmountPayable !== null && y.CommissionAmountPayable !== undefined ? y.CommissionAmountPayable.toLocaleString() : null}
                                                                    </td>
                                                                    <td> {x.Payments !== null && x.Payments !== undefined ? x.Payments.toLocaleString() : null}
                                                                    </td>
                                                                    <td> {y.CommissionAmountPayable !== null && y.CommissionAmountPayable !== undefined ? y.CommissionAmountPayable.toLocaleString() : null}
                                                                    </td>
                                                                    <td>  {y.ContractStatus !== null && y.ContractStatus !== undefined ? y.ContractStatus.toLocaleString() : null}
                                                                    </td>
                                                                    <td>   {y.Reference !== null && y.Reference !== undefined ? y.Reference.toLocaleString() : null}

                                                                    </td>
                                                                </tr> : null}
                                                            </>
                                                        ))



                                                        : null}</>
                                                    <tr >
                                                        <td></td>
                                                        <td></td>
                                                        <td colSpan="2" className="text-center"> Grand Total</td>

                                                        <td>{this.props.printData !== undefined && this.props.printData.TotalPrice !== undefined && this.props.printData.TotalPrice !== null  ? this.props.printData.TotalPrice.toLocaleString() : 0}</td>
                                                        <td>{this.props.printData !== undefined && this.props.printData.CommissionExpense !== undefined && this.props.printData.CommissionExpense !== null  ? this.props.printData.CommissionExpense.toLocaleString() : 0}</td>
                                                        <td>{this.props.printData !== undefined && this.props.printData.Paid !== undefined && this.props.printData.Paid !== null ? this.props.printData.Paid.toLocaleString() : 0}</td>
                                                        <td>{this.props.printData !== undefined && this.props.printData.Payable !== undefined && this.props.printData.Payable !== null ? this.props.printData.Payable.toLocaleString() : 0}</td>
                                                        <td></td>
                                                        <td></td>










                                                    </tr>

                                                </>

                                            ))}
                                    </tbody>
                                </table>

                            </div>
                        </div>


                    </div>
                </div>
            </div>
        );
    }
}