import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { FiEdit2 } from "react-icons/fi";
import { TiPrinter } from "react-icons/ti";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import {
  GetAllLenders,
  getalllenders,
  GetLenderData,
} from "../../../actions/getlender";
import InvestorPrint from "../InvestmentPrint/InvestorPrint";
import { CSVLink } from "react-csv";


export const InvestorTable = (props) => {
  const [lenderdata, setlenderdata] = useState({});
  const resultalllenders = useSelector((state) => state.getAllLenders);
  const lenderdatastate = useSelector((state) => state.getLendersdatastate);
  const rolesdata = useSelector((state) => state.GetAllRole);

  let dispatch = useDispatch();
  // print function
  const componentRef = useRef();

  const printPaymentRec = useReactToPrint({
    content: () => componentRef.current,
  });
  //Table Data fiter

  var suggestionlist = [];

  const onTextChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      dispatch(getalllenders());
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");

        suggestions = resultalllenders.data
          .sort()
          .filter((val) => regex.test(val.LenderName));
      }
      suggestionlist = suggestions;
      dispatch(GetAllLenders(suggestionlist));
    }
  };
  useEffect(() => {
    dispatch(getalllenders());
  }, [dispatch]);
  function showmodal(data) {
    document.getElementById("mymodal").style.display = "block";
    setlenderdata(data);
  }
  function closemodal() {
    document.getElementById("mymodal").style.display = "none";
  }

  const [CsvData, setCsvData] = useState([]);
  useEffect(() => {
    let arr = [];
    var i = 1;

    resultalllenders.data?.forEach((val) => {
      arr.push({
        Sr: i++,
        Name: val.LenderName,
        CNIC: val.LenderCnic,
        Occupation: val.Occupation,
        "Contact No.": val.ContactNumber1,
        City: val.City,
      });
    });
    setCsvData(arr);
  }, [resultalllenders.data]);

  return (
    <>
      <div hidden>
        <InvestorPrint ref={componentRef} printData={resultalllenders} />
      </div>
      <div className="row pt-3 ">
        <div className="col-md-1 pr-0 pl-0">
          <label className="input_label  m-0">Search:</label>
        </div>
        <div className="col-md-3   pr-0 pl-0">
          <input
            type="text "
            className="input_styling "
            onChange={onTextChange}
            placeholder="Investor Name"
          ></input>
        </div>
        {/* 
                <div className="col-md-3 text-center  pr-0 pl-0">
                    <label className="input_label m-0">From:</label>
                    <input type="date" className="input_date ml-3" id="from date"></input>
                </div>
                <div className="col-md-3 text-center pr-0 pl-0">
                    <label className="input_label m-0">To:</label>
                    <input type="date" className="input_date ml-3" id="todate"></input>
                </div>
                <div className="col-md-1 pr-0 pl-0">
                    <button className="btn_Go">Go</button>
                </div> */}
        <div className="col-md-1 pr-0 pl-0">
          {resultalllenders.loading ? (
            <Loader type="ThreeDots" color="green" height={40} width={40} />
          ) : null}
        </div>
      </div>

      <div className="row ">
        <div className=" col-12  table_height pr-0 pl-0">
          <div className="table-responsive mt-4">
            <table className="table table-borderless table-hover m-0">
              <thead>
                <tr>
                  <th>Sr</th>
                  <th>Name</th>

                  <th>CNIC</th>
                  <th>Occupation</th>
                  <th>Contact No.</th>
                  <th>City</th>

                  <th className="text-center">
                    {rolesdata?.data?.Access?.includes("C") ? (
                      <button
                        className="save_btn"
                        onClick={() => {
                          props.showlendertable();
                        }}
                      >
                        New+
                      </button>
                    ) : null}
                  </th>
                </tr>
              </thead>
              <tbody>
                {resultalllenders.data.map((val, index) => (
                  <tr
                    key={index}
                    onClick={() => {
                      if (rolesdata?.data?.Access?.includes("U")) {
                        showmodal(val);
                      }
                    }}
                  >
                    <td className="table_data">{index + 1}</td>
                    <td className="table_data">{val.LenderName}</td>

                    <td className="table_data">{val.LenderCnic}</td>
                    <td className="table_data">{val.Occupation}</td>
                    <td className="table_data">{val.ContactNumber1}</td>
                    <td className="table_data">{val.City}</td>
                    <td className="text-center">
                      {rolesdata?.data?.Access?.includes("U") ? (
                        <FiEdit2 size="1em" />
                      ) : null}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-6  text-right pr-0 pl-0">
          <button
            className="print_btn float-left"
            onClick={() => {
              printPaymentRec();
            }}
          >
            <TiPrinter size="1.3em" />
            Print
          </button>
          <button
            style={{ color: "black !important" }}
            className="print_btn ml-2 float-left"
          >
            <CSVLink
              style={{ color: "black" }}
              filename="Investor_Csv"
              data={CsvData}
            >
              Download CSV
            </CSVLink>
          </button>
        </div>
      </div>

      <div id="mymodal" className="modal">
        <div className="modal-content-cat">
          <div className="row">
            <div className="col-12  page_heading">
              <h4 className="  text-center pt-2">Investor Details</h4>
              <span class="close pr-2  " onClick={closemodal}>
                &times;
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-6 mt-2">
              <ul>
                <li>
                  <b>Name:</b> {lenderdata.LenderName}
                </li>
                <li>
                  <b>Email:</b> {lenderdata.Email}
                </li>
                <li>
                  <b>CNIC:</b> {lenderdata.LenderCnic}
                </li>
                <li>
                  <b>Occupation:</b> {lenderdata.Occupation}
                </li>
                <li>
                  <b>Contact No.1:</b> {lenderdata.ContactNumber1}
                </li>
                <li>
                  <b>Contact No.2:</b> {lenderdata.ContactNumber2}
                </li>
              </ul>
            </div>
            <div className="col-6 mt-2">
              <ul>
                <li>
                  <b>Country:</b> {lenderdata.Country}
                </li>
                <li>
                  <b>City:</b> {lenderdata.City}
                </li>
                <li>
                  <b>Phone No:</b> {lenderdata.PhoneNumber}
                </li>
                <li>
                  <b>Address:</b> {lenderdata.LenderAddress}
                </li>
              </ul>
            </div>
          </div>

          <div className="col-12 text-right">
            <button
              className=" save_btn   "
              onClick={() => {
                dispatch(GetLenderData(lenderdata));
                closemodal();
                props.showlendertable();
              }}
            >
              <FiEdit2 size="0.8em" /> Edit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
