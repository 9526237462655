const allLoanpopulate = { data: [], loading: true };

export const GetAllLoanPopulate = (state = allLoanpopulate, action) => {
    switch (action.type) {

        case "get_allLoanpopulate":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}
const allLoantable = { data: [], loading: true };

export const GetAllLoanTable = (state = allLoantable, action) => {
    switch (action.type) {

        case "get_allLoantable":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}
const allAdvancetable = { data: [], loading: true };

export const GetAllAdvanceTable = (state = allAdvancetable, action) => {
    switch (action.type) {

        case "get_alladvancetable":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}