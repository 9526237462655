import React from "react";
import listImage from "../../../assets/icons8-next-page-50.png";

const ListImage = () => {
  return (
    <img src={listImage} style={{ width: "18px" }} alt="img" className="mr-2" />
  );
};

export default ListImage;

