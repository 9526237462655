import { TreeItem, TreeView } from "@material-ui/lab";
import { useEffect } from "react";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { GetAllChartOfAcc } from "../../../actions/chartofacc/getchartofacc";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChartofAccountModal from "./ChartofAccountModal";


const useStyles = makeStyles({
    root: {
      height: 240,
      flexGrow: 1,
      maxWidth: 400,
    },
  });
const ChartofAccount = () => {
    const chartTreeView = useSelector(state => state.getChartOfAcc);
  
    let dispatch = useDispatch();
  
  
  
    const classes = useStyles();
  
    useEffect(() => {
      dispatch(GetAllChartOfAcc());
    }, [dispatch])
    return (
      <>
        <div className="row  ">
          <div className="dash_background col-12 ">
            <div className="row pb-3">
              <div className="col-md-12  text-left page_heading">
                <h4 className="m-0">Chart of Account  </h4>
                <ChartofAccountModal/>
  
              </div>
  
            </div>
            <div>
              {chartTreeView.loading ? <Loader type="ThreeDots"
                color="green"
                height={40}
                width={40} /> : null}
            </div>
            <div className=" w-100 ">
              <TreeView
                className={classes.root}
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
              >
                {chartTreeView.data.map((node, index) => (
                  <TreeItem
                    nodeId={node.headingId + "H"}
                    label={
                      <div style={{ width: "100%" }}>
                        {node.headingIdCode + " | " + node.headingTitle}
                        <span style={{ float: "right" }}>
                          {parseFloat(node.balance).toLocaleString()}
                        </span>
                      </div>
                    }
                  >
                    {node.account.map((child) => (
                      <TreeItem
                        nodeId={child.accountIdCode + "A"}
                        label={
                          <div style={{ width: "100%" }}>
                            {child.accountIdCode + " | " + child.accountTitle}
                            <span style={{ float: "right" }}>
                              {parseFloat(
                                child.currentBalance
                              ).toLocaleString()}
                            </span>
                          </div>
                        }
                      />
                    ))}
                    {node.inverseParent.map((child) => (
                      <TreeItem
                        nodeId={child.headingIdCode + "H"}
                        label={
                          <div style={{ width: "100%" }}>
                            {child.headingIdCode + " | " + child.headingTitle}
                            <span style={{ float: "right" }}>
                              {parseFloat(child.balance).toLocaleString()}
                            </span>
                          </div>
                        }
                      >
                        {child.account.map((child1) => (
                          <TreeItem
                            nodeId={child1.accountIdCode + "A"}
                            label={
                              <div style={{ width: "100%" }}>
                                {child1.accountIdCode + " | " + child1.accountTitle}
                                <span style={{ float: "right" }}>
                                  {parseFloat(
                                    child1.currentBalance
                                  ).toLocaleString()}
                                </span>
                              </div>
                            }
                          />
                        ))}
                        {child.inverseParent.map((child1) => (
                          <TreeItem
                            nodeId={child1.headingIdCode + "H"}
                            label={
                              <div style={{ width: "100%" }}>
                                {child1.headingIdCode + " | " + child1.headingTitle}
                                <span style={{ float: "right" }}>
                                  {parseFloat(child1.balance).toLocaleString()}
                                </span>
                              </div>
                            }
                          >
                            {child1.account.map((child2) => (
                              <TreeItem
                                nodeId={child2.accountIdCode + "A"}
                                label={
                                  <div style={{ width: "100%" }}>
                                    {child2.accountIdCode +
                                      " | " +
                                      child2.accountTitle}
                                    <span style={{ float: "right" }}>
                                      {parseFloat(
                                        child2.currentBalance
                                      ).toLocaleString()}
                                    </span>
                                  </div>
                                }
                              />
                            ))}
                            {child1.inverseParent.map(
                              (child2) => (
                                <TreeItem
                                  nodeId={child2.headingIdCode + "H"}
                                  label={
                                    <div style={{ width: "100%" }}>
                                      {child2.headingIdCode + " | " + child2.headingTitle}
                                      <span style={{ float: "right" }}>
                                        {parseFloat(
                                          child2.balance
                                        ).toLocaleString()}
                                      </span>
                                    </div>
                                  }
                                >
  
                                  {child2.account.map((child3) => (
                                    <TreeItem
                                      nodeId={child3.accountIdCode + "A"}
                                      label={
                                        <div style={{ width: "100%" }}>
                                          {child3.accountIdCode +
                                            " | " +
                                            child3.accountTitle}
                                          <span style={{ float: "right" }}>
                                            {parseFloat(
                                              child3.currentBalance
                                            ).toLocaleString()}
                                          </span>
                                        </div>
                                      }
                                    />
                                  ))}
                                  {child2.inverseParent.map(
                                    (child3) => (
                                      <TreeItem
                                        nodeId={child3.headingIdCode + "H"}
                                        label={
                                          <div style={{ width: "100%" }}>
                                            {child3.headingIdCode +
                                              " | " +
                                              child3.headingTitle}
                                            <span style={{ float: "right" }}>
                                              {parseFloat(
                                                child3.balance
                                              ).toLocaleString()}
                                            </span>
                                          </div>
                                        }
                                      >
                                        {child3.account.map((child4) => (
                                          <TreeItem
                                            nodeId={child4.accountIdCode + "A"}
                                            label={
                                              <div style={{ width: "100%" }}>
                                                {child4.accountIdCode +
                                                  " | " +
                                                  child4.accountTitle}
                                                <span style={{ float: "right" }}>
                                                  {parseFloat(
                                                    child4.currentBalance
                                                  ).toLocaleString()}
                                                </span>
                                              </div>
                                            }
                                          />
                                        ))}
                                        {child3.inverseParent.map(child4 => (
                                          <TreeItem
                                            nodeId={child4.headingIdCode + "H"}
                                            label={
                                              <div style={{ width: "100%" }}>
                                                {child4.headingIdCode +
                                                  " | " +
                                                  child4.headingTitle}
                                                <span style={{ float: "right" }}>
                                                  {parseFloat(
                                                    child4.balance
                                                  ).toLocaleString()}
                                                </span>
                                              </div>
                                            }
                                          >
                                            {child4.account.map(child5 => (
                                              <TreeItem
                                                nodeId={child5.accountIdCode + "A"}
                                                label={
                                                  <div style={{ width: "100%" }}>
                                                    {child5.accountIdCode +
                                                      " | " +
                                                      child5.accountTitle}
                                                    <span style={{ float: "right" }}>
                                                      {parseFloat(
                                                        child5.currentBalance
                                                      ).toLocaleString()}
                                                    </span>
                                                  </div>
                                                }
                                              />
                                            ))}
  
                                            {child4.inverseParent.map(child5 => (
                                              <TreeItem
                                                nodeId={child5.headingIdCode + "H"}
                                                label={
                                                  <div style={{ width: "100%" }}>
                                                    {child5.headingIdCode +
                                                      " | " +
                                                      child5.headingTitle}
                                                    <span style={{ float: "right" }}>
                                                      {parseFloat(
                                                        child5.balance
                                                      ).toLocaleString()}
                                                    </span>
                                                  </div>
                                                }
                                              >
                                                {child5.account.map(child6 => (
                                                  <TreeItem
                                                    nodeId={child6.accountIdCode + "A"}
                                                    label={
                                                      <div style={{ width: "100%" }}>
                                                        {child6.accountIdCode +
                                                          " | " +
                                                          child6.accountTitle}
                                                        <span style={{ float: "right" }}>
                                                          {parseFloat(
                                                            child6.currentBalance
                                                          ).toLocaleString()}
                                                        </span>
                                                      </div>
                                                    }
                                                  />
                                                ))}
                                                {child5.inverseParent.map(child6 => (
                                                  <TreeItem
                                                    nodeId={child6.headingIdCode + "H"}
                                                    label={
                                                      <div style={{ width: "100%" }}>
                                                        {child6.headingIdCode +
                                                          " | " +
                                                          child6.headingTitle}
                                                        <span style={{ float: "right" }}>
                                                          {parseFloat(
                                                            child6.balance
                                                          ).toLocaleString()}
                                                        </span>
                                                      </div>
                                                    }
                                                  />
                                                ))}
                                              </TreeItem>
                                            ))}
                                          </TreeItem>
                                        ))}
                                      </TreeItem>
                                    )
                                  )}
                                </TreeItem>
                              )
                            )}
                          </TreeItem>
                        ))}
                      </TreeItem>
                    ))}
                  </TreeItem>
                ))}
              </TreeView>
            </div>
  
          </div>
        </div>
      </>
    )
  }
  
  export default ChartofAccount;