import React, { Component } from "react";
import gravity from "../../../../assets/GravityLogo.png";
class ComPaySoldPrint extends Component {
    render() {

        return (
            <div style={{ backgroundColor: "white", height: "100%" }}>
                <div className="row m-0 p-0">
                    <div className="watermarked">
                        <img
                            src={gravity}
                            style={{ height: "600px", width: "600px" }}
                            alt="Company logo"
                        />
                    </div>
                    <div className="col-8 text-left mt-4">
                        <h2 style={{ color: "#1b7a7f" }}>Gravity Associates Pvt Limited</h2>
                        <hr />
                        <h5 style={{ color: "grey", marginTop: "5px" }}>
                            <i>
                                204 R/B Road, East Canal Road, Faisalabad.
                            </i>
                        </h5>
                        <div
                            style={{
                                borderTop: "2px dotted black",
                                borderBottom: "2px dotted black",
                                height: "7px",
                            }}
                        ></div>
                    </div>
                    <div className="col-4 text-center">
                        {/* IMAGE COMES HERE */}

                        <img
                            src={gravity}
                            style={{ height: "175px", width: "175px", borderRadius: "100px" }}
                            className="pt-2"
                            alt="Company logo"
                        />
                    </div>
                </div>
                <div
                    className="row m-3 p-0"
                // style={{ border: "5px solid darkgrey", height: "auto" }}
                >
                    <div className="col-12 text-center mt-2">
                        <h4 style={{ color: "red" }}>
                            <u>Deal Bought Commission Receipt</u>
                        </h4>





                        <div className="mt-5">
                            <div class=" col-12">
                                <table class="table">
                                    <thead>
                                    <tr>
                                        <th scope="col">Sr</th>

                                        <th scope="col">Deal</th>

                                        <th scope="col">Unit</th>
                                        <th scope="col">Size</th>

                                        <th scope="col">Rate</th>
                                        <th scope="col">Total Value</th>

                                        <th scope="col">Comission Exp</th>


                                        <th scope="col">Paid</th>
                                        <th scope="col">Payable</th>
                                        <th scope="col">Status</th>

                                        <th scope="col">Reference
                                        </th>







                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.props.printData !== undefined ?
                                            this.props.printData?.map((x, id) => (
                                            <>  <tr key={id} >
                                                <td>{id + 1}</td>
                                                <td>{x.DealName}</td>

                                                <td>{x.UnitName}</td>
                                                <td>{x.RatePerMarlaOrsqf == "MARLA" ? x.NetMarla + "M" : x.NetSqft + "Sqft"}</td>
                                                <td>{x.RatePerMarla.toLocaleString()}</td>
                                                <td>{x.Price !== undefined && x.Price !== null ? x.Price.toLocaleString() : null}</td>

                                                <td>
                                                    {x.Contract[0].CommissionAmountPayable !== null && x.Contract[0].CommissionAmountPayable !== undefined ? x.Contract[0].CommissionAmountPayable.toLocaleString() : null}
                                                </td>
                                                <td> {x.Payments !== null && x.Payments !== undefined ? x.Payments.toLocaleString() : null}
                                                </td>
                                                <td> {x.Contract[0].CommissionAmountPayable !== null && x.Contract[0].CommissionAmountPayable !== undefined ? x.Contract[0].CommissionAmountPayable.toLocaleString() : null}
                                                </td>
                                                <td>  {x.Contract[0].ContractStatus !== null && x.Contract[0].ContractStatus !== undefined ? x.Contract[0].ContractStatus.toLocaleString() : null}
                                                </td>
                                                <td>   {x.Contract[0].Reference !== null && x.Contract[0].Reference !== undefined ? x.Contract[0].Reference.toLocaleString() : null}

                                                </td>
                                            </tr>

                                                <> {x.Contract.length > 1 ?
                                                    x.Contract.map((y, idx) => (
                                                        <>
                                                            {/* <tr>
                                                                <th scope="col">Comission Exp</th>


                                                                <th scope="col">Paid</th>
                                                                <th scope="col">Payable</th>
                                                                <th scope="col">Status</th>

                                                                <th scope="col">Reference
                                                                </th>
                                                            </tr> */}

                                                            {idx > 0 ? <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>

                                                                <td>
                                                                    {y.CommissionAmountPayable !== null && y.CommissionAmountPayable !== undefined ? y.CommissionAmountPayable.toLocaleString() : null}
                                                                </td>
                                                                <td> {x.Payments !== null && x.Payments !== undefined ? x.Payments.toLocaleString() : null}
                                                                </td>
                                                                <td> {y.CommissionAmountPayable !== null && y.CommissionAmountPayable !== undefined ? y.CommissionAmountPayable.toLocaleString() : null}
                                                                </td>
                                                                <td>  {y.ContractStatus !== null && y.ContractStatus !== undefined ? y.ContractStatus.toLocaleString() : null}
                                                                </td>
                                                                <td>   {y.Reference !== null && y.Reference !== undefined ? y.Reference.toLocaleString() : null}

                                                                </td>
                                                            </tr> : null}
                                                        </>
                                                    ))



                                                    : null}</>
                                                {/* <tr >
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>

                                                    <td colSpan="2" className="text-center"> Grand Total</td>

                                                    <td>{dealpaydata !== undefined && dealpaydata.TotalPrice !== undefined ? dealpaydata.TotalPrice.toLocaleString() : 0}</td>
                                                    <td>{dealpaydata !== undefined && dealpaydata.CommissionExpense !== undefined ? dealpaydata.CommissionExpense.toLocaleString() : 0}</td>
                                                    <td>{dealpaydata !== undefined && dealpaydata.Paid !== undefined && dealpaydata.Paid !== null ? dealpaydata.Paid.toLocaleString() : 0}</td>
                                                    <td>{dealpaydata !== undefined && dealpaydata.Payable !== undefined && dealpaydata.Payable !== null ? dealpaydata.Payable.toLocaleString() : 0}</td>
                                                    <td></td>
                                                    <td></td>


                                                </tr> */}
                                                </>






                                        )):null}
                                        <tr >
                                            <td></td>
                                            <td></td>
                                            <td></td>

                                            <td colSpan="2" className="text-center"> Grand Total</td>

                                            <td>{this.props.TotalValue !== undefined ? this.props.TotalValue.toLocaleString() : 0}</td>
                                            <td>{this.props.TotalCommission !== undefined ? this.props.TotalCommission.toLocaleString() : 0}</td>
                                            <td>{this.props.TotalPaid !== undefined ? this.props.TotalPaid.toLocaleString() : 0}</td>
                                            <td>{this.props.TotalPayable !== undefined ? this.props.TotalPayable.toLocaleString() : 0}</td>
                                            <td></td>
                                            <td></td>









                                        </tr>
                                </tbody>

                                    {/* <tbody> */}

                                        {/* {this.props.printData !== undefined ?
                                            this.props.printData.map((val, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{val.DealName.toLocaleString()}</td>
                                                    <td>{val.DealAmount.toLocaleString()}</td>
                                                 
                                                    <td>{val.AmountReceived.toLocaleString()}</td>
                                                    <td>{val.AmountUsed.toLocaleString()}</td>


                                                </tr>))
                                            : null} */}

                                    {/* </tbody> */}
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default ComPaySoldPrint;