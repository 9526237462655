import React, { Component } from "react";
import gravity from "../../../../assets/GravityLogo.png";
import PrintHeader from "../../../Prints/PrintHeader/PrintHeader";

export class AllUnitPrint extends Component {
  render() {
    return (
      <div style={{ backgroundColor: "white", height: "100%" }}>
        <div className="row m-0 p-0">
          <PrintHeader/>
        </div>
        <div className="row m-3 p-0">
          <div className="col-12 text-center mt-2">
            <h4 style={{ color: "red" }}>
              <u>Units </u>
            </h4>

            <div className="mt-3">
              <div class=" col-12">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      <th>Project</th>
                      <th>Section </th>
                      <th>Unit</th>
                      <th>Area</th>
                      <th>Dimension</th>
                      <th>S.Rate</th>
                      <th>Total Value</th>
                    </tr>
                  </thead>

                  <tbody>
                    {this.props.printData.data !== undefined
                      ? this.props.printData.data.map((val, index) => (
                          <tr key={index}>
                            <td> {index + 1}</td>
                            {val.Project !== null &&
                            val.Project !== undefined ? (
                              <td> {val.Project.ProjectName}</td>
                            ) : (
                              <td></td>
                            )}
                            {val.Section !== null &&
                            val.Section !== undefined ? (
                              <td>{val.Section.SectionName}</td>
                            ) : (
                              <td></td>
                            )}
                            <td>{val.UnitName}</td>

                            {/* <td>{val.NetMarla}</td> */}
                            <td>{val.Marla + "M" + val.Sarsai + "S"}</td>
                            {/* <td>{val.DimensionLength + "x" + val.DimensionWidth}</td>
                                                    <td>{parseFloat(val.CostPerMarla).toLocaleString()}</td>
                                                    <td>{parseFloat(val.TotalCost).toLocaleString()}</td> */}

                            <td>
                              {val.DimensionLength !== null
                                ? val.DimensionLength + "x" + val.DimensionWidth
                                : "0x0"}
                            </td>
                            <td>
                              {parseFloat(val.RatePerMarla).toLocaleString()}
                            </td>
                            <td> {parseFloat(val.Price).toLocaleString()}</td>
                          </tr>
                        ))
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
