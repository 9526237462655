const InstallmentReceipt = { data: [], loading: true };
export const GetInstallmentReceiptRed = (state = InstallmentReceipt, action) => {
    switch (action.type) {

        case "get_allinstallmentreceipt":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}
const InstallmentPayment = { data: [], loading: true };
export const GetInstallmentPaymentRed = (state = InstallmentPayment, action) => {
    switch (action.type) {

        case "get_allinstallmentpayment":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}