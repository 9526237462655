const sectioncat = { data: [], loading: true };
const sectiontype = { data: [], loading: true };

const sectionsubcat = { data: [], loading: true };

export const GetsectioncatReducer = (state = sectioncat, action) => {
    switch (action.type) {

        case "get_allsectioncat":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}
export const GetsectiontypeReducer = (state = sectiontype, action) => {
    switch (action.type) {

        case "get_allsectiontype":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}
export const GetsectionsubcatReducer = (state = sectionsubcat, action) => {
    switch (action.type) {

        case "get_allsectionsubcat":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}

