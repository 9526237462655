import React from "react";
import "../App.css";
import { Link } from 'react-router-dom';

import background from "../assets/background.jpg";
const divStyle = {
    width: '100vw',
    height: '100vh',
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover'
};

const Verify = (props) => {

    return (
        <>
            <div style={divStyle}  >
                <div className="loginbox">
                    <div className="row mx-auto my-auto pb-4">

                        <div className="col-lg-12 col-md-12  col-sm-12 ">
                            <div className="row select_user">
                                <div className="col-12 heading bluetext logintext  ">
                                    Verification
                                </div>
                            </div>
                            <div className="row mt-3 ">
                                <div className="col-12 mx-auto text-center verfiytext">
                                    Enter the Verification code  sent to your email
                                 </div>
                            </div>
                            <div className="space row mx-auto mt-5">
                                <input
                                    className="input1 col p-0"
                                    id={"input-one"}
                                    // onKeyUp={handleKeyUp}
                                    maxLength="1"
                                    type="number"
                                ></input>
                                <input
                                    className="input1 col p-0"
                                    id={"input-two"}
                                    // onKeyUp={handleKeyUp}
                                    // ref={one}
                                    maxLength="1"
                                    type="number"
                                ></input>
                                <input
                                    className="input1 col p-0"
                                    // ref={two}
                                    id={"input-three"}
                                    // onKeyUp={handleKeyUp}
                                    maxLength="1"
                                    type="number"
                                ></input>
                                <input
                                    className="input1 col p-0"
                                    id={"input-four"}
                                    // ref={three}
                                    // onKeyUp={handleKeyUp}
                                    maxLength="1"
                                    type="number"
                                ></input>
                            </div>

                            <div className="row mt-3">
                                <div className="col-12 mt-3 ">
                                    <Link to="/reset">
                                        <button
                                            className="logIn "
                                        // onClick={submit}
                                        >
                                            SEND
                    </button>
                                    </Link>
                                </div>
                                <div className="col-12 mt-3 text-center">
                                    <span className="password">
                                        Resend The OTP?
                         </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Verify;