const allgetexternalcommission = { data: [], loading: true };

export const GetExternalCommissionRed = (
  state = allgetexternalcommission,
  action
) => {
  switch (action.type) {
    case "get_allgetexternalcommission":
      return {
        data: action.payload,
        loading: action.loading,
      };
    default:
      return state;
  }
};
