const chartofacc = { data: [], loading: true };
export const GetChartOfAcc = (state = chartofacc, action) => {
    switch (action.type) {

        case "get_chartofacc":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}