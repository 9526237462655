const taxreceivable = { data: [], loading: true };
const taxreceivedvoucher = { data: [], loading: true };

export const GetAllTaxReceivableReducer = (state = taxreceivable, action) => {
    switch (action.type) {

        case "get_taxreceivable":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}

export const GetAllTaxReceiveableVoucherReducer = (state = taxreceivedvoucher, action) => {
    switch (action.type) {

        case "get_allreceiptVoucher":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}