import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { FiEdit2 } from "react-icons/fi";
import { TiPrinter } from "react-icons/ti";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { getallProject } from "../../../actions/getprojects";
import { getallsellers, GetAllSellers, GetSellerData } from "../../../actions/inventoryactions/addseller";
import LandSellerPrint from "../LandPrints/LandSellerPrint";
import { CSVLink } from "react-csv";


export const SellerTable = (props) => {
    const [sellerdata, setsellerdata] = useState({});
    const resultallsellers = useSelector(state => state.getAllSellers);
    const sellerdatastate = useSelector(state => state.getSellerdatastate);
    // print function
    const componentRef = useRef();

    const printPaymentRec = useReactToPrint({
        content: () => componentRef.current,
    });
    //Table Data fiter

    var suggestionlist = [];

    const onTextChange = (e) => {
        const value = e.target.value;
        if (value === "") {

            dispatch(getallsellers());
        } else {
            let suggestions = [];
            if (value.length > 0) {
                const regex = new RegExp(`${value}`, "i");

                suggestions = resultallsellers.data
                    .sort()
                    .filter((val) =>
                        (regex.test(val.SellerName)));

            }
            suggestionlist = suggestions;
            dispatch(GetAllSellers(suggestionlist));
        }
    };
    let dispatch = useDispatch();

    useEffect(() => {
        dispatch(getallsellers());
        dispatch(getallProject());
    }, [dispatch])


    function showmodal(data) {
        document.getElementById("mymodal").style.display = "block";
        setsellerdata(data);
    }
    function closemodal() {
        document.getElementById("mymodal").style.display = "none";

    }
    const [CsvData, setCsvData] = useState([]);
    useEffect(() => {
      let arr = [];
      resultallsellers.data?.forEach((val, index) => {
        arr.push({
          Sr: index + 1,
          Name: val.SellerName,
          Email: val.SellerEmail,
          "CNIC/Reg": val.SellerCnic,
          "Contact No.": val.SellerCellNumber1,
          City: val.SellerCity,
        });
      });
      setCsvData(arr);
    }, [resultallsellers.data]);
    return (
        <>
            <div hidden>
                <LandSellerPrint
                    ref={componentRef}

                    printData={resultallsellers}

                /></div>
            <div className="row pt-3 ">
                <div className="col-md-1 pr-0 pl-0">


                    <label className="input_label m-0">Search:</label>
                </div>
                <div className="col-md-3 pr-0 pl-0">


                    <input type="text " className="input_styling " onChange={onTextChange} placeholder="Seller Name"></input>
                </div>
                {/* <div className="col-md-3 text-center  pr-0 pl-0">
                    <label className="input_label m-0">From:</label>
                    <input type="date" className="input_date ml-3" id="from date"></input>
                </div>
                <div className="col-md-3 text-center pr-0 pl-0">
                    <label className="input_label m-0">To:</label>
                    <input type="date" className="input_date ml-3" id="todate"></input>
                </div>
                <div className="col-md-1 pr-0 pl-0">
                    <button className="btn_Go">Go</button>
                </div> */}
                <div className="col-md-1 pr-0 pl-0">
                    {resultallsellers.loading ? <Loader type="ThreeDots"
                        color="green"
                        height={40}
                        width={40} /> : null}

                </div>
            </div>

            <div className="row ">
                <div className=" col-12  table_height pr-0 pl-0">

                    <div className="table-responsive mt-4">

                        <table className="table table-borderless table-hover m-0">
                            <thead>

                                <tr >

                                    <th>Sr</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>CNIC/Reg</th>

                                    <th>Contact No.</th>
                                    <th>City</th>



                                    <th className="text-center">
                                        <button
                                            className="save_btn"
                                            onClick={() => {
                                                props.showcreditortable();
                                            }}
                                        >
                                            New+
                                        </button>

                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {resultallsellers.data.map((val, index) => (
                                    <tr key={index} onClick={() => { showmodal(val) }}>
                                        <td>{index + 1}</td>
                                        <td>{val.SellerName}</td>
                                        <td>{val.SellerEmail}</td>
                                        <td>{val.SellerCnic}</td>

                                        <td>{val.SellerCellNumber1}</td>
                                        <td>{val.SellerCity}</td>
                                        <td className="text-center">
                                            <FiEdit2 size="1em" />
                                        </td>

                                    </tr>
                                ))}


                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-6  text-right pr-0 pl-0">
                    <button className="print_btn float-left" onClick={() => { printPaymentRec(); }}><TiPrinter size="1.3em" />Print</button>
                    <button
                        style={{ color: "black !important" }}
                        className="print_btn ml-2 float-left"
                        >
                        <CSVLink
                            style={{ color: "black" }}
                            filename="Seller_Csv"
                            data={CsvData}
                        >
                            Download CSV
                        </CSVLink>
                    </button>

                </div>
            </div>

            <div id="mymodal" class="modal">

                <div class="modal-content-cat">
                    <div className="row">
                        <div className="col-12  page_heading">
                            <h4 className="  text-center pt-2">Seller Details</h4>
                            <span
                                class="close pr-2  "
                                onClick={closemodal
                                }
                            >
                                &times;
                            </span>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 mt-2">

                            <ul>
                                <li>
                                    <b>Name:</b>{" "}
                                    {sellerdata.SellerName}
                                </li>
                                <li>
                                    <b>Email:</b>{" "}
                                    {sellerdata.SellerEmail}
                                </li>
                                <li>
                                    <b>CNIC/Reg:</b>{" "}
                                    {sellerdata.SellerCnic}
                                </li>

                                <li>
                                    <b>Contact No.1:</b>{" "}
                                    {sellerdata.SellerCellNumber1}

                                </li>
                                <li>
                                    <b>Contact No.2:</b>{" "}
                                    {sellerdata.SellerCellNumber2}

                                </li>
                            </ul>

                        </div>
                        <div className="col-6 mt-2">
                            <ul>


                                <li>
                                    <b>Country:</b>{" "}
                                    {sellerdata.SellerCountry}
                                </li>
                                <li>
                                    <b>State:</b>{" "}
                                    {sellerdata.State}
                                </li>
                                <li>
                                    <b>City:</b>{" "}
                                    {sellerdata.SellerCity}
                                </li>
                                <li>
                                    <b>Phone No:</b>{" "}
                                    {sellerdata.SellerPhoneNumber}

                                </li>
                                <li>
                                    <b>Address:</b>{" "}
                                    {sellerdata.SellerAddress}

                                </li>
                                <li>
                                    <b>Type:</b>{" "}
                                    {sellerdata.SellerType}

                                </li>


                            </ul>
                        </div>
                    </div>

                    <div className="col-12 text-right">
                        <button
                            className=" save_btn   "
                            onClick={() => {
                                dispatch(GetSellerData(sellerdata));
                                closemodal();
                                props.showcreditortable();


                            }}
                        >
                            <FiEdit2 size="0.8em" /> Edit
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};