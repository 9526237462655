import React, { useState } from "react";
import ExternalCommissionTable from "./ExternalCommissionTable";
import ExternalCommissionForm from "./ExternalCommissionForm";
import { useDispatch } from "react-redux";
import { getAllExternalCommission } from "../../../actions/vouchersactions/getExternalCommission";

function ExternalCommission() {
  const [tab, settab] = useState("INCOME");
  const dispatch = useDispatch();

  const showExternalCommissionIncomeForm = () => {
    setIncomeTypePage(
      <ExternalCommissionForm
        CommissionType="INCOME"
        cancelExternalCommissionForm={cancelExternalCommissionIncomeForm}
      />
    );
  };

  const cancelExternalCommissionIncomeForm = () => {
    setIncomeTypePage(
      <ExternalCommissionTable
        CommissionType="INCOME"
        showExternalCommissionForm={showExternalCommissionIncomeForm}
        fDate=""
        tDate=""
      />
    );
  };

  const [incomeTypePage, setIncomeTypePage] = useState(
    <ExternalCommissionTable
      CommissionType="INCOME"
      showExternalCommissionForm={showExternalCommissionIncomeForm}
      fDate=""
      tDate=""
    />
  );

  const showExternalCommissionExpenseForm = () => {
    setExpenseTypePage(
      <ExternalCommissionForm
        CommissionType="EXPENSE"
        cancelExternalCommissionForm={cancelExternalCommissionExpenseForm}
      />
    );
  };

  const cancelExternalCommissionExpenseForm = () => {
    setExpenseTypePage(
      <ExternalCommissionTable
        CommissionType="EXPENSE"
        showExternalCommissionForm={showExternalCommissionExpenseForm}
        fDate=""
        tDate=""
      />
    );
  };

  const [expenseTypePage, setExpenseTypePage] = useState(
    <ExternalCommissionTable
      CommissionType="EXPENSE"
      showExternalCommissionForm={showExternalCommissionExpenseForm}
      fDate=""
      tDate=""
    />
  );

  const incomePage = () => {
    dispatch(getAllExternalCommission(true));
    return incomeTypePage;
  };

  const expensePage = () => {
    dispatch(getAllExternalCommission(true));
    return expenseTypePage;
  };

  return (
    <>
      <div className="row">
        <div className="dash_background col-12 p-0">
          <div className="row ">
            <div className="col-md-12 text-center  p-0">
              <button
                className={
                  tab === "INCOME" ? "adjust_btns_selected  " : "adjust_btns  "
                }
                onClick={() => {
                  settab("INCOME");
                }}
              >
                <span className="comp_btns_text">INCOME</span>
              </button>

              <button
                className={
                  tab === "EXPENSE"
                    ? "adjust_btns_selected ml-1 "
                    : "adjust_btns ml-1 "
                }
                onClick={() => {
                  settab("EXPENSE");
                }}
              >
                <span className="comp_btns_text">EXPENSE</span>
              </button>
            </div>
            <div className="col-md-12">      
                {tab === "INCOME" ? incomePage() : expensePage()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ExternalCommission;
