import React from "react";
import { useSelector } from "react-redux";
function IndividualPrintHeader() {
  const companyInfo = useSelector((state) => state.getCompany);
  return (
    <>
      <div className="watermarked">
        <img
          src={companyInfo?.data?.Logo}
          style={{ height: "600px", width: "600px" }}
          alt="Company logo"
        />
      </div>
      <div className="col-4 text-center">
        <img
          src={companyInfo?.data?.Logo}
          style={{ height: "175px", width: "175px", borderRadius: "100px" }}
          className="pt-2"
          alt="Company logo"
        />
      </div>
      <div className="col-3"></div>
      <div className="col-5 text-center mt-4">
        <h2 style={{ color: "#1b7a7f" }}>{companyInfo?.data?.CompanyName}</h2>
        <hr />
        <h6 style={{ color: "grey", marginTop: "5px" }}>
          <i>{companyInfo?.data?.Address}.</i>
        </h6>
      </div>
    </>
  );
}

export default IndividualPrintHeader;
