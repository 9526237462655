import moment from "moment";
import React, { Component } from "react";
import gravity from "../../../../assets/GravityLogo.png";
import PrintHeader from "../../../Prints/PrintHeader/PrintHeader";
class GeneralJournalPrint extends Component {
    render() {

        return (
          <div style={{ backgroundColor: "white", height: "100%" }}>
            <div className="row m-0 p-0">
              <PrintHeader />
            </div>
            <div
              className="row m-3 p-0"
              // style={{ border: "5px solid darkgrey", height: "90%" }}
            >
              <div className="col-12 text-center mt-2">
                <h4 style={{ color: "red" }}>
                  <u>General Journal </u>
                </h4>

                <div className="mt-5">
                  <div class=" col-12">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>Sr</th>

                          <th>Date</th>
                          <th>ID</th>
                          <th>Narration</th>
                          <th>Debit</th>
                          <th>Credit</th>
                        </tr>
                      </thead>

                      <tbody>
                        {this.props.printData !== undefined
                          ? this.props.printData.map((val, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                  {moment(val.TransDate.split("T")[0]).format(
                                    "DD-MMM-YYYY"
                                  )}
                                </td>

                                <td>{val.ID}</td>
                                <td>{val.Particular}</td>

                                {val.Debit !== "" ? (
                                  <td>
                                    {parseFloat(val.Debit).toLocaleString()}
                                  </td>
                                ) : (
                                  <td></td>
                                )}
                                {val.Credit !== "" ? (
                                  <td>
                                    {parseFloat(val.Credit).toLocaleString()}
                                  </td>
                                ) : (
                                  <td></td>
                                )}
                              </tr>
                            ))
                          : null}
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>
                            {" "}
                            <label className="input_label m-0">Total:</label>
                          </td>
                          <td>
                            <input
                              type="text"
                              className="input_total_styling "
                              placeholder="Debit"
                              disabled
                              defaultValue={parseFloat(
                                this.props.statedebit
                              ).toLocaleString()}
                              key={this.props.statedebit}
                            ></input>
                          </td>
                          <td>
                            <input
                              type="text"
                              className="input_total_styling "
                              placeholder="Credit"
                              disabled
                              defaultValue={parseFloat(
                                this.props.statecredit
                              ).toLocaleString()}
                              key={this.props.statecredit}
                            ></input>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
    }
}

export default GeneralJournalPrint;