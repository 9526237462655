const allemployees = { data: [], loading: true };
const alldesignation = { data: [], loading: true };
const employeedata = {
    EmployeeId: 0,
    EmpName: null,
    EmpFatherName: null,
    EmpAddress: null,
    EmpCnic: null,
    EmpPhoneNo: null,
    EmpDesignationId: null,
    EmpEmail: null,
    EmpReligon: null,
    EmpGender: null,
    EmpDOB: null,
    EmpMaritalStatus: null,
    EmpJoiningDate: null,
    EmpCity: null,
    EmpCountry: null,
    EmpCellNo1: null,
    EmpCellNo2: null
};
export const GetAllEmployeesReducer = (state = allemployees, action) => {
    switch (action.type) {

        case "get_allemployees":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}


export const GetEmployeedataReducer = (state = employeedata, action) => {
    switch (action.type) {

        case "get_employeesdata":
            return {
                employeedata: action.payload,

            }
        default:
            return {
                employeedata: employeedata,

            };
    }
}
export const GetEmpDesignation = (state = alldesignation, action) => {
    switch (action.type) {
        case "get_empdesignation":

            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;

    }

}