import React, { Component } from "react";
import gravity from "../../../../assets/GravityLogo.png";
import moment from "moment";
import PrintHeader from "../../../Prints/PrintHeader/PrintHeader";

export class NewPurchasePrint extends Component {
  render() {
    return (
      <div style={{ backgroundColor: "white", height: "100%" }}>
        <div className="row m-0 p-0">
          <PrintHeader />
        </div>
        <div className="row m-3 p-0">
          <div className="col-12 text-center mt-2">
            <h4 style={{ color: "red" }}>
              <u>Purchase Units </u>
            </h4>

            <div className="mt-3">
              <div class=" col-12">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Sr</th>

                      <th>Date</th>
                      <th>Project</th>
                      <th>Section </th>
                      <th>Seller</th>
                      <th>Unit</th>
                      <th>Area</th>
                      <th>Dimension</th>
                      <th>Purchase Rate</th>
                      <th>Total Cost</th>

                      <th>Purchase Cost</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td className=" cur_sor ">1</td>
                      <td className=" cur_sor ">
                        {/* {this.props.printData?.data !== undefined && this.props.printData?.data?.Date.split("T")[0]} */}
                        {moment(
                          this.props.printData?.Date.split("T")[0]
                        ).format("DD-MMM-YYYY")}
                      </td>

                      {this.props.printData?.Project !== null &&
                      this.props.printData?.Project !== undefined ? (
                        <td className=" cur_sor ">
                          {this.props.printData?.Project}
                        </td>
                      ) : (
                        <td></td>
                      )}
                      {this.props.printData?.Unit !== null &&
                      this.props.printData?.Unit !== undefined ? (
                        <td className=" cur_sor ">
                          {this.props.printData?.Unit?.Section}
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className=" cur_sor ">
                        {this.props.printData?.Seller
                          ? this.props.printData?.Seller
                          : this.props.printData?.Customer}
                      </td>

                      <td className=" cur_sor ">
                        {this.props.printData?.Unit !== null &&
                        this.props.printData?.Unit !== undefined
                          ? this.props.printData?.Unit?.UnitName
                          : null}
                      </td>

                      <td className=" cur_sor ">
                        {this.props.printData?.Unit !== null &&
                        this.props.printData?.Unit !== undefined
                          ? this.props.printData?.Unit?.Marla +
                            "M" +
                            this.props.printData?.Unit?.Sarsai +
                            "S"
                          : null}
                      </td>
                      <td className=" cur_sor ">
                        {this.props.printData?.Unit !== null &&
                        this.props.printData?.Unit !== undefined
                          ? this.props.printData?.Unit?.DimensionLength +
                            "x" +
                            this.props.printData?.Unit?.DimensionWidth
                          : null}
                      </td>
                      <td className=" cur_sor ">
                        {this.props.printData?.Unit !== null &&
                        this.props.printData?.Unit !== undefined
                          ? parseFloat(
                              this.props.printData?.Unit?.CostPerMarla
                            ).toLocaleString()
                          : null}
                      </td>

                      <td className=" cur_sor ">
                        {this.props.printData?.Unit !== null &&
                        this.props.printData?.Unit !== undefined
                          ? parseFloat(
                              this.props.printData?.Unit.TotalCost
                            ).toLocaleString()
                          : null}
                      </td>
                      <td className=" cur_sor ">
                        {this.props.printData?.ProjectOwner === "OTHER"
                          ? this.props.printData?.PurchaseCost
                          : null}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      // <div className="row p-5 m-0">
      //     <div className="watermarked">
      //         <img
      //             src={gravity}
      //             style={{ height: "600px", width: "600px" }}
      //             alt="Company logo"
      //         />
      //     </div>
      //     <div className="col-12"><b>QUOTATION</b></div>

      //     <div className="col-6 mt-5" style={{ backgroundColor: "lightblue" }}><b>SHOP</b></div>
      //     <div className="col-6 mt-5 text-right" style={{ backgroundColor: "lightblue" }}><b>{this.props.printData !== undefined ? this.props.printData.UnitName : null}</b></div>

      //     <div className="col-6 mt-2">Floor:</div>
      //     <div className="col-6 mt-2 text-right">{ }</div>

      //     <div className="col-6 mt-2">Size {this.props.printData !== undefined ? this.props.printData.RatePerMarlaOrSqft : null}:</div>
      //     <div className="col-6 mt-2 text-right">{this.props.printData !== undefined ? this.props.printData.SizeNetSqft : null}</div>

      //     <div className="col-6 mt-2">Purchase Rate per {this.props.printData !== undefined ? this.props.printData.RatePerMarlaOrSqft : null}.:</div>
      //     <div className="col-6 mt-2 text-right">{this.props.printData !== undefined ? this.props.printData.PurchaseRate : null}</div>

      //     <div className="col-6 mt-2">Purchase Value:</div>
      //     <div className="col-6 mt-2 text-right">{this.props.printData !== undefined ? this.props.printData.PurchaseValue : null}</div>

      //     <div className="col-6 mt-4">Sale Rate per {this.props.printData !== undefined ? this.props.printData.RatePerMarlaOrSqft : null}.:</div>
      //     <div className="col-6 mt-4 text-right">{this.props.printData !== undefined ? this.props.printData.SaleRate : null}</div>

      //     <div className="col-6 mt-2">Shop Sale Value:</div>
      //     <div className="col-6 mt-2 text-right">{this.props.printData !== undefined ? this.props.printData.SaleValue : null}</div>

      //     <div className="col-12 mt-4"><b><u>Advance Payment</u></b></div>

      //     <div className="col-6 mt-2">1. Down Payment @ 30%:</div>
      //     <div className="col-6 mt-2 text-right">{this.props.printData !== undefined ? this.props.printData.AdvancePayment : null}</div>

      //     <div className="col-6 mt-2">2. Instalments Paid:</div>
      //     <div className="col-6 mt-2 text-right">{this.props.printData !== undefined ? this.props.printData.InstallmentsPaid : null}</div>

      //     <div className="col-6 mt-2">3. Profit Amount {this.props.printData !== undefined ? this.props.printData.RatePerMarlaOrSqft : null}.:</div>
      //     <div className="col-6 mt-2 text-right">{this.props.printData !== undefined ? this.props.printData.ProfitValue : null}</div>

      //     <div className="col-6 mt-2">4. Transfer fee:</div>
      //     <div className="col-6 mt-2 text-right">{this.props.printData !== undefined ? this.props.printData.TransferFee : null}</div>

      //     <div className="col-9"></div>
      //     <div className="col-3 text-right"><b>
      //         {this.props.printData !== undefined
      //             ?
      //             this.props.printData.TransferFee + this.props.printData.ProfitValue + this.props.printData.InstallmentsPaid + this.props.printData.AdvancePayment
      //             :
      //             null}
      //     </b></div>

      //     <div className="col-6 mt-4"><b><u>Remaining Amount</u></b></div>
      //     <div className="col-6 mt-4 text-right"><b>{this.props.printData !== undefined
      //         ?
      //         this.props.printData.PurchaseValue - this.props.printData.AdvancePayment - this.props.printData.InstallmentsPaid
      //         :
      //         null}</b></div>

      //     <div className="col-6">(Purchase Value - Down Payment - Installments Paid)</div>
      //     <div className="col-6">

      //     </div>

      //     <div className="col-6 mt-4"><b><u>Installment per month ({this.props.printData !== undefined ? this.props.printData.NoOfInstallmentsRemaining : null} Installments)</u></b></div>
      //     <div className="col-6 mt-4 text-right"><b>{this.props.printData !== undefined ? this.props.printData.InstallmentPerMonth : null}</b></div>
      // </div>
    );
  }
}
