import moment from "moment";
import React, { Component } from "react";
import gravity from "../../../../assets/GravityLogo.png";
import PrintHeader from "../../../Prints/PrintHeader/PrintHeader";

export class RecoveryIndividualPrint extends Component {
  render() {
    var i = 0;
    return (
      <div
        style={{ backgroundColor: "white", height: "100%", padding: "10px" }}
      >
        <div className="row m-0 p-0">
          <PrintHeader />
        </div>
        <div className="row m-3 p-0" style={{ height: "80%" }}>
          <div className="col-12 text-center mt-2">
            <h4 style={{ color: "red" }}>
              <u>Installments Detail</u>
            </h4>
            <hr style={{ backgroundColor: "black" }} />

            <div className="row mt-4">
              <div className="col-6 text-left">
                <b>Contract Date:</b>{" "}
                {moment(this.props?.multiple?.EntryDate?.split("T")[0]).format(
                  "DD-MMM-YYYY"
                )}
              </div>
              <div className="col-6 text-right">
                <b>Last Payment Date: </b>
                {moment(
                  this.props?.multiple?.UnitVoucher[0]?.EntryDate?.split("T")[0]
                ).format("DD-MMM-YYYY")}
              </div>

              <div className="col-6 text-left">
                <b>Unit: </b>
                {this.props?.multiple?.Unit?.UnitName}
              </div>
              <div className="col-6 text-right">
                <b>Buyer: </b>
                {this.props?.multiple?.CustomerCnicNavigation?.CustomerName}
              </div>

              <div className="col-6 text-left">
                <b>Total Installments: </b>
                {this.props?.multiple?.NoOfInstallments}
              </div>
              <div className="col-6 text-right">
                <b>Pending Installments: </b>
                {
                  this.props?.multiple?.ContractInstallments?.filter(
                    (item) => item?.InstallmentStatus === "PENDING"
                  )?.length
                }
              </div>

              <div className="col-6 text-left">
                <b>Total Price: </b>
                {this.props?.multiple?.TotalPrice?.toLocaleString()}
              </div>

              <div className="col-6 text-right">
                <b>Customer Receivable: </b>
                {this.props?.multiple?.CustomerReceivable?.toLocaleString()}
              </div>
            </div>

            <div className="mt-3">
              <div class="col-12">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      <th scope="col">Due Date</th>
                      <th scope="col">Installment Amount</th>
                      <th scope="col">Received</th>
                      <th scope="col">Pending Amount</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.multiple !== undefined
                      ? this.props.multiple.ContractInstallments.map((y, i) => (
                          <tr>
                            <td scope="row">{++i}</td>
                            <td>
                              {moment(y?.DueDate?.split("T")[0]).format(
                                "DD-MMM-YYYY"
                              )}
                            </td>
                            <td>{y?.DueAmount?.toLocaleString()}</td>
                            <td>{y?.ReceivedAmount?.toLocaleString()}</td>
                            <td>
                              {(
                                y?.DueAmount - y?.ReceivedAmount
                              )?.toLocaleString()}
                            </td>
                            <td>{y?.InstallmentStatus}</td>
                          </tr>
                        ))
                      : null}
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <b>Pending Sum:</b>
                      </td>
                      <td className="ml-2">
                        {this.props?.sumpending?.toLocaleString()}
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
