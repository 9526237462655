import React, { Component } from "react";
import gravity from "../../../../assets/GravityLogo.png";
import PrintHeader from "../../../Prints/PrintHeader/PrintHeader";
class ProjectsReportPrint extends Component {
    render() {

        return (
          <div style={{ backgroundColor: "white", height: "100%" }}>
            <div className="row m-0 p-0">
              <PrintHeader />
            </div>
            <div
              className="row m-3 p-0"
              // style={{ border: "5px solid darkgrey", height: "auto" }}
            >
              <div className="col-12 text-center mt-2">
                <h4 style={{ color: "red" }}>
                  <u>Project Units Report</u>
                </h4>

                <div className="mt-5">
                  <div class=" col-12">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">Sr</th>

                          <th scope="col">Unit</th>
                          <th scope="col">Size</th>

                          <th scope="col">Rate</th>
                          <th scope="col">Total Value</th>

                          <th scope="col" className="text-center" colSpan="2">
                            Received
                          </th>

                          {/* <th scope="col">Installments</th> */}
                          <th scope="col">Receivable</th>
                          <th scope="col">Status</th>

                          <th scope="col">Reference Sale Person</th>
                        </tr>
                        <tr>
                          <th scope="col"></th>

                          <th scope="col"></th>
                          <th scope="col"></th>

                          <th scope="col"></th>
                          <th scope="col"></th>

                          <th scope="col">Down Payment</th>

                          <th scope="col">Installments</th>
                          <th scope="col"></th>
                          <th scope="col"></th>

                          <th scope="col"></th>
                        </tr>
                      </thead>

                      <tbody>
                        {this.props.printData !== undefined
                          ? this.props.printData?.map((x, id) => (
                              <tr key={id}>
                                <td>{id + 1}</td>
                                <td>{x.UnitName}</td>
                                <td>
                                  {x.RatePerMarlaOrsqf == "MARLA"
                                    ? x.NetMarla + "M"
                                    : x.NetSqft + "Sqft"}
                                </td>
                                <td>{x.RatePerMarla.toLocaleString()}</td>
                                <td>{x.Price}</td>

                                <td>
                                  {x.Contract !== null &&
                                  x.Contract.AdvanceReceived !== undefined &&
                                  x.Contract.AdvanceReceived !== null
                                    ? x.Contract.AdvanceReceived.toLocaleString()
                                    : null}
                                </td>
                                <td>
                                  {x.Contract !== null &&
                                  x.Contract.InstallmentsReceived !==
                                    undefined &&
                                  x.Contract.InstallmentsReceived !== null
                                    ? x.Contract.InstallmentsReceived.toLocaleString()
                                    : null}
                                </td>
                                <td>
                                  {x.Contract !== null &&
                                  x.Contract.CustomerReceivable !== undefined &&
                                  x.Contract.CustomerReceivable !== null
                                    ? x.Contract.CustomerReceivable.toLocaleString()
                                    : null}
                                </td>
                                <td>
                                  {x.Contract !== null &&
                                  x.Contract.ContractStatus !== undefined &&
                                  x.Contract.ContractStatus !== null
                                    ? x.Contract.ContractStatus.toLocaleString()
                                    : null}
                                </td>

                                <td>
                                  {x.Contract !== null &&
                                  x.Contract !== undefined
                                    ? x.Contract.Reference
                                    : null}
                                </td>
                              </tr>
                            ))
                          : null}
                        <tr>
                          <td></td>
                          <td></td>
                          <td colSpan="2" className="text-center">
                            {" "}
                            Grand Total
                          </td>

                          <td>
                            {this.props.TotalValue !== undefined
                              ? this.props.TotalValue.toLocaleString()
                              : 0}
                          </td>
                          <td>
                            {this.props.TotalDownPayment !== undefined
                              ? this.props.TotalDownPayment.toLocaleString()
                              : 0}
                          </td>
                          <td>
                            {this.props.TotalInstallments !== undefined
                              ? this.props.TotalInstallments.toLocaleString()
                              : 0}
                          </td>
                          <td>
                            {this.props.TotalReceived !== undefined
                              ? this.props.TotalReceived.toLocaleString()
                              : 0}
                          </td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
    }
}

export default ProjectsReportPrint;