const Sell = { data: [], loading: true };

export const GetAssetsSellReducer = (state = Sell, action) => {
    switch (action.type) {

        case "get_allSell":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}