import React from "react";

const ScreenInfoModal = ({ setshowModal, screenName }) => {
  return (
    <div id="mymodal" className="modal" style={{ display: "block" }}>
      <div className="modal-content-cat">
        <div className="row">
          <div className="col-12  page_heading">
            <h4 className="  text-center pt-2">{screenName}</h4>
            <span
              class="close pr-2  "
              onClick={() => {
                setshowModal(false);
              }}
            >
              &times;
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScreenInfoModal;
