const secassets = { data: [], loading: true };
const depricationmethod = { data: [], loading: true };
const tableassets = { data: [], loading: true };


const assetssubcat = { data: [], loading: true };

export const GetAssetsCatReducer = (state = secassets, action) => {
    switch (action.type) {

        case "get_allassetscat":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}
export const GetdepricationMethodReducer = (state = depricationmethod, action) => {
    switch (action.type) {

        case "get_alldepricationmethod":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}
export const GetAssetsSubcatReducer = (state = assetssubcat, action) => {
    switch (action.type) {

        case "get_allassetssubcat":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}
export const GetTableAssetsReducer = (state = tableassets, action) => {
    switch (action.type) {

        case "get_alltableassets":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}