
const allaffiliatesdata = { data: [], loading: true };

const affiliatesdata = {

    AffiliateCnic: null,
    AffiliateName: null,
    AffiliateAddress: null,
    AffiliatePhoneNumber: null,
    AffiliateContactNo1: null,
    AffiliateCity: null,
    AffiliateCountry: null,
    AffiliateEmail: null,
    AffiliateType: null
};
// table
export const GetAllAffiliatesRed = (state = allaffiliatesdata, action) => {
    switch (action.type) {

        case "get_affiliatesdata":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}

export const GetAffliateEidtReducer = (state = affiliatesdata, action) => {
    switch (action.type) {

        case "get_Affdata":
            return {
                affiliatesdata: action.payload,

            }
        default:
            return {
                affiliatesdata: affiliatesdata,

            };
    }
}

