import moment from "moment";
import React, { Component } from "react";
import gravity from "../../../../assets/GravityLogo.png";
import PrintHeader from "../../../Prints/PrintHeader/PrintHeader";
export class TaxRecceivedPrint extends Component {
    render() {

        return (
          <div style={{ backgroundColor: "white", height: "100%" }}>
            <div className="row m-0 p-0">
              <PrintHeader />
            </div>
            <div
              className="row m-3 p-0"
              // style={{ border: "5px solid darkgrey", height: "auto" }}
            >
              <div className="col-12 text-center mt-2">
                <h4 style={{ color: "red" }}>
                  <u>Tax Received</u>
                </h4>

                <div className="mt-5">
                  <div class=" col-12">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>Sr</th>
                          <th>EntryDate</th>
                          <th>Voucher No</th>
                          <th>CPR Date</th>

                          <th>CPR Number</th>

                          <th>Rec Type</th>
                          <th>Narration</th>
                          <th>Amount</th>
                        </tr>
                      </thead>

                      <tbody>
                        {this.props.printData.data !== undefined
                          ? this.props.printData.data.map((val, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>

                                <td>
                                  {moment(val.EntryDate.split("T")[0]).format(
                                    "DD-MMM-YYYY"
                                  )}
                                </td>
                                <td>{val.TaxReceivableVoucherId}</td>
                                <td>
                                  {val.Cprdate !== null
                                    ? moment(val.Cprdate.split("T")[0]).format(
                                        "DD-MMM-YYYY"
                                      )
                                    : null}
                                </td>
                                <td>{val.Cprnumber}</td>

                                <td>{val.PaymentType}</td>
                                <td>{val.Narration}</td>
                                <td>{val.Amount.toLocaleString()}</td>
                              </tr>
                            ))
                          : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
    }
}