const allseller = { data: [], loading: true };
const sellerdata = {
    SellerId: 0,
    SellerName: null,
    SellerAddress: null,
    SellerCellNumber1: null,
    SellerCellNumber2: null,
    SellerPhoneNumber: null,
    SellerCountry: null,
    SellerCity: null,
    SellerEmail: null,
    SellerCnic: null,
    SellerType: null,


};
export const GetAllSellerReducer = (state = allseller, action) => {
    switch (action.type) {

        case "get_allseller":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}
export const GetSellerdataReducer = (state = sellerdata, action) => {
    switch (action.type) {

        case "get_sellerdata":
            return {
                sellerdata: action.payload,

            }
        default:
            return {
                sellerdata: sellerdata,

            };
    }
}