const allrole = { data: [], loading: true };
export const GetAllRole = (state = allrole, action) => {
    switch (action.type) {

        case "get_allrole":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}
const allroletable = { data: [], loading: true };
export const GetAllRoleTable = (state = allroletable, action) => {
    switch (action.type) {

        case "get_allroletable":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}
