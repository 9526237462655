
const allLoanTableData = { data: [], loading: true };


export const GetAllLoanTableRed = (state = allLoanTableData, action) => {
    switch (action.type) {

        case "get_allloantabledata":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}
