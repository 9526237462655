import { useState } from "react";
import { AddInvestor } from "./AddInvestor";
import { InvestorTable } from "./InvestorTable";

const MainAddInvestor = () => {


    const showlendertable = () => {
        stateshowlender(<AddInvestor cancellendertable={cancellendertable} />);
    }
    const cancellendertable = () => {
        stateshowlender(<InvestorTable showlendertable={showlendertable} cancellendertable={cancellendertable} />);
    }
    const [showlender, stateshowlender] = useState(<InvestorTable showlendertable={showlendertable} cancellendertable={cancellendertable} />);

    return (

        <>
            <div className="row ">
                <div className="dash_background col-12 p-0">
                    <div className="row ">

                        <div className="col-md-12">
                            {showlender}
                        </div>


                    </div>


                </div>
            </div>
        </>

    )
}
export default MainAddInvestor