const allgetrecoveryreceipt = { data: [], loading: true };

const allRRTableData = { data: [], loading: true };

export const GetRecoveryReceiptRed = (
  state = allgetrecoveryreceipt,
  action
) => {
  switch (action.type) {
    case "get_allgetrecoveryreceipt":
      return {
        data: action.payload,
        loading: action.loading,
      };
    default:
      return state;
  }
};
export const GetAllRecoveryReceiptTableRed = (
  state = allRRTableData,
  action
) => {
  switch (action.type) {
    case "get_allrrtabledata":
      return {
        data: action.payload,
        loading: action.loading,
      };
    default:
      return state;
  }
};
