import { useState } from "react";
import { DealBought } from "./DealBought";
import { DealBoughtTable } from "./DealBoughtTable";

const MainDealBought = () => {

    const showprojecttable = () => {
        stateshowproject(<DealBought cancelprojecttable={cancelprojecttable} />);
    }
    const cancelprojecttable = () => {
        stateshowproject(<DealBoughtTable showprojecttable={showprojecttable} cancelprojecttable={cancelprojecttable} />);
    }
    const [showproject, stateshowproject] = useState(<DealBoughtTable showprojecttable={showprojecttable} cancelprojecttable={cancelprojecttable} />);

    return (

        <>
            <div className="row  ">
                <div className="dash_background col-12 p-0">
                    <div className="row ">

                        <div className="col-md-12">
                            {showproject}

                        </div>


                    </div>


                </div>
            </div>
        </>

    )
}
export default MainDealBought;