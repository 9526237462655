import { useEffect } from "react";
import { useState } from "react";
import { JournalEntryForm } from "./JournalEntryForm";
import { JournalEntryTable } from "./JournalEntryTable";

const JournalEntryMain = () => {
    const [voucherData, setvoucherData] = useState(null);
  
  
    const showjournaltable = () => {
      stateshowjournal(<JournalEntryForm canceljournaltable={canceljournaltable} />);
    };
    const canceljournaltable = () => {
      stateshowjournal(
        <JournalEntryTable
          setvoucherData={setvoucherData} vouchData={voucherData}
          showjournaltable={showjournaltable}
          canceljournaltable={canceljournaltable}
        />
      );
    };
    const [showjournal, stateshowjournal] = useState(
      <JournalEntryTable
        setvoucherData={setvoucherData} vouchData={voucherData}
        showjournaltable={showjournaltable}
        canceljournaltable={canceljournaltable}
      />
    );
    useEffect(() => {
      if (voucherData === null) {
        stateshowjournal(
          <JournalEntryTable
            setvoucherData={setvoucherData} vouchData={voucherData}
            showjournaltable={showjournaltable}
            canceljournaltable={canceljournaltable}
          />
        );
      } else {
        stateshowjournal(
          <JournalEntryForm
            setvoucherData={setvoucherData} vouchData={voucherData}
            showjournaltable={showjournaltable}
            canceljournaltable={canceljournaltable}
          />
        );
      }
    }, [voucherData]);
    return (
      <>
        <div className="row">
          <div className="dash_background col-12 p-0">
            <div className="row ">
              <div className="col-md-12">{showjournal}</div>
            </div>
          </div>
        </div>
      </>
    );
  };
  
  export default JournalEntryMain;