import moment from "moment";
import React, { Component } from "react";
import gravity from "../../../assets/GravityLogo.png";
class LandPaymentsReportPrint extends Component {
  render() {
    return (
      <div style={{ backgroundColor: "white", height: "100%" }}>
        <div className="row m-0 p-0">
          <div className="watermarked">
            <img
              src={gravity}
              style={{ height: "600px", width: "600px" }}
              alt="Company logo"
            />
          </div>
          <div className="col-8 text-left mt-4">
            <h2 style={{ color: "#1b7a7f" }}>Gravity Associates Pvt Limited</h2>
            <hr />
            <h5 style={{ color: "grey", marginTop: "5px" }}>
              <i>204 R/B Road, East Canal Road, Faisalabad.</i>
            </h5>
            <div
              style={{
                borderTop: "2px dotted black",
                borderBottom: "2px dotted black",
                height: "7px",
              }}
            ></div>
          </div>
          <div className="col-4 text-center">
            {/* IMAGE COMES HERE */}
            <img
              src={gravity}
              style={{ height: "175px", width: "175px", borderRadius: "100px" }}
              className="pt-2"
              alt="Company logo"
            />
          </div>
        </div>
        <div
          className="row m-3 p-0"
          // style={{ border: "5px solid darkgrey", height: "auto" }}
        >
          <div className="col-12 text-center mt-2">
            <h4 style={{ color: "red" }}>
              <u>Land Payments Report</u>
            </h4>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h5>
                <b>Payment Sum: </b>
                {this.props.statecashinhand !== undefined
                  ? this.props.statecashinhand.toLocaleString()
                  : null}
              </h5>
              <h5>
                <b>Receipt Sum: </b>
                {this.props.stateopeningbal !== undefined
                  ? this.props.stateopeningbal.toLocaleString()
                  : null}
              </h5>
              <h5>
                <b>Balance: </b>
                {this.props.statecashinhand !== undefined &&this.props.stateopeningbal !== undefined
                  ? (this.props.statecashinhand-this.props.stateopeningbal)?.toLocaleString()
                  : null}
              </h5>
            </div>
            <div className="">
              <div class="col-12">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Sr</th>

                      <th>Date</th>
                      <th>Narration</th>
                      <th>Payment</th>
                      <th>Receipt</th>
                    </tr>
                  </thead>

                  <tbody>
                    {this.props.printData !== undefined
                      ? this.props.printData?.map((val, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              {moment(val.EntryDate.split("T")[0]).format(
                                "DD-MMM-YYYY"
                              )}
                            </td>
                            <td>{val.Narration}</td>

                            {val.TransactionType === "DEBIT" ? (
                              <td>{parseFloat(val.Amount).toLocaleString()}</td>
                            ) : (
                              <td></td>
                            )}
                            {val.TransactionType === "CREDIT" ? (
                              <td>{parseFloat(val.Amount).toLocaleString()}</td>
                            ) : (
                              <td></td>
                            )}
                          </tr>
                        ))
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LandPaymentsReportPrint;
