const profitloss = { data: [], loading: true };
export const GetProfitLoss = (state = profitloss, action) => {
    switch (action.type) {

        case "get_allgetprofitloss":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}