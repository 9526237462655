const allsalariespopulate = { data: [], loading: true };

export const GetAllSalariesPopulate = (state = allsalariespopulate, action) => {
    switch (action.type) {

        case "get_allsalariespopulate":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}
const allsalariestable = { data: [], loading: true };

export const GetAllSalariesTable = (state = allsalariestable, action) => {
    switch (action.type) {

        case "get_allsalariestable":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}