const allgetdealsoldreceipt = { data: [], loading: true };

const allDSRTableData = { data: [], loading: true };

export const GetDealSoldReceiptRed = (state = allgetdealsoldreceipt, action) => {
    switch (action.type) {

        case "get_allgetdealsoldreceipt":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}
export const GetAllDealSoldReceiptTableRed = (state = allDSRTableData, action) => {
    switch (action.type) {
        
        case "get_allDSRtabledata":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}