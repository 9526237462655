import React from "react";
import { useContext } from "react";
import { monthlyAlert } from "../ContextApi/MonthlyAlert";
import icon from "../../assets/Invoice-Reminder.png";
import moment from "moment";

export default function AltertModal() {
  const { showAlertModal, changeState } = useContext(monthlyAlert);

  return (
    <>
      {showAlertModal && (
        <div style={{ display: "block" }} className="modal">
          <div className="modal-content-Alert">
            <div className="row ">
              <div className="col-12  page_headingAlert">
                {/* <h4 className="  text-center pt-2"></h4> */}
                <span
                  class="close pr-2 closeicon"
                  id="hideMeAfter5Seconds"
                  onClick={() => changeState(false)}
                >
                  &times;
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  style={{
                    marginTop: "10px",
                    margin: " 0 auto",
                    width: "100%",
                  }}
                  src={icon}
                  alt="icon"
                />
              </div>
              <marquee
                width="100%"
                direction="left"
                style={{ backgroundColor: "#21b6a8", color: "white" }}
              >
                Your Invoice is {Number(moment().format("DD")) - 10} days past
                due. Please clear the invoice at your earliest!
              </marquee>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
