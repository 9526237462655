const allaccreceivable = { data: [], loading: true };
export const GetAllAccReceivable = (state = allaccreceivable, action) => {
    switch (action.type) {

        case "get_allaccreceivable":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}