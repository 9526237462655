import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { CSVLink } from "react-csv";
import { TiPrinter } from "react-icons/ti";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { getallaccpayable } from "../../../actions/chartofacc/accpayableaction";
import AccPayablePrint from "../ReportPrints/AccountPayablePrint";


const AccountPayable= () => {
    const resultsgetaccpay = useSelector((state) => state.getAccPayable);
    const [filterdata, setfilterdata] = useState([]);
    const [csvData, setcsvData] = useState([]);

    // print function
    const componentRef = useRef();

    const printPaymentRec = useReactToPrint({
        content: () => componentRef.current,
    });
    let Total = 0;
    const dispatch = useDispatch();
    //Table Data fiter

    // var suggestionlist = [];

    // const onTextChange = (e) => {
    //     const value = e.target.value;
    //     if (value === "") {

    //         dispatch(getallaccpayable());
    //     } else {
    //         let suggestions = [];
    //         if (value.length > 0) {
    //             const regex = new RegExp(`${value}`, "i");

    //             suggestions = resultsgetaccpay.data
    //                 .sort()
    //                 .filter((val) =>
    //                     (regex.test(val.AccountTitle) || regex.test(val.HeadingNavigation !== null ? val.HeadingNavigation.HeadingTitle : null) || regex.test(val.HeadingNavigation !== null ? val.HeadingNavigation.Parent.HeadingTitle : null)));

    //         }
    //         suggestionlist = suggestions;
    //         dispatch(GetAllAccPayable(suggestionlist));
    //     }
    // };
    const onTextChange = (e) => {

        if (document.getElementById("accountfilter").value === "" && document.getElementById("subcategoryfilter").value === "" && document.getElementById("categoryfilter").value === "" && document.getElementById("subcategoryfilter").value === "") {
            setfilterdata(resultsgetaccpay.data);

        } else {
            let suggestions = [];
            {
                const regex = new RegExp(document.getElementById("accountfilter").value, "i");
                const regex1 = new RegExp(document.getElementById("categoryfilter").value, "i");
                const regex2 = new RegExp(document.getElementById("subcategoryfilter").value, "i");





                suggestions = resultsgetaccpay.data
                    .sort()
                    .filter((val) =>
                        (document.getElementById("accountfilter").value === "" || regex.test(val.AccountTitle)) &&
                        (document.getElementById("categoryfilter").value === "" || regex1.test(val.HeadingNavigation.Parent.HeadingTitle)) &&
                        (document.getElementById("subcategoryfilter").value === "" || regex2.test(val.HeadingNavigation.HeadingTitle))
                        &&
                        (document.getElementById("maincategoryfilter").value === "" || regex2.test(val.AccountCategory))



                    );

            }
            setfilterdata(suggestions);
        }
    };
    useEffect(() => {

        dispatch(getallaccpayable());
    }, [dispatch]);

    if (filterdata !== undefined) {
        filterdata.map((val, index) => {
            Total += parseFloat(val.CurrentBalance);
        })
    }

    useEffect(() => {
        setfilterdata(resultsgetaccpay.data);

    }, [resultsgetaccpay]
    )
    useEffect(() => {
        let arr = [];
        filterdata.forEach((item) => {
            arr.push({
                Code: item.AccountIdCode,
                Account: item.AccountTitle,
                MainCategory: item.AccountCategory,
                Category: item.HeadingNavigation !== null && item.HeadingNavigation !== undefined ? item.HeadingNavigation.Parent.HeadingTitle : null,
                SubCategory: item.HeadingNavigation !== null && item.HeadingNavigation !== undefined ? item.HeadingNavigation.HeadingTitle : null,
                OpeningBalance: item.CurrentBalance,
                Type: item.CurrentBalance < 0 ? "DR" : "CR"
            })
        });
        arr.push({
            Code: "",
            Account: "",
            MainCategory: "",
            Category: "",
            SubCategory: "Total",
            OpeningBalance: Total,
            Type: ""
        });
        setcsvData(arr);
    }, [filterdata]);

    return (
        <>
            <div hidden>
                <AccPayablePrint
                    ref={componentRef}
                    Total={Total}
                    printData={filterdata !== undefined ? filterdata : null}

                /></div>
            <div className="row  app_font">
                <div className="dash_background col-12 pt-2">
                    <div className="row ">
                        <div className="col-md-1 pr-0 pl-0">


                            <label className="input_label m-0">Search:</label>
                        </div>
                        <div className="col-md-2 pr-0 pl-0">


                            <input type="text " className="input_styling " id="accountfilter" onChange={onTextChange} placeholder="Account "></input>
                        </div>
                        <div className="col-md-2 pr-0 pl-0">


                            <input type="text " className="input_styling " id="maincategoryfilter" onChange={onTextChange} placeholder="Main Category"></input>
                        </div>
                        <div className="col-md-2 pr-0 pl-0">


                            <input type="text " className="input_styling " id="categoryfilter" onChange={onTextChange} placeholder="Category"></input>
                        </div>
                        <div className="col-md-2 pr-0 pl-0">


                            <input type="text " className="input_styling " id="subcategoryfilter" onChange={onTextChange} placeholder="Sub Category "></input>
                        </div>


                        {/* <div className="col-md-3 pr-0 pl-0">


                            <input type="text " className="input_styling " onChange={onTextChange} placeholder="Account/Category/Sub Cat"></input>
                        </div> */}
                        <div className="col-md-1 pr-0 pl-0">


                            {resultsgetaccpay !== undefined ? resultsgetaccpay.loading ? <Loader type="ThreeDots"
                                color="green"
                                height={40}
                                width={40} /> : null : null}
                        </div>

                    </div>



                    <div className=" col-12  table_height pr-0 pl-0">
                        <div className="table-responsive mt-2">
                            <table className="table table-borderless m-0">
                                <thead>
                                    <tr>
                                        <th>ID Code </th>
                                        <th>Account</th>
                                        <th>Main Category</th>

                                        <th>Category</th>
                                        <th>Sub Category</th>
                                        <th>OpeningBalance</th>

                                        <th>Amount</th>
                                        <th>Type</th>







                                    </tr>
                                </thead>
                                <tbody>
                                    {filterdata !== undefined ? filterdata.map((val, index) => (
                                        <tr key={index}>
                                            <td>{val.AccountIdCode}</td>
                                            <td>{val.AccountTitle}</td>
                                            <td>{val.AccountCategory}</td>

                                            <td>{val.HeadingNavigation !== null && val.HeadingNavigation !== undefined ? val.HeadingNavigation.Parent.HeadingTitle : null}</td>
                                            <td>{val.HeadingNavigation !== null && val.HeadingNavigation !== undefined ? val.HeadingNavigation.HeadingTitle : null}</td>
                                            <td>{val.OpeningBalance}</td>
                                            <td>{val.CurrentBalance < 0 ? parseFloat(val.CurrentBalance).toLocaleString().split("-")[1] : parseFloat(val.CurrentBalance).toLocaleString()}</td>
                                            <td>{val.CurrentBalance < 0 ? "DR" : "CR"}</td>
                                        </tr>
                                    )) : null}
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>

                                        <td> <label className="input_label m-0">Total:</label></td>
                                        <td>  <input type="text " className="input_total_styling" value={parseFloat(Total).toLocaleString()} id="total" disabled></input></td>
                                        <td></td>

                                    </tr>


                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-6  text-right pr-0 pl-0">
                        <button className="print_btn float-left" disabled={filterdata?.length > 0 ? false : true} onClick={() => { printPaymentRec(); }}><TiPrinter size="1.3em" />Print</button>
                        {filterdata?.length > 0 && <button style={{ color: "black !important" }} className="print_btn ml-2 float-left" >
                            <CSVLink style={{ color: "black" }} filename="Account Payables Csv" data={csvData}>Download CSV</CSVLink>

                        </button>}

                    </div>


                </div>
            </div>
        </>

    )
}

export default AccountPayable;