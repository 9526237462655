const alllenders = { data: [], loading: true };
const lenderdata = {
    LenderId: 0,
    LenderName: null,
    LenderAddress: null,
    ContactNumber1: null,
    ContactNumber2: null,
    PhoneNumber: null,
    Country: null,
    City: null,
    Email: null,
    LenderCnic: null,
    Occupation: null,
    // AccountId: 0

};
export const GetAllLendersReducer = (state = alllenders, action) => {
    switch (action.type) {

        case "get_alllenders":
            return {

                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}
export const GetLendersdataReducer = (state = lenderdata, action) => {
    switch (action.type) {

        case "get_lendersdata":
            return {
                lenderdata: action.payload,

            }
        default:
            return {
                lenderdata: lenderdata,

            };
    }


}
