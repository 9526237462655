const allclients = { data: [], loading: true };
const clientdata = {
  ManagerClientId: 0,
  ManagerClientName: null,
  Address: null,
  ContactNumber1: null,
  ManagerClientCnic: null,
  PhoneNumber: null,
  Occupation: null,
  City: null,
  ManagerClientRating: null,
  Email: null,
  Country: null,
  ContactNumber2: null,
  ManagerId: null,
};
export const GetAllClientsReducer = (state = allclients, action) => {
  switch (action.type) {
    case "get_allclients":
      return {
        data: action.payload,
        loading: action.loading,
      };
    default:
      return state;
  }
};
export const GetClientsdataReducer = (state = clientdata, action) => {
  switch (action.type) {
    case "get_clientsdata":
      return {
        clientdata: action.payload,
      };
    default:
      return {
        clientdata: clientdata,
      };
  }
};
// get customers reducers
const allcustomers = { data: [], loading: true };

export const GetAllCustomersRed = (state = allcustomers, action) => {
  switch (action.type) {
    case "get_allcustomerdata":
      return {
        data: action.payload,
        loading: action.loading,
      };
    default:
      return state;
  }
};
const customerdata = {
  CustomerCnic2: null,
  CustomerName: null,
  CustomerAddress: null,
  ContactNumber1: null,
  PhoneNumber: null,
  Occupation: null,
  City: null,
  ClientRating: null,
  Email: null,
  Country: null,
  ContactNumber2: null,
  GuardianName: null,
};
export const GetCustomersdataReducer = (state = customerdata, action) => {
  switch (action.type) {
    case "get_customerdata":
      return {
        customerdata: action.payload,
      };
    default:
      return {
        customerdata: customerdata,
      };
  }
};
