import { useEffect } from "react";
import { useState } from "react";
import ReactDOM from "react-dom";
import { GrFormAdd } from "react-icons/gr";
import Deletepic from "../../../../assets/delete.png";
export const JornalEntryTab = (props) => {
  let suggestionlist = [];
  const [renderComp, setrenderComp] = useState(false);
  // const [rowData, setrowData] = useState([
  //   {
  //     JournalVoucherSavedId: 0,
  //     JournalVoucherSavedDetailId: props.vouchId,
  //     AccountId: "",
  //     AccTitle: "",
  //     Description: "",
  //     CreditAmount: "",
  //     DebitAmount: "",
  //   }
  // ])

  useEffect(() => {
    let arr = [];
    if (props.vouchData === undefined) {
      props.setdisableBtn(true);
      props.setrowstate([
        {
          JournalVoucherSavedId: props.vouchId,
          JournalVoucherSavedDetailId: 0,
          AccountId: "",
          AccTitle: "",
          Description: "",
          CreditAmount: "",
          DebitAmount: "",
        },
      ]);

      // this.setState({ rows: arr })
    } else {
      props.setdisableBtn(false);
      let deb = 0;
      let cred = 0;
      // setrowstate(props.vouchData?.JournalVoucherSavedDetails)
      props.vouchData?.forEach((item) => {
        if (item.TransactionType === "DEBIT") {
          deb = +Number(item.Amount);
        } else {
          cred = +Number(item.Amount);
        }
        arr.push({
          AccountId: item.AccountId,
          JournalVoucherSavedId: item.JournalVoucherSavedId,
          JournalVoucherSavedDetailId: item.JournalVoucherSavedDetailId,
          AccTitle: item.Account?.AccountTitle,
          Description: item.Description,
          CreditAmount: item.TransactionType === "CREDIT" ? item.Amount : "",
          DebitAmount: item.TransactionType === "DEBIT" ? item.Amount : "",
        });
      });
      props.setrowstate(arr);

      // setrowData(arr);
      document.getElementById("totalcreditamount1").value = parseInt(
        cred.toString()
      ).toLocaleString();
      document.getElementById("totaldebitamount1").value = parseInt(
        deb.toString()
      ).toLocaleString();
    }
  }, [props.vouchData]);
  const handleChange = (idx) => (e) => {
    props.setdisableBtn(true);
    const { name, value } = e.target;
    // console.log(name);
    const rows = props.rowstate;
    // console.log(rows);
    if (name === "Accountid") rows[idx].AccountId = value;
    if (name === "AccountTitle") {
      var inputValue = document.getElementById(`AccountTitle${idx}`).value;
      var options = document.getElementById(`mylist${idx}`).childNodes;

      for (var i = 0; i < options.length; i++) {
        if (options[i].value === inputValue) {
          rows[idx].AccTitle = options[i].attributes.value.value;
          rows[idx].AccountId = options[i].attributes.accountid.value;

          document.getElementById(`accountid${idx}`).value =
            options[i].attributes.accountid.value;
          break;
        }
      }

      // this.onTextChange(value, idx);
    }
    if (name === "Description") rows[idx].Description = value;

    // setrowData(rows);
    props.setrowstate(rows);
  };
  const handleAddRow = () => {
    props.setdisableBtn(true);
    const item = {
      JournalVoucherSavedId: props.vouchId,
      JournalVoucherSavedDetailId: 0,
      AccountId: "",
      AccTitle: "",
      Description: "",
      CreditAmount: "",
      DebitAmount: "",
    };
    props.setrowstate([...props.rowstate, item]);
  };

  const handleRemoveSpecificRow = (idx) => () => {
    props.setdisableBtn(true);
    const rows = props.rowstate;
    rows.splice(idx, 1);
    // setrowData(rows);
    props.setrowstate(rows);
    setrenderComp(!renderComp);
  };
  //sum amount function
  const handleChangecredit = (idx) => (e) => {
    props.setdisableBtn(true);
    // console.log("Credit amount");

    if (document.getElementById(`creditamount${idx}`).value !== "") {
      document.getElementById(`debitamount${idx}`).disabled = true;
    } else {
      document.getElementById(`debitamount${idx}`).disabled = false;
    }

    // if (document.getElementById("creditamount0").value !== "") {
    //   for (let i = 1; i < props.rowstate?.length; i++) {
    //     document
    //       .getElementById("creditamount" + i.toString())
    //       .setAttribute("readonly", true);
    //     document.getElementById("creditamount" + i.toString()).value = "";
    //   }
    // } else {
    //   for (let i = 1; i < props.rowstate?.length; i++) {
    //     document
    //       .getElementById("creditamount" + i.toString())
    //       .removeAttribute("readonly");
    //   }
    // }

    const { name, value } = e.target;
    const rows = props.rowstate;
    if (name === "CreditAmount1") {
      rows[idx].CreditAmount = parseInt(
        value.replace(/,/g, "")
      ).toLocaleString();
      document.getElementById(`creditamount${idx}`).value = parseInt(
        value.replace(/,/g, "")
      ).toLocaleString();
      if (document.getElementById(`creditamount${idx}`).value === "NaN") {
        document.getElementById(`creditamount${idx}`).value = 0;
      }
    }

    sumamountcredit();
    // setrowData(rows);
    props.setrowstate(rows);
  };
  const sumamountcredit = () => {
    var len = document.getElementById("tablebody").rows.length;
    var sum = 0;
    var i;
    for (i = 0; i < len; i++) {
      var id = "creditamount".concat(i.toString());
      var amount = document.getElementById(id).value.replace(/,/g, "");
      if (Number.isInteger(parseFloat(amount))) {
        sum = sum + parseFloat(amount);
      }
    }

    document.getElementById("totalcreditamount1").value = parseInt(
      sum.toString()
    ).toLocaleString();
  };

  const handleChangedebit = (idx) => (e) => {
    // console.log("debit amount");
    props.setdisableBtn(true);
    if (document.getElementById(`debitamount${idx}`).value !== "") {
      document.getElementById(`creditamount${idx}`).disabled = true;
    } else {
      document.getElementById(`creditamount${idx}`).disabled = false;
    }

    // if (document.getElementById("debitamount0").value !== "") {
    //   for (let i = 1; i < props.rowstate?.length; i++) {
    //     document
    //       .getElementById("debitamount" + i.toString())
    //       .setAttribute("readonly", true);
    //     document.getElementById("debitamount" + i.toString()).value = "";
    //   }
    // } else {
    //   for (let i = 1; i < props.rowstate?.length; i++) {
    //     document
    //       .getElementById("debitamount" + i.toString())
    //       .removeAttribute("readonly");
    //   }
    // }

    const { name, value } = e.target;
    // console.log(name);
    const rows = props.rowstate;

    if (name === "DebitAmount1") {
      rows[idx].DebitAmount = parseInt(
        value.replace(/,/g, "")
      ).toLocaleString();
      document.getElementById(`debitamount${idx}`).value = parseInt(
        value.replace(/,/g, "")
      ).toLocaleString();
      if (document.getElementById(`debitamount${idx}`).value === "NaN") {
        document.getElementById(`debitamount${idx}`).value = 0;
      }
    }
    sumamountdebit();
    // setrowData(rows);
    props.setrowstate(rows);
  };

  const sumamountdebit = () => {
    var len = document.getElementById("tablebody").rows.length;
    var sum = 0;
    var i;
    for (i = 0; i < len; i++) {
      var id = "debitamount".concat(i.toString());
      var amount = document.getElementById(id).value.replace(/,/g, "");
      if (Number.isInteger(parseFloat(amount))) {
        sum = sum + parseFloat(amount);
      }
    }

    document.getElementById("totaldebitamount1").value = parseInt(
      sum.toString()
    ).toLocaleString();
  };
  const onTextChange = (e, id) => {
    var accountnames = [];
    props.resultsallaccount.data.forEach((name) => {
      accountnames.push(name.AccountTitle.concat("-", name.AccountId));
    });
    const value = e;
    if (value === "Add New") {
      ReactDOM.render(null, document.getElementById(id.toString()));
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");
        suggestions = accountnames.sort().filter((v) => regex.test(v));
      }
      // console.log(suggestions);
      suggestionlist = suggestions;
      // this.suggestionlist.push("Add New");
      if (suggestionlist.length === 0) {
        ReactDOM.render(null, document.getElementById(id.toString()));
      } else {
        ReactDOM.render(
          <ul className="suggestboxaccount">
            {this.suggestionlist.map((item) => (
              <li
                className="listcss"
                onClick={() => suggestionselected(item, id)}
              >
                {item}
              </li>
            ))}
          </ul>,
          document.getElementById(id.toString())
        );
      }
    }
  };
  const suggestionselected = (val, id) => {
    var res = val.split("-");
    var idval = "AccountTitle".concat(id.toString());
    var acid = "accountid".concat(id.toString());

    if (res.length >= 3) {
      document.getElementById(idval).value = `${res[0]}-${res[1]}`;
    } else {
      document.getElementById(idval).value = res[0];
    }

    document.getElementById(acid).value = res.at(-1);
    suggestionlist = [];
    ReactDOM.render(null, document.getElementById(id.toString()));

    const rows = props.rowstate;
    if (res.length >= 0) {
      rows[id].AccTitle = `${res[0]}-${res[1]}`;
    }

    rows[id].AccountId = res.at(-1);

    // this.setState({
    //   rows: rows,
    // });

    props.setrowstate(rows);

    // setrowData(rows)
    // }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleAddRow();
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Tab") {
      handleAddRow();
    }
  };

  return (
    <div>
      <div className="row clearfix">
        <div className="col-md-12 p-0 column">
          <table className="table table-bordered table-hover" id="tab_logic">
            <thead>
              <tr>
                <th className="text-center"> # </th>
                <th className="text-center"> ID </th>
                <th className="text-center"> Account Title </th>
                <th className="text-center"> Description </th>
                <th className="text-center"> Debit Amount </th>
                <th className="text-center"> Credit Amount </th>
                <th className="text-center">
                  <span onClick={handleAddRow}>
                    {" "}
                    <GrFormAdd className="add_icon drow" />
                  </span>
                </th>
              </tr>
            </thead>
            <tbody id="tablebody">
              {props.rowstate?.map((item, idx) => (
                <tr id="addr0" key={idx}>
                  <td>{idx}</td>
                  <td>
                    <input
                      id={`accountid${idx}`}
                      type="text"
                      readOnly
                      name="Accountid"
                      defaultValue={item.AccountId}
                      onChange={handleChange(idx)}
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      id={`AccountTitle${idx}`}
                      name="AccountTitle"
                      defaultValue={item.AccTitle}
                      onChange={handleChange(idx)}
                      className="form-control"
                      list={`mylist${idx}`}
                    />
                    <datalist id={`mylist${idx}`}>
                      {props.resultsallaccount?.data?.map((x, idx) => (
                        <option accountid={x.AccountId} value={x.AccountTitle}>
                          {x.AccountIdCode}
                        </option>
                      ))}
                    </datalist>
                  </td>
                  <td>
                    <input
                      type="text"
                      name="Description"
                      id={`description${idx}`}
                      defaultValue={item.Description}
                      onChange={handleChange(idx)}
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="DebitAmount1"
                      id={`debitamount${idx}`}
                      defaultValue={item.DebitAmount}
                      onChange={handleChangedebit(idx)}
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="CreditAmount1"
                      id={`creditamount${idx}`}
                      defaultValue={item.CreditAmount}
                      onChange={handleChangecredit(idx)}
                      className="form-control"
                      // onKeyPress={this.handleKeyPress}
                      // onKeyDown={this.handleKeyDown}
                    />
                  </td>

                  <td className="text-center">
                    <h6>
                      <span onClick={handleRemoveSpecificRow(idx)}>
                        <img
                          style={{
                            cursor: "pointer",
                            width: "19px",
                            marginTop: "8px",
                          }}
                          src={Deletepic}
                          alt="delete"
                        ></img>
                      </span>
                    </h6>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
