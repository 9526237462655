import { useState } from "react";
import { LandSellerForm } from "./LandSellerForm";
import { LandSellerTable } from "./LandSellerTable";

const MainLandSeller = () => {

    const showcreditortable = () => {
        stateshowcreditor(<LandSellerForm cancelcreditortable={cancelcreditortable} />);
    }
    const cancelcreditortable = () => {
        stateshowcreditor(<LandSellerTable showcreditortable={showcreditortable} cancelcreditortable={cancelcreditortable} />);
    }
    const [showclient, stateshowcreditor] = useState(<LandSellerTable showcreditortable={showcreditortable} cancelcreditortable={cancelcreditortable} />);

    return (

        <>
            <div className="row ">
                <div className="dash_background col-12 p-0">
                    <div className="row ">

                        <div className="col-md-12">
                            {showclient}
                        </div>


                    </div>


                </div>
            </div>
        </>

    )
}
export default MainLandSeller;