import React from "react";
import { BiInfoCircle } from "react-icons/bi";
import SaveImage from "../../ModalImages/SaveImage";
import ListImage from "../../ModalImages/ListImage";
import SubmitImage from "../../ModalImages/SubmitImage";
import DownIcon from "../../ModalImages/DownIcon";

function UnitReceiptModal() {

  return (
    <div>
      <div class="d-flex justify-content-center align-items-center">
        <span class="modal-btn" data-toggle="modal" data-target="#myModal2">
          <BiInfoCircle />
        </span>
      </div>

      <div
        class="modal right fade my-modal"
        id="myModal2"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myModalLabel2"
      >
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content ">
            <div class="modal-header">
              <h4 class="modal-title" id="myModalLabel2">
                Unit Receipt Info!
              </h4>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div class="modal-body">
              <ul className="modal-ul">
                <li>
                  <h5>
                    <SaveImage />
                    Save
                  </h5>

                  <ul>
                    <li>
                      <ListImage />
                      Users can update the voucher until the voucher is not
                      submitted.
                    </li>
                  </ul>
                </li>
                <li>
                  <h5>
                    <SubmitImage />
                    Submit
                  </h5>
                  <ul className="custom-list">
                    <li>
                      <ListImage />
                      Contracts' receivable decrease and installment received
                      amount increase with voucher amount.
                    </li>
                    <li>
                      <ListImage />
                      Installment’s status changed.
                    </li>
                    <li>
                      <DownIcon />
                      Transactions are:
                      <ul>
                        <li>
                          <ListImage />
                          Credit account = Buyer account credit with voucher
                          amount.
                        </li>
                        <li>
                          <ListImage />
                          Debit account = Cash/Bank debit with voucher amount.
                        </li>
                        <li>
                          <ListImage />
                          If tax involve than:
                          <br />
                          Tax transaction: Debit with (gross amount/100 * tax
                          percentage)
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UnitReceiptModal;
