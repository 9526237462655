import { useState } from "react";
import { InstallResceduleform } from "./InstallResceduleForm";
import { InstallResceduleTable } from "./InstallResceduleTable";

const InstallmentResceduleMain = () => {

    const showcontracttable = () => {
        stateshowcontract(<InstallResceduleform cancelcontracttable={cancelcontracttable} />);
    }
    const cancelcontracttable = () => {
        stateshowcontract(<InstallResceduleTable showcontracttable={showcontracttable} cancelcontracttable={cancelcontracttable} />);
    }
    const [showcontract, stateshowcontract] = useState(<InstallResceduleTable showcontracttable={showcontracttable} cancelcontracttable={cancelcontracttable} />);

    return (

        <>
            <div className="row  ">
                <div className="dash_background col-12 p-0">
                    <div className="row ">

                        <div className="col-md-12">
                            {showcontract}

                        </div>


                    </div>


                </div>
            </div>
        </>

    )
}

export default InstallmentResceduleMain;