import React, { Component } from "react";
import gravity from "../../../../assets/GravityLogo.png";
import moment from "moment";
import PrintHeader from "../../../Prints/PrintHeader/PrintHeader";

class CommissionPaymentPrint extends Component {
    render() {
        return (
          <div style={{ backgroundColor: "white", height: "100%" }}>
            <div className="row m-0 p-0">
              <PrintHeader />
            </div>

            <div
              className="row m-3 p-0"
              // style={{ border: "5px solid darkgrey", height: "auto" }}
            >
              <div className="col-12 text-center mt-2">
                <h4 style={{ color: "red" }}>
                  <u>Commission Payment</u>
                </h4>
                {this.props?.Data !== null ? (
                  <div className="mt-5">
                    <div class=" col-12">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">Sr</th>
                            <th scope="col">Date</th>
                            <th scope="col">Affiliate</th>
                            <th scope="col">Unit</th>
                            <th scope="col">Total Value</th>
                            <th scope="col">Commission Amount</th>
                            <th scope="col">Paid</th>
                            <th scope="col">Remaining</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr>
                            <td>{1}</td>
                            <td>
                              {moment(this.props?.Data?.EntryDate).format(
                                "DD-MMM-YYYY"
                              )}
                            </td>
                            <td>{this.props?.Data?.Reference}</td>
                            <td>{this.props?.Data?.UnitName}</td>
                            <td>
                              {this.props?.Data?.ContractPrice?.toLocaleString()}
                            </td>
                            <td>
                              {this.props?.Data?.CommissionAmount?.toLocaleString()}
                            </td>
                            <td>
                              {this.props?.Data?.Payments?.toLocaleString()}
                            </td>
                            <td>
                              {(
                                this.props?.Data?.CommissionAmount -
                                this.props?.Data?.Payments
                              )?.toLocaleString()}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        );
    }
}

export default CommissionPaymentPrint;