const allgetrecoverybooking = { data: [], loading: true };
const allRecoveryTableData = { data: [], loading: true };

export const GetRecoveryBookingRed = (state = allgetrecoverybooking, action) => {
    switch (action.type) {

        case "get_allgetrecoverybooking":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}
export const GetAllRecoveryBookingTableRed = (state = allRecoveryTableData, action) => {
    switch (action.type) {

        case "get_allrecoverytabledata":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}
