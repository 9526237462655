const allNewPurchase = { data: [], loading: true };
export const GetAllNewPurchase = (state = allNewPurchase, action) => {
    switch (action.type) {

        case "get_allnewpurchase":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}
const addfilepopulate = { data: [], loading: true };
export const GetAddfilePopulate = (state = addfilepopulate, action) => {
    switch (action.type) {

        case "get_addfilepopulate":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}