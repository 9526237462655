import React, { Component } from "react";
import gravity from "../../../../assets/GravityLogo.png";
import PrintHeader from "../../../Prints/PrintHeader/PrintHeader";

class TrailBalancePrint extends Component {
  render() {
    return (
      <div style={{ backgroundColor: "white", height: "100%" }}>
        <div className="row m-0 p-0">
          <PrintHeader />
        </div>
        <div
          className="row m-3 p-0"
          // style={{ border: "5px solid darkgrey", height: "auto" }}
        >
          <div className="col-12 text-center mt-2">
            <h4 style={{ color: "red" }}>
              <u>Trail Balance </u>
            </h4>

            <div className="mt-5">
              <div className="  col-12">
                <table className="table table-borderless m-0">
                  <thead>
                    <tr style={{ backgroundColor: "white" }}>
                      <th></th>

                      <th className="text-center" colspan="2">
                        Opening
                      </th>

                      <th className="text-center" colspan="2">
                        For The Period
                      </th>

                      <th className="text-center" colspan="2">
                        Closing
                      </th>
                    </tr>
                    <tr style={{ backgroundColor: "white" }}>
                      <th>Id Code/Account Title</th>
                      <th>Debit</th>
                      <th>Credit</th>
                      <th>Debit</th>
                      <th>Credit</th>
                      <th>Debit</th>
                      <th>Credit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.statetraildata.map((val, index) => {
                      return (
                        <>
                          <tr
                            style={{ backgroundColor: "lightblue" }}
                            key={index}
                          >
                            <td>
                              {val?.IdCode} || <b>{val.Particular}</b>
                            </td>
                            {/* {val?.AccCategory === "AS" ||
                            val?.AccCategory === "EX" ? (
                              val?.OpeningDebit > 0 ? (
                                <>
                                  <td>
                                    <b>
                                      {val?.OpeningDebit !== null
                                        ? val?.OpeningDebit.toLocaleString()
                                        : null}
                                    </b>
                                  </td>
                                  <td>
                                    <b>
                                      {val?.OpeningCredit !== null
                                        ? val?.OpeningCredit.toLocaleString()
                                        : null}
                                    </b>
                                  </td>
                                </>
                              ) : (
                                <>
                                  <td>
                                    <b>
                                      {val?.OpeningDebit !== null
                                        ? Math.abs(
                                            val?.OpeningDebit
                                          )?.toLocaleString()
                                        : null}
                                    </b>
                                  </td>
                                  <td>
                                    <b>
                                      {val?.OpeningCredit !== null
                                        ? Math.abs(
                                            val?.OpeningCredit
                                          )?.toLocaleString()
                                        : null}
                                    </b>
                                  </td>
                                </>
                              )
                            ) : val?.OpeningCredit > 0 ? (
                              <>
                                <td>
                                  <b>
                                    {val?.OpeningDebit !== null
                                      ? val?.OpeningDebit?.toLocaleString()
                                      : null}
                                  </b>
                                </td>
                                <td>
                                  <b>
                                    {val?.OpeningCredit !== null
                                      ? val?.OpeningCredit?.toLocaleString()
                                      : null}
                                  </b>
                                </td>
                              </>
                            ) : (
                              <>
                                <td>
                                  <b>
                                    {val?.OpeningDebit !== null
                                      ? Math.abs(
                                          val?.OpeningDebit
                                        )?.toLocaleString()
                                      : null}
                                  </b>
                                </td>
                                <td>
                                  <b>
                                    {val?.OpeningCredit !== null
                                      ? Math.abs(
                                          val?.OpeningCredit
                                        )?.toLocaleString()
                                      : null}
                                  </b>
                                </td>
                              </>
                            )} */}
                            <td>
                              <b>
                                {val?.OpeningDebit !== null
                                  ? val?.OpeningDebit.toLocaleString()
                                  : null}
                              </b>
                            </td>
                            <td>
                              <b>
                                {val?.OpeningCredit !== null
                                  ? val?.OpeningCredit.toLocaleString()
                                  : null}
                              </b>
                            </td>
                            <td>
                              <b>
                                {val.Debit !== null
                                  ? val.Debit.toLocaleString()
                                  : null}
                              </b>
                            </td>
                            <td>
                              <b>
                                {val.Credit !== null
                                  ? val.Credit?.toLocaleString()
                                  : null}
                              </b>
                            </td>
                            {/* {val.AccCategory === "AS" ||
                            val.AccCategory === "EX" ? (
                              val.Balance > 0 ? (
                                <>
                                  <td>
                                    <b>
                                      {val.Balance !== null
                                        ? val.Balance.toLocaleString()
                                        : null}
                                    </b>
                                  </td>
                                  <td></td>
                                </>
                              ) : (
                                <>
                                  <td></td>
                                  <td>
                                    <b>
                                      {val.Balance !== null
                                        ? Math.abs(val.Balance).toLocaleString()
                                        : null}
                                    </b>
                                  </td>
                                </>
                              )
                            ) : val.Balance > 0 ? (
                              <>
                                <td></td>
                                <td>
                                  <b>
                                    {val.Balance !== null
                                      ? val.Balance.toLocaleString()
                                      : null}
                                  </b>
                                </td>
                              </>
                            ) : (
                              <>
                                <td>
                                  <b>
                                    {val.Balance !== null
                                      ? Math.abs(val.Balance).toLocaleString()
                                      : null}
                                  </b>
                                </td>
                                <td></td>
                              </>
                            )} */}
                            <td>
                              <b>
                                {val?.ClosingDebit !== null
                                  ? Math.abs(val?.Debit)?.toLocaleString()
                                  : null}
                              </b>
                            </td>
                            <td>
                              <b>
                                {val?.ClosingCredit !== null
                                  ? Math.abs(val?.Credit)?.toLocaleString()
                                  : null}
                              </b>
                            </td>
                            {/* {val?.AccCategory === "AS" ||
                            val?.AccCategory === "EX" ? (
                              val?.ClosingDebit > 0 ? (
                                <>
                                  <td>
                                    <b>
                                      {val?.ClosingDebit !== null
                                        ? val?.ClosingDebit?.toLocaleString()
                                        : null}
                                    </b>
                                  </td>
                                  <td>
                                    <b>
                                      {val?.ClosingCredit !== null
                                        ? val?.ClosingCredit?.toLocaleString()
                                        : null}
                                    </b>
                                  </td>
                                </>
                              ) : (
                                <>
                                  <td>
                                    <b>
                                      {val?.ClosingDebit !== null
                                        ? Math.abs(
                                            val?.ClosingDebit
                                          )?.toLocaleString()
                                        : null}
                                    </b>
                                  </td>
                                  <td>
                                    <b>
                                      {val?.ClosingCredit !== null
                                        ? Math.abs(
                                            val?.ClosingCredit
                                          )?.toLocaleString()
                                        : null}
                                    </b>
                                  </td>
                                </>
                              )
                            ) : val?.ClosingCredit > 0 ? (
                              <>
                                <td>
                                  <b>
                                    {val?.ClosingDebit !== null
                                      ? val?.ClosingDebit?.toLocaleString()
                                      : null}
                                  </b>
                                </td>
                                <td>
                                  <b>
                                    {val?.ClosingCredit !== null
                                      ? val?.ClosingCredit?.toLocaleString()
                                      : null}
                                  </b>
                                </td>
                              </>
                            ) : (
                              <>
                                <td>
                                  <b>
                                    {val?.ClosingDebit !== null
                                      ? Math.abs(val?.Debit)?.toLocaleString()
                                      : null}
                                  </b>
                                </td>
                                <td>
                                  <b>
                                    {val?.ClosingCredit !== null
                                      ? Math.abs(val?.Credit)?.toLocaleString()
                                      : null}
                                  </b>
                                </td>
                              </>
                            )} */}
                          </tr>
                          {this.props.recursiveFuncation(
                            val.ChildHeads,
                            val?.ChildAcc
                          )}
                        </>
                      );
                    })}
                    <tr>
                      <td>Grand Total:</td>
                      <td>
                        <b>{this.props.closingDebitTotal?.toLocaleString()}</b>
                      </td>
                      <td>
                        <b>{this.props.closingCreditTotal?.toLocaleString()}</b>
                      </td>
                      <td>
                        <b>
                          {this.props.debitGrandTotalState?.toLocaleString()}
                        </b>
                      </td>
                      <td>
                        <b>
                          {this.props.creditGrandTotalState?.toLocaleString()}
                        </b>
                      </td>
                      <td>
                        <b>{this.props.closingDebitTotal?.toLocaleString()}</b>
                      </td>
                      <td>
                        <b>{this.props.closingCreditTotal?.toLocaleString()}</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TrailBalancePrint;
