import { useState } from "react";
import { Investment } from "./Investment";
import { InvestmentTable } from "./InvestmentTable";


const MainInvestment = () => {

    const showloantable = () => {
        stateshowloan(<Investment cancelloantable={cancelloantable} />);
    }
    const cancelloantable = () => {
        stateshowloan(<InvestmentTable showloantable={showloantable} cancelloantable={cancelloantable} />);
    }
    const [showloan, stateshowloan] = useState(<InvestmentTable showloantable={showloantable} cancelloantable={cancelloantable} />);

    return (

        <>
            <div className="row ">
                <div className="dash_background col-12 p-0">
                    <div className="row ">

                        <div className="col-md-12">
                            {showloan}
                        </div>


                    </div>


                </div>
            </div>
        </>

    )
}

export default MainInvestment;