const allteamspopulate = { data: [], loading: true };

export const GetAllTeamsPopulate = (state = allteamspopulate, action) => {
    switch (action.type) {

        case "get_allteamspopulate":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}
const allteamstable = { data: [], loading: true };

export const GetAllTeamsTable = (state = allteamstable, action) => {
    switch (action.type) {

        case "get_allteamstable":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}
// memberpopulate
const allmemberpopulate = { data: [], loading: true };

export const GetAllMemberPopulate = (state = allmemberpopulate, action) => {
    switch (action.type) {

        case "get_allmemberpopulate":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}
const allmembertable = { data: [], loading: true };

export const GetAllMemberTable = (state = allmembertable, action) => {
    switch (action.type) {

        case "get_allmembertable":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}