import React, { Component } from "react";
import gravity from "../../../../assets/GravityLogo.png";
import PrintHeader from "../../../Prints/PrintHeader/PrintHeader";
class AccReceivablePrint extends Component {
    render() {

        return (
          <div style={{ backgroundColor: "white", height: "100%" }}>
            <div className="row m-0 p-0">
              <PrintHeader />
            </div>
            <div
              className="row m-3 p-0"
              // style={{ border: "5px solid darkgrey", height: "auto" }}
            >
              <div className="col-12 text-center mt-2">
                <h4 style={{ color: "red" }}>
                  <u>Account Receivable</u>
                </h4>

                <div className="mt-5">
                  <div class=" col-12">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>ID Code </th>
                          <th>Account</th>
                          <th>Category</th>
                          <th>Sub Category</th>
                          <th>OpeningBalance</th>
                          <th>Amount</th>
                          <th>Type</th>
                        </tr>
                      </thead>

                      <tbody>
                        {this.props.printData !== undefined
                          ? this.props.printData.map((val, index) => (
                              <tr key={index}>
                                <td>{val.AccountIdCode}</td>
                                <td>{val.AccountTitle}</td>
                                <td>
                                  {val.HeadingNavigation !== null &&
                                  val.HeadingNavigation !== undefined
                                    ? val.HeadingNavigation.Parent.HeadingTitle
                                    : null}
                                </td>
                                <td>
                                  {val.HeadingNavigation !== null &&
                                  val.HeadingNavigation !== undefined
                                    ? val.HeadingNavigation.HeadingTitle
                                    : null}
                                </td>
                                <td>{val.OpeningBalance}</td>
                                <td>
                                  {val.CurrentBalance < 0
                                    ? parseFloat(val.CurrentBalance)
                                        .toLocaleString()
                                        .split("-")[1]
                                    : parseFloat(
                                        val.CurrentBalance
                                      ).toLocaleString()}
                                </td>
                                <td>{val.CurrentBalance < 0 ? "CR" : "DR"}</td>
                              </tr>
                            ))
                          : null}
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>
                            {" "}
                            <label className="input_label m-0">Total:</label>
                          </td>
                          <td>
                            <input
                              type="text "
                              className="input_total_styling "
                              value={parseFloat(
                                this.props.Total
                              ).toLocaleString()}
                            ></input>
                          </td>

                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
    }
}

export default AccReceivablePrint;