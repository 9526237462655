import React from "react";
import saveImage from "../../../assets/icons8-save-50.png";

const SaveImage = () => {
  return (
    <img src={saveImage} style={{ width: "25px" }} alt="img" className="mr-2" />
  );
};

export default SaveImage;
