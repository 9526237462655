import React from 'react'
import Loader from 'react-loader-spinner'

export default function FullScreenLoader({ setshowModal }) {
    return (
        <div style={{ display: "block" }} class="modalscreen ">
            <div style={{ backgroundColor: "transparent" }} class="modal-content-screen1">
                <div className='row col-12' style={{ backgroundColor: "transparent", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", height: "70vh" }}>
                    <Loader type="ThreeDots" color="#5CD1D0" height={40} width={70} />

                </div>

            </div>
        </div>
    )
}
