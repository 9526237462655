import React, { Component } from "react";
import gravity from "../../../../assets/GravityLogo.png";
import PrintHeader from "../../../Prints/PrintHeader/PrintHeader";

export class DetailPrint extends Component {
  render() {
    const { creditorData } = this.props;
    console.log(creditorData);

    return (
      <div style={{ backgroundColor: "white", height: "100%" }}>
        <div className="row m-0 p-0">
          <PrintHeader />
        </div>
        <div className="row m-3 p-0">
          <div className="col-12 text-center mt-2">
            <h4 style={{ color: "red" }}>
              <u>Unit Details </u>
            </h4>

            <div className="container">
              <div className="row">
                <div className="col-12 mt-2">
                  <div className="table-responsive_modal mt-4 ">
                    <div className="text-center">
                      <b>Transaction Details</b>
                    </div>
                    <table className="table table-borderless m-0 ">
                      <thead>
                        <tr>
                          <th>Sr</th>
                          <th>Account</th>
                          <th>Debit</th>
                          <th>Credit</th>
                        </tr>
                      </thead>
                      <tbody>
                        {creditorData !== undefined
                          ? creditorData.Transaction?.map((x, i) => {
                              return (
                                <tr key={i}>
                                  <td>{i + 1}</td>
                                  <td>{x?.Account?.AccountTitle}</td>
                                  <td>
                                    {x.TransactionType === "DEBIT"
                                      ? x?.Amount?.toLocaleString()
                                      : null}
                                  </td>
                                  <td>
                                    {x?.TransactionType === "CREDIT"
                                      ? x?.Amount?.toLocaleString()
                                      : null}
                                  </td>
                                </tr>
                              );
                            })
                          : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 mt-5">
                <div className="table-responsive_modal  mt-0">
                  <div className="text-center">
                    <b>Unit Details</b>
                  </div>
                </div>
              </div>
              <div
                className="col-6 mt-2"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ul>
                  <li>
                    <b>Project:</b>{" "}
                    {creditorData.Project !== undefined
                      ? creditorData.Project
                      : null}
                  </li>
                  <li>
                    <b>Section:</b>{" "}
                    {creditorData.Unit !== undefined
                      ? creditorData.Unit.Section
                      : null}
                  </li>
                  <li>
                    <b>Unit:</b>{" "}
                    {creditorData.Unit !== undefined
                      ? creditorData.Unit.UnitName
                      : null}
                  </li>

                  <li>
                    <b>Marla:</b>{" "}
                    {creditorData.Unit !== undefined
                      ? creditorData.Unit.Marla
                      : null}
                  </li>
                  <li>
                    <b>Sarsai:</b>{" "}
                    {creditorData.Unit !== undefined
                      ? creditorData.Unit.Sarsai
                      : null}
                  </li>
                  <li>
                    <b>LxW:</b>{" "}
                    {creditorData.Unit !== undefined
                      ? creditorData.Unit.DimensionLength +
                        "x" +
                        creditorData.Unit.DimensionWidth
                      : null}
                  </li>
                  <li>
                    <b>Measurement Unit:</b>{" "}
                    {creditorData.Unit !== undefined
                      ? creditorData.Unit.RatePerMarlaOrsqft
                      : null}
                  </li>
                </ul>
              </div>
              <div
                className="col-6 mt-0"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ul>
                  <li>
                    <b>Net Marla:</b>{" "}
                    {creditorData.Unit !== undefined
                      ? parseFloat(creditorData.Unit.NetMarla).toLocaleString()
                      : null}
                  </li>
                  <li>
                    <b>Net Sqft:</b>{" "}
                    {creditorData.Unit !== undefined
                      ? parseFloat(creditorData.Unit.NetSqft).toLocaleString()
                      : null}
                  </li>
                  <li>
                    <b>Sale Rate:</b>{" "}
                    {creditorData.Unit !== undefined
                      ? parseFloat(
                          creditorData.Unit.RatePerMarla
                        ).toLocaleString()
                      : null}
                  </li>
                  <li>
                    <b>Total Value:</b>{" "}
                    {creditorData.Unit !== undefined
                      ? parseFloat(creditorData.Unit.Price).toLocaleString()
                      : null}
                  </li>
                  <li>
                    <b>Cost Rate:</b>{" "}
                    {creditorData.Unit !== undefined
                      ? parseFloat(
                          creditorData.Unit.CostPerMarla
                        ).toLocaleString()
                      : null}
                  </li>
                  <li>
                    <b>Total Cost:</b>{" "}
                    {creditorData.Unit !== undefined
                      ? parseFloat(creditorData.Unit.TotalCost).toLocaleString()
                      : null}
                  </li>
                </ul>
              </div>
            </div>

            {/* <div className="mt-3">
              <div class="col-12">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Sr</th>
                      <th>Project</th>
                      <th>Section</th>
                      <th>Unit</th>
                      <th>Marla</th>
                      <th>Sarsai</th>
                      <th>LxW</th>
                      <th>Measurement</th>
                      <th>Net Marla</th>
                      <th>Net Sqft</th>
                      <th>Total</th>
                      <th>Cost Rate</th>
                      <th>Total Cost</th>
                    </tr>
                  </thead>
                  <tbody>
                    {creditorData?.data !== undefined
                      ? creditorData?.data?.map((val, index) => (
                          <tr key={index}>
                            <td className=" cur_sor ">{index + 1}</td>
                            <td className=" cur_sor ">
                              {val.Date.split("T")[0]}
                            </td>
                            {creditorData.Project !== null &&
                            val.Project !== undefined ? (
                              <td className=" cur_sor ">{val.Project}</td>
                            ) : (
                              <td></td>
                            )}
                            {creditorData.Unit !== null &&
                            creditorData.Unit !== undefined ? (
                              <td className=" cur_sor ">{creditorData.Unit.Section}</td>
                            ) : (
                              <td></td>
                            )}
                            <td className=" cur_sor ">{val.Seller}</td>
                            <td className=" cur_sor ">
                              {val.Unit !== null && val.Unit !== undefined
                                ? val.Unit.UnitName
                                : null}
                            </td>
                            <td className=" cur_sor ">
                              {val.Unit !== null && val.Unit !== undefined
                                ? val.Unit.Marla + "M" + val.Unit.Sarsai + "S"
                                : null}
                            </td>
                            <td className=" cur_sor ">
                              {val.Unit !== null && val.Unit !== undefined
                                ? val.Unit.DimensionLength +
                                  "x" +
                                  val.Unit.DimensionWidth
                                : null}
                            </td>
                            <td className=" cur_sor ">
                              {val.Unit !== null && val.Unit !== undefined
                                ? val.Unit.RatePerMarlaOrsqft
                                : null}
                            </td>
                            <td className=" cur_sor ">
                              {val.Unit !== null && val.Unit !== undefined
                                ? parseFloat(val.Unit.NetMarla).toLocaleString()
                                : null}
                            </td>
                            <td className=" cur_sor ">
                              {val.Unit !== null && val.Unit !== undefined
                                ? parseFloat(val.Unit.NetSqft).toLocaleString()
                                : null}
                            </td>
                            <td className=" cur_sor ">
                              {val.Unit !== null && val.Unit !== undefined
                                ? parseFloat(val.Unit.Price).toLocaleString()
                                : null}
                            </td>
                            <td className=" cur_sor ">
                              {val.Unit !== null && val.Unit !== undefined
                                ? parseFloat(val.Unit.CostPerMarla).toLocaleString()
                                : null}
                            </td>
                            <td className=" cur_sor ">
                              {val.Unit !== null && val.Unit !== undefined
                                ? parseFloat(val.Unit.TotalCost).toLocaleString()
                                : null}
                            </td>
                          </tr>
                        ))
                      : null}
                  </tbody>
                </table>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}