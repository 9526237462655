import { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { GetAllTaxReceivable, getallTaxReceivableTable } from "../../../actions/accountsactions/taxreceivable";
import { CSVLink } from "react-csv";
import TaxReceivableModal from "./TaxReceivableModal";


export const TaxReceivableTable = (props) => {
    const taxdatastate = useSelector(state => state.GetAllTaxReceivable);
    const rolesdata = useSelector((state) => state.GetAllRole);


    let dispatch = useDispatch();
    useEffect(() => {

        dispatch(getallTaxReceivableTable());
    }, [dispatch]);
    //Table Data fiter

    var suggestionlist = [];

    const onTextChange = (e) => {
        const value = e.target.value;
        if (value === "") {

            dispatch(getallTaxReceivableTable());
        } else {
            let suggestions = [];
            if (value.length > 0) {
                const regex = new RegExp(`${value}`, "i");

                suggestions = taxdatastate.data
                    .sort()
                    .filter((val) =>
                        (regex.test(val.TaxName)));

            }
            suggestionlist = suggestions;
            dispatch(GetAllTaxReceivable(suggestionlist));
        }
    };
    function ToVoucher() {
        let taxList = [];
        let taxList2 = [];
        taxdatastate.data.map((x, i) => {
            let sum = 0;
            x.TaxReceivableDetail.map((val, index) => {
                if (val.Checked == true) {
                    sum += val.TaxAmount
                    taxList2.push(val.TaxReceivableDetailId)

                }
            })
            taxList.push({ TaxId: x.TaxId, Amount: sum })
        })
     
        props.settaxList2(taxList2);
        props.settaxList(taxList);


    }
    // useEffect(()=>{
    //     if I
    // })
    const [CsvData, setCsvData] = useState([]);
    useEffect(() => {
      let arr = [];
      taxdatastate.data?.forEach((x, index) => {
        arr.push({
          "": x.TaxName,
        });
        x.TaxReceivableDetail.map((val, i) => {
          let year = val.EntryDate.slice(0, 4);
          let day = val.EntryDate.slice(8, 10);
          let month = val.EntryDate.slice(5, 7);
          arr.push({
            // Sr: i+1,
            Date: day + "-" + month + "-" + year,
            "Voucher No": val.VoucherNo,
            Account: val.Account.AccountTitle,
            "Total Amt": val.TotalAmount.toLocaleString(),
            "Tax%": val.TaxPer,
            "Tax Amt": val.TaxAmount.toLocaleString(),
            Status: val.Received === false ? "Pending" : "Received",
          });
        });
      });
      setCsvData(arr);
    }, [taxdatastate.data]);


    return (
      <>
        <div className="row  ">
          <div className="dash_background col-12 p-0">
            <div className="row pt-3 ">
              <div className="col-md-1 pr-0 pl-0">
                <label className="input_label  m-0">Search:</label>
              </div>
              <div className="col-md-3   pr-0 pl-0">
                <input
                  type="text "
                  className="input_styling "
                  placeholder="Tax Name"
                  onChange={onTextChange}
                ></input>
              </div>
              <TaxReceivableModal/>
              {/* 
                        <div className="col-md-3 text-center  pr-0 pl-0">
                            <label className="input_label m-0">From:</label>
                            <input type="date" className="input_date ml-3" id="from date"></input>
                        </div>
                        <div className="col-md-3 text-center pr-0 pl-0">
                            <label className="input_label m-0">To:</label>
                            <input type="date" className="input_date ml-3" id="todate"></input>
                        </div>
                        <div className="col-md-1 pr-0 pl-0">
                            <button className="btn_Go">Go</button>
                        </div> */}
              <div className="col-md-1 pr-0 pl-0">
                {taxdatastate.loading ? (
                  <Loader
                    type="ThreeDots"
                    color="green"
                    height={40}
                    width={40}
                  />
                ) : null}
              </div>
            </div>

            <div className="row ">
              <div className=" col-12 pr-0 pl-0 table_height">
                <div className="table-responsive mt-4">
                  <table className="table table-borderless m-0">
                    <thead>
                      <tr>
                        {/* <th>Sr</th> */}
                        <th>Date</th>
                        <th>Voucher No</th>

                        <th>Account</th>
                        <th>Total Amt</th>
                        <th>Tax Per</th>
                        <th>Tax Amt</th>
                        <th>Status</th>

                        <th className="text-center">
                          {rolesdata?.data?.Access?.includes("C") ? (
                            <button
                              className="save_btn"
                              onClick={() => {
                                props.showtaxtable();
                                ToVoucher();
                              }}
                            >
                              Pay
                            </button>
                          ) : null}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* <tr>{taxdatastate.data[0].TaxName}</tr> */}
                      {taxdatastate.data.map((x, index) => (
                        <>
                          <tr>
                            <td>
                              <b>{x.TaxName}</b>
                            </td>
                            <td></td>
                            <td></td>

                            <td></td>

                            <td></td>
                            <td></td>
                            <td></td>

                            <td>
                              <input
                                id="IncomeCheck"
                                type="checkbox"
                                name="check1"
                                onChange={(e) => {
                                  taxdatastate.data[index].Checked =
                                    e.target.checked;
                                  taxdatastate.data[
                                    index
                                  ].TaxReceivableDetail.map((val, i) => {
                                    taxdatastate.data[
                                      index
                                    ].TaxReceivableDetail[i].Checked =
                                      e.target.checked;
                                    document.getElementById(
                                      "advCheck" + val.TaxReceivableDetailId
                                    ).checked = e.target.checked;
                                  });
                                }}
                              ></input>
                            </td>
                          </tr>

                          {x.TaxReceivableDetail.map((val, i) => {
                            let year = val.EntryDate.slice(0, 4);
                            let day = val.EntryDate.slice(8, 10);
                            let month = val.EntryDate.slice(5, 7);
                            switch (month) {
                              case "01":
                                month = "Jan";

                                break;
                              case "02":
                                month = "Feb";

                                break;
                              case "03":
                                month = "Mar";

                                break;
                              case "04":
                                month = "Apr";

                                break;
                              case "05":
                                month = "May";

                                break;
                              case "06":
                                month = "Jun";

                                break;
                              case "07":
                                month = "Jul";

                                break;
                              case "08":
                                month = "Aug";

                                break;
                              case "09":
                                month = "Sep";

                                break;
                              case "10":
                                month = "Oct";
                                break;
                              case "11":
                                month = "Nov";
                                break;
                              case "12":
                                month = "Dec";
                                break;
                              default:
                                break;
                            }
                            return (
                              <tr key={i}>
                                {/* <td>{i + 1}</td> */}
                                <td>{day + "-" + month + "-" + year}</td>

                                <td>{val.VoucherNo}</td>
                                <td>{val.Account.AccountTitle}</td>
                                <td>{val.TotalAmount.toLocaleString()}</td>
                                <td>{val.TaxPer}</td>
                                <td>{val.TaxAmount.toLocaleString()}</td>
                                <td>
                                  {val.Received === false
                                    ? "Pending"
                                    : "Received"}
                                </td>

                                <td>
                                  <input
                                    id={"advCheck" + val.TaxReceivableDetailId}
                                    type="checkbox"
                                    name="check2"
                                    onChange={(e) => {
                                      taxdatastate.data[
                                        index
                                      ].TaxReceivableDetail[i].Checked =
                                        e.target.checked;
                                    }}
                                  ></input>
                                </td>
                              </tr>
                            );
                          })}
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
                <button
                  style={{ color: "black !important" }}
                  className="print_btn ml-2 float-left"
                >
                  <CSVLink
                    style={{ color: "black" }}
                    filename="Tax_Receivable_Csv"
                    data={CsvData}
                  >
                    Download CSV
                  </CSVLink>
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}