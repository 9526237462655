const allsections = { data: [], loading: true };
export const GetAllSections = (state = allsections, action) => {
    switch (action.type) {

        case "get_allsections":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}
