import React, { Component } from "react";
import gravity from "../../../../assets/GravityLogo.png";
export class UnitPrint extends Component {
    render() {

        return (
            <div style={{ backgroundColor: "white", height: "100%" }}>
                <div className="row m-0 p-0">
                    <div className="watermarked">
                        <img
                            src={gravity}
                            style={{ height: "600px", width: "600px" }}
                            alt="Company logo"
                        />
                    </div>
                    <div className="col-8 text-left mt-4">
                        <h2 style={{ color: "#1b7a7f" }}>Gravity Associates Pvt Limited</h2>
                        <hr />
                        <h5 style={{ color: "grey", marginTop: "5px" }}>
                            <i>
                                204 R/B Road, East Canal Road, Faisalabad.
                            </i>
                        </h5>
                        <div
                            style={{
                                borderTop: "2px dotted black",
                                borderBottom: "2px dotted black",
                                height: "7px",
                            }}
                        ></div>
                    </div>
                    <div className="col-4 text-center">
                        {/* IMAGE COMES HERE */}

                        <img
                            src={gravity}
                            style={{ height: "175px", width: "175px", borderRadius: "100px" }}
                            className="pt-2"
                            alt="Company logo"
                        />
                    </div>
                </div>
                <div
                    className="row m-3 p-0"
                    style={{ border: "5px solid darkgrey", height: "85%" }}
                >
                    <div className="col-12 text-center mt-2">
                        <h4 style={{ color: "red" }}>
                            <u>Unit </u>
                        </h4>
                        <h5>
                            {/* <b>Voucher No.:{" "}</b> */}
                            {/* {this.props.printData !== undefined ?

                                this.props.printData.GeneralVoucherId

                                : null} */}
                        </h5>
                        <h5 className="text-left">
                            <b>Project Name: {" "}</b>
                            {this.props.printData !== undefined ?

                                this.props.printData.Project !== null && this.props.printData.Project !== undefined ?
                                     this.props.printData.Project.ProjectName === "THE GRAND CITY" ? "THE GRAND ICONIC CITY" : this.props.printData.Project.ProjectName : null

                                : null}
                        </h5>



                        <div className="mt-3">
                            <div class=" col-12">
                                <table class="table">
                                    <thead>
                                        <tr>




                                            <th>Project</th>
                                            <th>Section </th>
                                            <th>Unit</th>
                                            <th>Area</th>
                                            <th>Dimension</th>
                                            <th>S.Rate</th>
                                            <th>Total Value</th>
                                        </tr>
                                    </thead>
                                    {this.props.printData !== undefined ?
                                        <tbody>
                                            <tr>
                                                {this.props.printData.Project !== null && this.props.printData.Project !== undefined ?
                                                    <td>{ this.props.printData.Project.ProjectName === "THE GRAND CITY" ? "THE GRAND ICONIC CITY" : this.props.printData.Project.ProjectName}</td> : <td></td>}
                                                {this.props.printData.Section !== null && this.props.printData.Section !== undefined ?
                                                    <td>{this.props.printData.Section.SectionName}</td> : <td></td>}
                                                <td>{this.props.printData.UnitName}</td>

                                                {/* <td>{this.props.printData.NetMarla}</td> */}
                                                <td>{this.props.printData.Marla + "M" + this.props.printData.Sarsai + "S"}</td>
                                                <td>{this.props.printData.DimensionLength + "x" + this.props.printData.DimensionWidth}</td>
                                                <td>{parseFloat(this.props.printData.RatePerMarla).toLocaleString()}</td>
                                                <td>{parseFloat(this.props.printData.Price).toLocaleString()}</td>

                                                {/* {this.props.printData.Section !== null && this.props.printData.Section !== undefined ?
                                                    <td>{this.props.printData.Section.SectionName}</td> : <td></td>}
                                                <td>{this.props.printData.UnitName}</td>

                                                <td>{this.props.printData.NetMarla} </td>
                                                <td>{this.props.printData.DimensionLength + "x" + this.props.printData.DimensionWidth + " " + this.props.printData.DimensionUnit.DimensionUnit}</td>
                                                <td>{parseFloat(this.props.printData.Price).toLocaleString()}</td> */}
                                            </tr>
                                        </tbody> : null}
                                </table>
                            </div>
                        </div>
                        {/* <h5 className="text-right pt-3">
                            <b>Narration:{" "}</b>
                            {this.props.printData !== undefined ?

                                this.props.printData.Narration

                                : null}
                        </h5> */}
                    </div>
                </div>
            </div>
        );
    }
}