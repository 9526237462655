import { useState } from "react";
import { Unit } from "./Unit";
import { UnitTable } from "./UnitTable";

const MainUnit = () => {
  const showunittable = () => {
    stateshowunit(<Unit cancelunittable={cancelunittable} />);
  };
  const cancelunittable = () => {
    stateshowunit(
      <UnitTable
        showunittable={showunittable}
        cancelunittable={cancelunittable}
      />
    );
  };
  const [showunit, stateshowunit] = useState(
    <UnitTable
      showunittable={showunittable}
      cancelunittable={cancelunittable}
    />
  );

  return (
    <>
      <div className="row ">
        <div className="dash_background col-12 p-0">
          <div className="row ">
            <div className="col-md-12">{showunit}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainUnit;
