const alltransfercontractPopulate = { data: [], loading: true };
const transfercontracttable = { data: [], loading: true };
export const GetAllTransferContractPopulateRed = (state = alltransfercontractPopulate, action) => {
    switch (action.type) {

        case "get_alltransfercontractpopulate":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}
export const GetTransferContractTable = (state = transfercontracttable, action) => {
    switch (action.type) {

        case "get_transfercontracttable":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}