import { useEffect } from "react";
import { useState } from "react";
import { DealSoldForm } from "./DealSold";
import { DealSoldCancel } from "./DealSoldCancel";
import { DealSoldTable } from "./DealSoldTable";

const DealSoldMain = () => {
    const [statecaneldataList1, setcancelList1] = useState();
    const [editstate, setedit] = useState("false");
    const [editfielddata, seteditfielddata] = useState({});
    const [dealunitid, setdealunitid] = useState(0);

    const [edittablepopulate, setedittablepopulate] = useState([]);
    let newlist = [];
    const [tablepopulate, settablepopulate] = useState([]);
    const showloantable = () => {
        stateshowloan("DealSoldComp");
    }
    const cancelloantable = () => {
        stateshowloan("DealSoldTableComp");
    }
    const showdealcancelscreen = () => {
        stateshowloan("DealcancelComp");
    }
    const [showloan, stateshowloan] = useState("DealSoldTableComp");
    function settabledata() {
        edittablepopulate.map((x) => {
            newlist.push({
                project: x.Unit.Project !== undefined ? x.Unit.Project.ProjectName : null,
                unitid: parseInt(x.Unit.UnitId),
                // split: document.getElementById("unit").options[document.getElementById("unit").selectedIndex].dataset.split,
                block: x.Unit.Section !== undefined ? x.Unit.Section.SectionName : null,
                unit: x.Unit !== undefined ? x.Unit.UnitName : null,
                marlas: x.Unit !== undefined ? x.Unit.Marla : null,
                sarsais: x.Unit !== undefined ? x.Unit.Sarsai : null,
                Amount: x.Unit !== undefined && x.Unit.CostPerMarla !== null ? x.Unit.CostPerMarla.toLocaleString() : null,
                Ratemarla: x.Unit !== undefined ? x.Unit.RatePerMarla : null,
                rateperms: x.Unit !== undefined ? x.Unit.RatePerMarlaOrsqft : null,
                netsqft: x.Unit !== undefined ? x.Unit.NetSqft : null,
                NewSaleRate: x.NewSaleRate.toLocaleString(),
                TotalSaleValue: x.TotalSaleValue.toLocaleString(),
                AdvanceAmount: x.AdvanceAmount.toLocaleString()
            }
                // settablepopulate([...tablepopulate, item]);
            )

        });
        settablepopulate(newlist);
    }


    useEffect(() => {
        settabledata();
    }, [editstate])
    return (

        <>
            <div className="row ">
                <div className="dash_background col-12 p-0">
                    <div className="row ">

                        <div className="col-md-12">
                            {showloan == "DealSoldTableComp" ? <DealSoldTable settablepopulate={settablepopulate} setedittablepopulate={setedittablepopulate} setdealunitid={setdealunitid} seteditfielddata={seteditfielddata} showloantable={showloantable} cancelloantable={cancelloantable} setedit={setedit} showdealcancelscreen={showdealcancelscreen} setcancelList1={setcancelList1} /> : showloan == "DealSoldComp" ? <DealSoldForm setdealunitid={setdealunitid} dealunitid={dealunitid} seteditfielddata={seteditfielddata} editfielddata={editfielddata} setedit={setedit} cancelloantable={cancelloantable} settablepopulate={settablepopulate} setedittablepopulate={setedittablepopulate} tablepopulate={tablepopulate} editstate={editstate} /> : <DealSoldCancel statecaneldataList1={statecaneldataList1} cancelloantable={cancelloantable} />}

                        </div>


                    </div>


                </div>
            </div>
        </>

    )
}
export default DealSoldMain;
