const allgetreceiptInstall = { data: [], loading: true };
const allreceiptinstallTableData = { data: [], loading: true };

export const GetReceiptInstallRed = (state = allgetreceiptInstall, action) => {
    switch (action.type) {

        case "get_allgetreceiptInstall":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}
export const GetAllReceiptInstallTableRed = (state = allreceiptinstallTableData, action) => {
    switch (action.type) {

        case "get_allreceiptinstalltabledata":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}