import { useState } from "react";
import { RecoveryBookingForm } from "./RecoveryBookingForm";
import { RecoveryBookingTable } from "./RecoveryBookingTable";

const RecoveryBookingMain = () => {
  // functions for recoverybooking screen
  const showbookingtable = () => {
    stateshowbooking(
      <RecoveryBookingForm cancelbookingtable={cancelbookingtable} />
    );
  };
  const cancelbookingtable = () => {
    stateshowbooking(
      <RecoveryBookingTable
        showbookingtable={showbookingtable}
        cancelbookingtable={cancelbookingtable}
      />
    );
  };
  const [showbooking, stateshowbooking] = useState(
    <RecoveryBookingTable
      showbookingtable={showbookingtable}
      cancelbookingtable={cancelbookingtable}
    />
  );

  return (
    <>
      <div className="row">
        <div className="dash_background col-12 p-0">
          <div className="row ">
            <div className="col-md-12">{showbooking}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecoveryBookingMain;
