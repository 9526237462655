const allgetdealboughtpay = { data: [], loading: true };

const allDealBPTableData = { data: [], loading: true };

export const GetDealBoughtPayRed = (state = allgetdealboughtpay, action) => {
    switch (action.type) {

        case "get_alldealboughtpay":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}
export const GetAllDealBoughtPayTableRed = (state = allDealBPTableData, action) => {
    switch (action.type) {

        case "get_allDealBPtabledata":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}