import moment from "moment";
import React, { Component } from "react";
import gravity from "../../../../assets/GravityLogo.png";
import PrintHeader from "../../../Prints/PrintHeader/PrintHeader";
class CashBookPrint extends Component {
    render() {

        return (
          <div style={{ backgroundColor: "white", height: "100%" }}>
            <div className="row m-0 p-0">
              <PrintHeader />
            </div>
            <div
              className="row m-3 p-0"
              // style={{ border: "5px solid darkgrey", height: "auto" }}
            >
              <div className="col-12 text-center mt-2">
                <h4 style={{ color: "red" }}>
                  <u>Cash Book </u>
                </h4>
                <h5>
                  <b>OpeningBalance: </b>
                  {this.props?.stateopeningbal !== undefined
                    ? this.props.stateopeningbal?.toLocaleString()
                    : null}
                </h5>
                <h5>
                  <b>Cash In Hand: </b>
                  {this.props.statecashinhand !== undefined
                    ? this.props.statecashinhand?.toLocaleString()
                    : null}
                </h5>

                <div className="mt-5">
                  <div class=" col-12">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>Sr</th>

                          <th>Date</th>
                          <th>Narration</th>
                          <th>Debit</th>
                          <th>Credit</th>
                          <th>Balance</th>
                          <th>Type</th>
                        </tr>
                      </thead>

                      <tbody>
                        {this.props.printData !== undefined
                          ? this.props.printData?.map((val, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                  {moment(val.EntryDate?.split("T")[0]).format(
                                    "DD-MMM-YYYY"
                                  )}
                                </td>
                                <td>{val.Narration}</td>

                                {val.TransactionType === "DEBIT" ? (
                                  <td>
                                    {parseFloat(val.Amount)?.toLocaleString()}
                                  </td>
                                ) : (
                                  <td></td>
                                )}
                                {val.TransactionType === "CREDIT" ? (
                                  <td>
                                    {parseFloat(val.Amount)?.toLocaleString()}
                                  </td>
                                ) : (
                                  <td></td>
                                )}
                                <td>
                                  {parseFloat(
                                    val.AccountBalance
                                  )?.toLocaleString()}
                                </td>
                                <td>{val.AccountBalance < 0 ? "CR" : "DR"}</td>
                              </tr>
                            ))
                          : null}
                        <tr>
                          <td></td>
                          <td></td>
                          <td>
                            {" "}
                            <label className="input_label m-0">Total:</label>
                          </td>
                          <td>
                            <input
                              type="text"
                              className="input_total_styling "
                              placeholder="DEBIT"
                              disabled
                              defaultValue={parseFloat(
                                this.props.statedebit
                              ).toLocaleString()}
                              key={this.props.statedebit}
                            ></input>
                          </td>
                          <td>
                            <input
                              type="text"
                              className="input_total_styling "
                              placeholder="CREDIT"
                              disabled
                              defaultValue={parseFloat(
                                this.props.statecredit
                              ).toLocaleString()}
                              key={this.props.statecredit}
                            ></input>
                          </td>
                          <td>
                            <input
                              type="text"
                              className="input_total_styling "
                              placeholder="BALANCE"
                              disabled
                              value={parseFloat(
                                this.props.statedebit - this.props.statecredit
                              ).toLocaleString()}
                            ></input>
                          </td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
    }
}

export default CashBookPrint;