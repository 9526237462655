import React, { Component } from "react";
import gravity from "../../../../assets/GravityLogo.png";
import moment from "moment";
import PrintHeader from "../../../Prints/PrintHeader/PrintHeader";

class ReserveCommissionPayableReportPrintAll extends Component {
  TotalValue;
  TotalAdminPayable;
  TotalPaid;
  TotalPayable;


  render() {
    if (this.props?.Data !== undefined) {
      this.TotalValue = 0;
      this.TotalAdminPayable = 0;
      this.TotalPaid = 0;
      this.TotalPayable = 0;
      
      this.props?.Data?.map((val, index) => {
        this.TotalPaid += val?.Payments;
        this.TotalValue += parseFloat(val?.ContractPrice);
        this.TotalAdminPayable += val?.ReservedCommissionAmount
          ? val?.ReservedCommissionAmount
          : 0;
        this.TotalPayable +=
          (val?.ReservedCommissionAmount ? val?.ReservedCommissionAmount : 0) -
          val?.Payments;
      });
    }

    return (
      <div style={{ backgroundColor: "white", height: "100%" }}>
        <div className="row m-0 p-0">
          <PrintHeader />
        </div>
        <div
          className="row m-3 p-0"
          // style={{ border: "5px solid darkgrey", height: "auto" }}
        >
          <div className="col-12 text-center mt-2">
            <h4 style={{ color: "red" }}>
              <u>Reserved Commission Payables</u>
            </h4>

            <div className="mt-5">
              <div class=" col-12">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Sr</th>
                      <th scope="col">Date</th>
                      <th scope="col">Affiliate</th>
                      <th scope="col">Unit</th>
                      <th scope="col">Total Value</th>
                      <th scope="col">Reserved Comm</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>

                  <tbody>
                    {this.props?.Data !== undefined
                      ? this.props?.Data?.map((x, id) => (
                          <tr key={id}>
                            <td>{id + 1}</td>
                            <td>
                              {moment(x?.EntryDate).format("DD-MMM-YYYY")}
                            </td>
                            <td>{x?.Reference}</td>
                            <td>{x?.UnitName}</td>
                            <td>{x?.ContractPrice?.toLocaleString()}</td>
                            <td>
                              {x?.ReservedCommissionAmount
                                ? x?.ReservedCommissionAmount.toLocaleString()
                                : 0}
                            </td>
                            <td>{x?.ContractStatus}</td>
                          </tr>
                        ))
                      : null}

                    <tr>
                      <td colSpan="2" className="text-center">
                        <b>Grand Total</b>
                      </td>
                      <td></td>
                      <td></td>
                      <td>
                        <b>{this.TotalValue?.toLocaleString()}</b>
                      </td>
                      <td>
                        <b>{this.TotalAdminPayable?.toLocaleString()}</b>
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ReserveCommissionPayableReportPrintAll;
