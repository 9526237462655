import moment from "moment";
import React, { Component } from "react";
import gravity from "../../../../assets/GravityLogo.png";
import PrintHeader from "../../../Prints/PrintHeader/PrintHeader";
export class InvestmentPrint extends Component {
    render() {

        return (
          <div style={{ backgroundColor: "white", height: "100%" }}>
            <div className="row m-0 p-0">
              <PrintHeader />
            </div>
            <div
              className="row m-3 p-0"
              // style={{ border: "5px solid darkgrey", height: "auto" }}
            >
              <div className="col-12 text-center mt-2">
                <h4 style={{ color: "red" }}>
                  <u>Investments</u>
                </h4>

                <div className="mt-5">
                  <div class=" col-12">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>Sr#</th>
                          <th>Investor</th>

                          <th>Inv Name</th>
                          <th>Entry Date</th>
                          <th>Due Date</th>
                          <th>Amount</th>
                          <th>Profit %</th>
                          <th>T&C</th>
                        </tr>
                      </thead>

                      <tbody>
                        {this.props.printData.data !== undefined
                          ? this.props.printData.data.map((val, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                  {val.Lender !== undefined
                                    ? val.Lender.LenderName
                                    : null}
                                </td>
                                <td>{val.LoanName}</td>
                                <td>
                                  {moment(val.EntryDate.split("T")[0]).format(
                                    "DD-MMM-YYYY"
                                  )}
                                </td>
                                <td>
                                  {moment(val.DueDate.split("T")[0]).format(
                                    "DD-MMM-YYYY"
                                  )}
                                </td>
                                <td>
                                  {parseFloat(val.Amount).toLocaleString()}
                                </td>
                                <td>{val.NetProfit}</td>

                                <td>
                                  {val.TermsAndConditions !== null
                                    ? val.TermsAndConditions
                                    : null}
                                </td>
                              </tr>
                            ))
                          : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
    }
}