const allrepurchasePopulate = { data: [], loading: true };
const repurchasetable = { data: [], loading: true };
export const GetAllRePurchasePopulateRed = (state = allrepurchasePopulate, action) => {
    switch (action.type) {

        case "get_allrepurchasepopulate":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}
export const GetRePurchaseTable = (state = repurchasetable, action) => {
    switch (action.type) {

        case "get_repurchasetable":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}