import { createContext, useState } from "react";
export const monthlyAlert = createContext();
const MonthlyAlertContextProvider = (props) => {
    const [showAlertModal, changeItems] = useState(false);
    const changeState = (val) => {
        changeItems(val);
    };
    return (
        <monthlyAlert.Provider value={{ showAlertModal, changeState }}>
            {props.children}
        </monthlyAlert.Provider>
    );
};
export default MonthlyAlertContextProvider;