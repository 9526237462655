import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { TiPrinter } from "react-icons/ti";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { getallSection } from "../../../actions/getsections";
import { AllSectionPrint } from "../ProjectsPrint/AllSectionPrint";
import { SectionPrint } from "../ProjectsPrint/SectionPrint";
import { CSVLink } from "react-csv";


export const SectionTable = (props) => {
    const resultsallsections = useSelector((state) => state.getAllSections);
    const dispatch = useDispatch();
    const [filerunits, setfilterunits] = useState();

    const [printData, SetPrintData] = useState();
    // print function
    const componentRef = useRef();

    const printRecoveryBooking = useReactToPrint({
        content: () => componentRef.current,
    });
    useEffect(() => {
        if (printData !== undefined) {
            printRecoveryBooking();
        }
    }, [printData]);
    // all projects print

    const AllcomponentRef = useRef();

    const AllprintPaymentRec = useReactToPrint({
        content: () => AllcomponentRef.current,
    });
    //Table Data fiter
    const onTextChange = (e) => {

        if (document.getElementById("projectfilter").value === "" && document.getElementById("sectionfilter").value === "") {
            setfilterunits(resultsallsections.data);

        } else {
            let suggestions = [];
            {
                const regex = new RegExp(document.getElementById("projectfilter").value, "i");
                const regex1 = new RegExp(document.getElementById("sectionfilter").value, "i");





                suggestions = resultsallsections.data
                    .sort()
                    .filter((val) =>
                        (document.getElementById("projectfilter").value === "" || regex.test(val.Project.ProjectName)) &&
                        (document.getElementById("sectionfilter").value === "" || regex1.test(val.SectionName))



                    );

            }
            setfilterunits(suggestions);
        }
    };
    useEffect(() => {


        dispatch(getallSection());
    }, [dispatch]
    )
    useEffect(() => {
        setfilterunits(resultsallsections.data);
    
    }, [resultsallsections]
    );
    const [CsvData, setCsvData] = useState([]);
    useEffect(() => {
      let arr = [];
      filerunits?.forEach((val, index) => {
        arr.push({
          Sr: index + 1,
          "Project Name":
            val.Project !== null && val.Project !== undefined
              ? val.Project.ProjectName
              : "",
          "Section Name": val.SectionName,
          "Section Type": val.SectionType.Type,
          Category:
            val.Category !== null && val.Category !== undefined
              ? val.Category.CategoryName
              : "",
          "Sub Category":
            val.SubCategory !== null && val.SubCategory !== undefined
              ? val.SubCategory.SubcategoryName
              : "",
          "Adv%":
            val.AdvanceAmountPer !== null && val.AdvanceAmountPer !== undefined
              ? val.AdvanceAmountPer
              : "",
          "Pay Time": val.Installments + " Months",
          "Marketing Exp%": val.MarketingExpensePercentage,
        });
      });
      setCsvData(arr);
    }, [filerunits]);
    return (
        <>
            <div hidden>
                <SectionPrint
                    ref={componentRef}

                    printData={printData}

                />
            </div>
            <div hidden>
                <AllSectionPrint
                    ref={AllcomponentRef}

                    printData={resultsallsections}

                />
            </div>
            <div className="row pt-3 ">
                <div className="col-md-1 pr-0 pl-0">


                    <label className="input_label m-0">Search:</label>
                </div>
                <div className="col-md-2 pr-0 pl-0">


                    <input type="text " className="input_styling " id="projectfilter" onChange={onTextChange} placeholder="Project "></input>
                </div>
                <div className="col-md-2 pr-0 pl-0">


                    <input type="text " className="input_styling " id="sectionfilter" onChange={onTextChange} placeholder="Section"></input>
                </div>

                <div className="col-md-1 pr-0 pl-0">
                    {resultsallsections.loading ? <Loader type="ThreeDots"
                        color="green"
                        height={40}
                        width={40} /> : null}
                </div>
            </div>

            <div className="row ">
                <div className=" col-12  table_height pr-0 pl-0">
                    <div className="table-responsive mt-4">

                        <table className="table table-borderless m-0">
                            <thead>
                                <tr>
                                    <th>Sr</th>

                                    <th>Project Name</th>
                                    <th>Section Name</th>
                                    <th>Section Type</th>
                                    <th>Category</th>
                                    <th>Sub Category</th>

                                    <th>Adv%</th>
                                    <th>Pay Time</th>
                                    <th>Marketing Exp%</th>

                                    <th className="text-center">
                                        <button
                                            className="save_btn"
                                            onClick={() => {
                                                props.showsectiontable();
                                            }}
                                        >
                                            New+
                                        </button>

                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {filerunits !== undefined ? filerunits.map((val, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>

                                        {val.Project !== null && val.Project !== undefined ?
                                            <td>{val.Project.ProjectName}</td> : <td></td>}
                                        <td>{val.SectionName}</td>
                                        <td>{val.SectionType.Type}</td>
                                        {val.Category !== null && val.Category !== undefined ?
                                            <td>{val.Category.CategoryName}</td> : <td></td>}
                                        {val.SubCategory !== null && val.SubCategory !== undefined ?
                                            <td>{val.SubCategory.SubcategoryName}</td> : <td></td>}


                                        {val.AdvanceAmountPer !== null && val.AdvanceAmountPer !== undefined ?
                                            <td>{val.AdvanceAmountPer}</td> : <td></td>}
                                        <td>{val.Installments + " Months"}</td>
                                        <td>{val.MarketingExpensePercentage}</td>
                                        {/* <td className="text-center cur_sor" onClick={() => SetPrintData(val)}>
                                            <GrPrint size="1.3em" />
                                        </td> */}
                                        <td></td>

                                    </tr>)) : null}

                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-6  text-right pr-0 pl-0">
                    <button className="print_btn float-left" onClick={() => { AllprintPaymentRec(); }}><TiPrinter size="1.3em" />Print</button>
                    <button
                    style={{ color: "black !important" }}
                    className="print_btn ml-2 float-left"
                    >
                    <CSVLink
                        style={{ color: "black" }}
                        filename="Section_Csv"
                        data={CsvData}
                    >
                        Download CSV
                    </CSVLink>
                    </button>                    

                </div></div>
        </>
    );
};