import moment from "moment";
import React, { Component } from "react";
import gravity from "../../../../assets/GravityLogo.png";
import PrintHeader from "../../../Prints/PrintHeader/PrintHeader";
export class InstallRecPrint extends Component {
    render() {

        return (
          <div style={{ backgroundColor: "white", height: "100%" }}>
            <div className="row m-0 p-0">
              <PrintHeader />
            </div>
            <div
              className="row m-3 p-0"
              // style={{ border: "5px solid darkgrey", height: "auto" }}
            >
              <div className="col-12 text-center mt-2">
                <h4 style={{ color: "red" }}>
                  <u>Installment Receipt Report</u>
                </h4>

                <div className="mt-5">
                  <div class=" col-12">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>Sr</th>
                          <th>Date</th>
                          <th>Customer</th>
                          <th>Pay.Type</th>

                          <th>Project</th>

                          <th>Unit</th>
                          <th>Received</th>
                          <th>Paid</th>
                          <th>Status</th>
                        </tr>
                      </thead>

                      <tbody>
                        {this.props.printData !== undefined
                          ? this.props.printData.map((val, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                  {moment(val.EntryDate.split("T")[0]).format(
                                    "DD-MMM-YYYY"
                                  )}
                                </td>
                                <td>{val.CustomerName}</td>

                                <td>{val.PaymentType}</td>
                                <td>
                                  {val.Project !== null &&
                                  val.Project !== undefined
                                    ? val.Project.ProjectName
                                    : null}
                                </td>
                                <td>{val.Unit !== null ? val.Unit : null}</td>

                                <td>
                                  {val.ReceivedAmount !== null
                                    ? val.ReceivedAmount.toLocaleString()
                                    : null}
                                </td>
                                <td>
                                  {val.PaidAmount !== null
                                    ? val.PaidAmount.toLocaleString()
                                    : null}
                                </td>
                                <td>
                                  {val.Paid !== true ? "Pending" : "Paid"}
                                </td>
                              </tr>
                            ))
                          : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
    }
}