import { GrPrint } from "react-icons/gr";

const CommissionPaymentModal= ({setshowModal, ModalData , IndividualprintPaymentRec, setModalData}) => {
    return (
        <div  class="Defaultmodal">
            <div class="modal-content-deal">
                <div className="row">
                    <div className="col-12  page_heading">
                        <h4 className="  text-center pt-2">Commission Payments Contract</h4>
                        <span
                            class="close pr-2  "
                            onClick={()=> {setshowModal(false); setModalData(null)}}
                        >
                            &times;
                        </span>

                    </div>
                </div>

                <div className="row">
                    <div className="col-12 mt-2">
                        <table class="table  table-borderless">
                            <thead class="purple whiteText">
                                <tr>
                                    <th scope="col">Sr</th>
                                    <th scope="col">Commission Amount</th>
                                    <th scope="col">Contract Status</th>
                                    <th scope="col">Entry Date</th>
                                    <th scope="col">Reference</th>                                
                                    <th scope="col">Print</th>                                
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    ModalData.map((x, id) => 
                                    <tr key={id} >
                                        <td>{id + 1}</td>
                                        <td>{x.CommissionAmount}</td>
                                        <td>{x.ContractStatus}</td>
                                        <td>{x.EntryDate}</td>
                                        <td>{x.Reference}</td>
                                        <td>
                                            <GrPrint
                                                className="reportcommissiondetails"
                                                onClick={IndividualprintPaymentRec}
                                            />
                                        </td>
                                    </tr>
                                    )
                                }
                            </tbody>
                     
                        </table>
                    </div >
                    {/* <div className="col-12 text-right">
                        <button className="print_btn mr-2 " onClick={() => { IndividualprintPaymentRec(); }}><TiPrinter size="1.3em" />Print</button>

                    </div> */}
                </div >

            </div>
        </div>
    );
};

export default CommissionPaymentModal;