import { useState } from "react";
import {  SectionForm } from "./SectionForm";
import { SectionTable } from "./SectionTable";


const MainSection = () => {

    const showsectiontable = () => {
        stateshowsection(<SectionForm cancelsectiontable={cancelsectiontable} />);
    }
    const cancelsectiontable = () => {
        stateshowsection(<SectionTable showsectiontable={showsectiontable} cancelsectiontable={cancelsectiontable} />);
    }
    const [showsection, stateshowsection] = useState(<SectionTable showsectiontable={showsectiontable} cancelsectiontable={cancelsectiontable} />);

    return (

        <>
            <div className="row ">
                <div className="dash_background col-12 p-0">
                    <div className="row ">

                        <div className="col-md-12">
                            {showsection}
                        </div>


                    </div>


                </div>
            </div>
        </>

    )
}

export default MainSection;