import React from "react";
import downImage from "../../../assets/icons8-down-button-50.png";

const DownIcon = () => {
  return (
    <img src={downImage} style={{ width: "18px" }} alt="img" className="mr-2" />
  );
};

export default DownIcon;

