const allheading = [];
const allaccounts = { data: [], loading: true };
const allrecentlyadded = { data: [], loading: true };
const GetHeadingReducer = (state = allheading, action) => {
    switch (action.type) {
        case "get_headding":
            return action.payload;


        default:
            return state;
    }


}
export default GetHeadingReducer;

export const GetAllAccountsReducer = (state = allaccounts, action) => {
    switch (action.type) {

        case "get_allaccount":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}
export const GetAllRecentlyAccReducer = (state = allrecentlyadded, action) => {
    switch (action.type) {

        case "get_allrecentlyadded":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}


