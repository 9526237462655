const recoveryreport = { data: [], loading: true };
export const GetRecoveryReport = (state = recoveryreport, action) => {
    switch (action.type) {

        case "get_allrecoveryreport":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}