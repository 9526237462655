import { useState } from "react";
import { PaymentInstallmentsTable } from "./PaymentInstallment/PaymentInstallemnetTable";
import { PaymentInstallmentForm } from "./PaymentInstallment/PaymentInstallmentForm";

import { ReceiptInstallmentsForm } from "./ReceiptInstallment/ReceiptInstallmentsForm";
import { ReceiptInstallmentsTable } from "./ReceiptInstallment/ReceiptInstallmentsTable";

const InstallmentMain = () => {
    const [tab, settab] = useState("Receipt Installments");
    const showreceipttable = () => {
        stateshowreceipt(<ReceiptInstallmentsForm cancelreceipttable={cancelreceipttable} />);
    }
    const cancelreceipttable = () => {
        stateshowreceipt(<ReceiptInstallmentsTable showreceipttable={showreceipttable} cancelreceipttable={cancelreceipttable} />);
    }
    const [showreceipt, stateshowreceipt] = useState(<ReceiptInstallmentsTable showreceipttable={showreceipttable} cancelreceipttable={cancelreceipttable} />);
    // for payment
    const showpaymenttable = () => {
        statshowpayment(<PaymentInstallmentForm cancelpaymenttable={cancelpaymenttable} />);
    }
    const cancelpaymenttable = () => {
        statshowpayment(<PaymentInstallmentsTable showpaymenttable={showpaymenttable} cancelpaymenttable={cancelpaymenttable} />);
    }
    const [showpayment, statshowpayment] = useState(<PaymentInstallmentsTable showpaymenttable={showpaymenttable} cancelpaymenttable={cancelpaymenttable} />);
    return (

        <>
            <div className="row  ">
                <div className="dash_background col-12 p-0">
                    <div className="row ">
                        <div className="col-md-12 text-center  p-0">

                            <button
                                className={tab === "Receipt Installments" ? "adjust_btns_selected  " : "adjust_btns  "}
                                onClick={() => {
                                    settab("Receipt Installments");

                                }}
                            >
                                <span className="comp_btns_text">Receipt Installments</span>

                            </button>


                            <button
                                className={tab === "Payment Installments" ? "adjust_btns_selected ml-1 " : "adjust_btns ml-1 "}
                                onClick={() => {
                                    settab("Payment Installments");

                                }}
                            >
                                <span className="comp_btns_text"> Payment Installments</span>

                            </button>


                        </div>
                        <div className="col-md-12">
                            {tab === "Receipt Installments" ? showreceipt
                                : tab === "Payment Installments" ? showpayment
                                    : null
                            }
                        </div>


                    </div>


                </div>
            </div>
        </>

    )
}

export default InstallmentMain;