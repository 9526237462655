const projectData = [];
export const GetProjectDataReducer = (state = projectData, action) => {
    switch (action.type) {

        case "get_projectData":
            return {
                projectData: action.payload,

            }
        default:
            return state
    }
}