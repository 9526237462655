import React, { Component } from "react";
import gravity from "../../../../assets/GravityLogo.png";
import PrintHeader from "../../../Prints/PrintHeader/PrintHeader";
export class DealIndvidualPrint extends Component {
    render() {

        return (
          <div style={{ backgroundColor: "white", height: "100%" }}>
            <div className="row m-0 p-0">
              <PrintHeader />
            </div>
            <div
              className="row m-3 p-0"
              // style={{ border: "5px solid darkgrey", height: "85%" }}
            >
              <div className="col-12 text-center mt-2">
                <h4 style={{ color: "red" }}>
                  <u>Deal Bought</u>
                </h4>
                {/* <h5>
                            <b>Entry Date:{" "}</b>
                            {this.props.printData !== undefined ?

                                this.props.printData.EntryDate.split("T")[0]

                                : null}
                        </h5>
                        <h5>
                            <b>Exp Date:{" "}</b>
                            {this.props.printData !== undefined ?

                                this.props.printData.ExpDate.split("T")[0]

                                : null}
                        </h5> */}

                <div className="row">
                  <div className="col-6 mt-4">
                    <ul>
                      <li>
                        <b>Project Name:</b>{" "}
                        {this.props.printData !== undefined
                          ? this.props.printData.Project.ProjectName ===
                            "THE GRAND CITY"
                            ? "THE GRAND ICONIC CITY"
                            : this.props.printData.Project.ProjectName
                          : null}
                      </li>

                      <li>
                        <b>Seller:</b>{" "}
                        {this.props.printData !== undefined &&
                        this.props.printData.Seller !== undefined
                          ? this.props.printData.Seller.SellerName
                          : null}
                      </li>
                      <li>
                        <b>Total Amount:</b>{" "}
                        {this.props.printData !== undefined
                          ? this.props.printData.TotalAmount.toLocaleString()
                          : null}
                      </li>
                      <li>
                        <b>CommissionPer:</b>{" "}
                        {this.props.printData !== undefined
                          ? this.props.printData.CommissionPer.toLocaleString()
                          : null}
                      </li>
                    </ul>
                  </div>
                  <div className="col-6 mt-4">
                    <ul>
                      <li>
                        <b>Deal Name: </b>{" "}
                        {this.props.printData !== undefined
                          ? this.props.printData.DealName
                          : null}
                      </li>
                      <li>
                        <b>Deal Amount:</b>{" "}
                        {this.props.printData !== undefined
                          ? this.props.printData.DealAmount.toLocaleString()
                          : null}
                      </li>

                      <li>
                        <b>DealAmountPer%:</b>{" "}
                        {this.props.printData !== undefined
                          ? this.props.printData.DealAmountPer
                          : null}
                      </li>

                      <li>
                        <b>Profit Margin%:</b>{" "}
                        {this.props.printData !== undefined
                          ? this.props.printData.ProfitMarginPer
                          : null}
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="mt-5">
                  <div class=" col-12">
                    <table class="table ">
                      <thead class="purple whiteText">
                        <tr>
                          <th scope="col">Sr</th>

                          <th scope="col">Unit</th>
                          <th scope="col">Size</th>
                          <th scope="col">Sqft</th>
                          <th scope="col">Purchase Price</th>

                          <th scope="col">Sale Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.printData === undefined ||
                        this.props.printData.Units === undefined
                          ? // contractinstallment.length == 0
                            null
                          : this.props.printData.Units.map((x, id) => (
                              <tr key={id}>
                                <td>{id + 1}</td>
                                <td>{x.UnitName}</td>
                                <td>{x.Marla + "M " + x.Sarsai + " S"}</td>
                                <td>{x.NetSqft}</td>
                                <td>
                                  {x.TotalCost !== null
                                    ? x.TotalCost.toLocaleString()
                                    : null}
                                </td>
                                <td>
                                  {x.Price !== null
                                    ? x.Price.toLocaleString()
                                    : null}
                                </td>
                              </tr>
                            ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
    }
}