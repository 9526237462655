import { useState } from "react";
import { SellerForm } from "./SellerForm";
import { SellerTable } from "./SellerTable";

const MainSeller = () => {

    const showcreditortable = () => {
        stateshowcreditor(<SellerForm cancelcreditortable={cancelcreditortable} />);
    }
    const cancelcreditortable = () => {
        stateshowcreditor(<SellerTable showcreditortable={showcreditortable} cancelcreditortable={cancelcreditortable} />);
    }
    const [showclient, stateshowcreditor] = useState(<SellerTable showcreditortable={showcreditortable} cancelcreditortable={cancelcreditortable} />);

    return (

        <>
            <div className="row ">
                <div className="dash_background col-12 p-0">
                    <div className="row ">

                        <div className="col-md-12">
                            {showclient}
                        </div>


                    </div>


                </div>
            </div>
        </>

    )
}
export default MainSeller;