const allavailability = { data: [], loading: true };
const allleads = { data: [], loading: true };

export const GetAllCRMAvailabilityRed = (state = allavailability, action) => {
    switch (action.type) {

        case "get_allcrmavailability":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}
export const GetAllLeadsRed = (state = allleads, action) => {
    switch (action.type) {

        case "get_allleads":
            return {
                data: action.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}