import { useState } from "react";
import { Project, ProjectForm } from "./ProjectForm";
import { ProjectTable } from "./ProjectTable";

const MainProject = () => {

    const showprojecttable = () => {
        stateshowproject(<ProjectForm cancelprojecttable={cancelprojecttable} />);
    }
    const cancelprojecttable = () => {
        stateshowproject(<ProjectTable showprojecttable={showprojecttable} cancelprojecttable={cancelprojecttable} />);
    }
    const [showproject, stateshowproject] = useState(<ProjectTable showprojecttable={showprojecttable} cancelprojecttable={cancelprojecttable} />);
    // for deal 
    // const showdealtable = () => {
    //     statedealproject(<Deal canceldealtable={canceldealtable} />);
    // }
    // const canceldealtable = () => {
    //     statedealproject(<DealTableComp showdealtable={showdealtable} canceldealtable={canceldealtable} />);
    // }
    // const [showdeal, statedealproject] = useState(<DealTableComp showdealtable={showdealtable} canceldealtable={canceldealtable} />);
    return (

        <>
            <div className="row  ">
                <div className="dash_background col-12 p-0">
                    <div className="row ">
                        <div className="col-md-12 text-center  p-0">

                            {/* <button
                                className={tab === "Add Project" ? "adjust_btns_selected  " : "adjust_btns  "}
                                onClick={() => {
                                    settab("Add Project");

                                }}
                            >
                                <span className="comp_btns_text">Add Project</span>

                            </button> */}

                            {/* 
                            <button
                                className={tab === "Add Deal" ? "adjust_btns_selected ml-1 " : "adjust_btns ml-1 "}
                                onClick={() => {
                                    settab("Add Deal");

                                }}
                            >
                                <span className="comp_btns_text"> Add Deal</span>

                            </button> */}


                        </div>
                        {/* <div className="col-md-12">
                            {tab === "Add Project" ? showproject
                                : tab === "Add Deal" ? showdeal
                                    : null
                            }
                        </div> */}
                        <div className="col-md-12">
                            {showproject}

                        </div>


                    </div>


                </div>
            </div>
        </>

    )
}

export default MainProject;